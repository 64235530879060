<template>
    <div id="header-nav" ref="header">
        <div class="scrolled-bg"></div>
        <div class="lockout">
            <div class="logo">
                <router-link to="/">
                    <img src="../img/global/header/logo-respawn-white.png" alt="Respawn" class="logo--full"
                         v-if="mobileOpen">
                    <img src="../img/global/header/logo-respawn.png" alt="Respawn" class="logo--full"
                         v-if="!mobileOpen">
                    <img src="../img/global/header/logo-respawn-bug.png" alt="Respawn" class="logo--bug">
                </router-link>
            </div>
            <div id="nav" class="nav" ref="nav" >
                <router-link to="/games" class="games">GAMES<span>GAMES</span></router-link>
                <router-link to="/studio" class="studio">STUDIO<span>STUDIO</span></router-link>
                <!--
                    <router-link to="/people" class="team">PEOPLE<span>PEOPLE</span></router-link>
                -->
                <router-link to="/culture" class="culture">CULTURE<span>CULTURE</span></router-link>
                <router-link to="/careers" class="careers">CAREERS<span>CAREERS</span></router-link>
                <router-link to="/internships" class="team">INTERNSHIPS<span>INTERNSHIPS</span></router-link>
                <router-link to="/news" class="news">NEWS<span>NEWS</span></router-link>
                <!--
                <router-link to="/history" class="history">HISTORY<span>HISTORY</span></router-link>
                -->
            </div>
            <div id="hamburger" @click="mobileOpen = !mobileOpen">
                <img src="../img/global/header/icon-hamburger.png" v-if="!mobileOpen">
                <img src="../img/global/header/icon-hamburger-close.png" v-if="mobileOpen">
            </div>
        </div>
        <div id="mobile-menu" :class="{'mobileOpen': mobileOpen}" ref="mobileMenu">
            <div class="inner nav" ref="mobileMenuInner">
                <div>
                    <router-link to="/games" class="games">GAMES</router-link>
                </div>
                <div>
                    <router-link to="/studio" class="studio">STUDIO</router-link>
                </div>
                <!--
                <div>
                    <router-link to="/people" class="team">PEOPLE</router-link>
                </div>
                -->
                <div>
                    <router-link to="/careers" class="careers">CAREERS</router-link>
                </div>
                <div>
                    <router-link to="/culture" class="culture">CULTURE</router-link>
                </div>
                <div>
                    <router-link to="/internships" class="careers">INTERNSHIPS</router-link>
                </div>
                <div>
                    <router-link to="/news" class="news">NEWS</router-link>
                </div>
                <!--
                <div>
                    <router-link to="/history" class="history">HISTORY</router-link>
                </div>
                -->
            </div>
            <footer-bar id="footer-mobile-nav"></footer-bar>
        </div>
    </div>
</template>

<script>
    import Footer from '@/components/Footer'
    import { gsap } from "gsap";
    export default {
        name: 'HeaderNav',
        components: {
            'footer-bar': Footer
        },
        props: {},
        data() {
            return {
                mobileOpen: false
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        mounted() {
            this.$refs.mobileMenu.addEventListener('touchmove', (e) => {
                e.preventDefault();
            }, {passive: false});
        },
        methods: {
            handleScroll(event) {
                if (window.scrollY > 15) {
                    this.$refs.header.classList.add('scrolled');
                } else {
                    this.$refs.header.classList.remove('scrolled');
                }
            },
        },
        watch: {
            $route(to, from) {
                this.mobileOpen = false;
            },
            mobileOpen: function (isOpen) {
                if (isOpen) {
                    let buttons = this.$refs.mobileMenuInner.querySelectorAll('a');
                    gsap.fromTo(buttons,
                        { y: 200, force3D: true, opacity: 0 },
                        {
                            duration: 0.45,
                            y: 0,
                            ease: "power3.out",
                            opacity: 1,
                            stagger: {
                                amount: 0.02,
                                start: 0.05
                            }
                        }
                    );
                }

            }

        },
    }
</script>
