<template>
    <div v-if="data" id="studio" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">Studio</h1>
        <div class="hero hero-generic">
            <div class="hero__text">
                <div class="hero__text__bg">
                    <div class="hero__text__bg__wrap">
                        <h4 v-if="data.subtitle !== undefined" class="hero__text__subtitle" v-html="data.subtitle"></h4>
                        <h1 v-if="data.title  !== undefined" class="hero__text__title"  v-html="data.title"></h1>
                    </div>
                </div>
            </div>
            <div class="hero__image" :style="'background-image: url(' + data.studioImage + ')'">
                <div class="hero__image__white"></div>
            </div>
        </div>

        <div class="rtc lockout">
            <div class="who-we-are-holder" v-html="data.whoWeAre"></div>
            
            <div class="gallery gallery--flipped" v-if="data.top_gallery && data.top_gallery.length">
                <div class="gallery__images">
                    <ul class="gallery__images__carousel">
                        <li v-for="galleryItem in data.top_gallery">
                            <img :src="galleryItem.img">
                        </li>
                    </ul>
                    <div class="gallery__images__counter">
                        <div class="gallery__images__counter__current"></div>
                        <div class="gallery__images__counter__total">/</div>
                    </div>
                </div>
                <div class="gallery__caption">
                    <ul>
                        <li v-if="data.top_gallery" v-for="galleryItem in data.top_gallery">
                            <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                            <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>
                        </li>
                    </ul>
                </div>
                <div class="gallery__caption gallery__caption--mobile">
                    <ul>
                        <li v-for="galleryItem in data.top_gallery">
                            <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                            <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="gallery">
                <div class="gallery__caption">
                    <ul>
                        <li v-if="data.gallery" v-for="galleryItem in data.gallery">
                            <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                            <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>
                        </li>
                    </ul>
                </div>
                <div class="gallery__images">
                    <ul class="gallery__images__carousel">
                        <li v-for="galleryItem in data.gallery">
                            <img :src="galleryItem.img">
                        </li>
                    </ul>
                    <div class="gallery__images__counter">
                        <div class="gallery__images__counter__current"></div>
                        <div class="gallery__images__counter__total">/</div>
                    </div>
                </div>
                <div class="gallery__caption gallery__caption--mobile">
                    <ul>
                        <li v-for="galleryItem in data.gallery">
                            <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                            <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>
                        </li>
                    </ul>
                </div>
            </div>


            <perks-block :perks="data.perks"></perks-block>
            <join-studio-block></join-studio-block>

        </div>

        <footer-bar></footer-bar>
    </div>
</template>

<script>

    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import SlickCarousels from '../lib/SlickCarousels'
    import inobounce from 'inobounce'
    import SectionTitle from '../lib/SectionTitle'
    import $ from 'jquery'
    import axios from 'axios'
    import JoinStudioBlock from '@/components/JoinStudioBlock'
    import PerksBlock from '@/components/PerksBlock'
    import siteHelpers from "@/helpers/siteHelpers";

    import { gsap, Power3 } from "gsap";

    export default {
        name: 'studio',
        metaInfo() {
            return siteHelpers.getGlobalMeta('Studio')
        },
        components: {
            'footer-bar': Footer,
            'join-studio-block': JoinStudioBlock,
            'perks-block': PerksBlock
        },
        data() {
            return {
                data: {},
            }
        },
        created() {

        },
        mounted() {
            this.loadJson();
            //this.onJsonLoaded();
        },
        methods: {

            loadJson() {
                axios.get(window.baseUrl + '/studio.json')
                     .then((response) => {
                         this.data = response.data;
                         nextTick(() => {
                             this.onJsonLoaded();
                         });
                          //console.log(this.data);
                     })
                     .catch((error) => {
                         console.error(error);
                     })
             },

            onJsonLoaded() {
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, 500);
                inobounce.disable();
                SlickCarousels.init();
                SectionTitle.animateIn(this.$refs.sectionTitle);
                this.animateIn();
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var isMobile = $(window).width() < 700;
                var $orangeHero = $('.hero__text__bg');
                var $whiteImageBlocker = $('.hero__image__white');
                var narrowOrange = $orangeHero.width() * 0.3;
                var targetX = $orangeHero.width() + (isMobile ? 0 : 230);
                var orangeStaringHeight = $orangeHero.outerHeight();
                var $text = $('.hero__text__bg__wrap');
                var $rtc = $('.rtc');

                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0 : 0.65);

                tl.set($orangeHero, {width: $orangeHero.width() * 0.3, height: orangeStaringHeight})
                tl.fromTo($orangeHero, 1, { x:-narrowOrange, force3D:true },
                    { x: targetX, force3D:true, ease:Power3.easeInOut});
                tl.to($orangeHero, 0.6, { x:0, width: '100%', force3D: true, ease: Power3.easeInOut, clearProps:'all' }, '-=0.21');
                tl.fromTo($whiteImageBlocker, 0.6, {xPercent:0}, { xPercent: -100, force3D:true, ease:Power3.easeInOut}, '-=0.55');
                tl.fromTo($text, 0.45, {x:200, opacity: 0}, { x: 0, opacity:1, force3D:true, ease:Power3.easeOut}, 1.12);
                tl.fromTo($rtc, 0.7, { y:100, opacity:0}, { y:0, force3D:true, opacity:1, ease:Power3.easeInOut}, '-=0.6');

            }
        }

    }

</script>
