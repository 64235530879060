<template>
    <div id="blockers">
        <div>
            <div id="mobile-landscape-blocker">
                <img src="../img/global/blockers/icon-rotate.png">
                <h3>Please rotate your device!</h3>
            </div>
            <div id="desktop-too-short-blocker">
                <div class="l">
                    <img src="../img/global/blockers/icon-taller-browser.png">
                </div>
                <div class="r">
                    <h3>PLEASE MAKE YOUR BROWSER WINDOW TALLER!</h3>
                    <ul>
                        <li>Please use your browsers view preferences and either choose “Actual Size” or “Zoom Out” or “100%”</li>
                        <li>Click and drag your browser window’s bottom<br>edge to make it taller</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name: 'blockers',
        components: {}
    }

</script>
