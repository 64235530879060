<template>
    <div id="careers-landing" class="section sticky-footer" v-if="data">
        
        <!-- <div class="message-banner">
            <h5>
                <strong>We're upgrading our recruiting system!</strong><br>Job applications and postings will be unavailable Oct 25th - Nov 12th
            </h5>
        </div> -->

        <h1 class="section-title lockout" ref="sectionTitle">Careers</h1>
        <div class="hero hero-generic">
            <div class="hero__text">
                <div class="hero__text__bg">
                    <div class="hero__text__bg__wrap">
                        <h4 v-if="data.subtitle !== undefined" class="hero__text__subtitle" v-html="data.subtitle"></h4>
                        <h1 v-if="data.title  !== undefined" class="hero__text__title" v-html="data.title"></h1>
                        <!-- <div class="picker">
                            
                            <div class="fields">
                                <div class="btn-outer">
                                    <router-link to="/careers-listings">
                                        <div class="btn btn--huge btn--huge--reversed white">
                                            <div class="btn--huge__text">
                                                <div>FIND JOBS<span>FIND JOBS</span></div>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                           
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="hero__image" :style="'background-image: url(' + data.studioImage + ')'">
                <div class="hero__image__white"></div>
            </div>
        </div>

        <careers-pickers ref="cp" extraClasses="landing"></careers-pickers>

        <div class="lockout--badge desktop">
            <div class="items">
                <div class="badge">
                    <img src="@/img/careers/badge.svg" alt="GamesIndustry.biz - 2022 Best Places to Work Winner" />
                </div>
                <div class="dots">
                </div>
            </div>
        </div>

        <div class="lockout--careers top">

            <div class="lockout">
                <div class="intro">
                    <div class="intro__text">
                        <div class="intro__text intro__text--desktop rtc-no-padding-margins rich-text" v-html="data.introText === undefined ? '' : data.introText"></div>
                        <div class="intro__text intro__text--mobile rtc-no-padding-margins rich-text">
                            <div v-html="introTextMobile"></div>
                            <div class="intro__text--more" @click="introTextTruncated = false" v-if="introTextTruncated">
                                MORE
                            </div>
                        </div>
                    </div>
                    <div v-if="1==2" class="intro__video">
                        <div class="intro__video__title intro__video__title--mobile">
                            <h4>{{ data.video.title === undefined ? '' : data.video.title }}</h4>
                        </div>
                        <router-link v-if="(data.video.link === undefined || data.video.link === '')" :to="'video/' + (data === undefined ? '' : data.video.youtubeId)" v-on:mouseenter.native="onVideoThumbOver" v-on:mouseleave.native="onVideoThumbOut">
                            <div class="intro__video__thumb">
                                <div class="video-thumb">
                                    <img :src="data.video.youtubeThumb" alt="Respawn Office Culture" class="video-thumb__img" />
                                    <div class="video-thumb__box"></div>
                                    <img src="../img/global/icon-play.png" class="video-thumb__play" />
                                </div>
                            </div>
                        </router-link>
                        <a v-else :href="data.video.link" target="_blank" v-on:mouseenter="onVideoThumbOver" v-on:mouseleave="onVideoThumbOut">
                            <div class="intro__video__thumb">
                                <div class="video-thumb">
                                    <img :src="data.video.youtubeThumb" alt="Respawn Office Culture" class="video-thumb__img" />
                                    <div class="video-thumb__box"></div>
                                    <img src="../img/global/icon-play.png" class="video-thumb__play" />
                                </div>
                            </div>
                        </a>

                        <div class="intro__video__title intro__video__title--desktop">
                            <h4>{{ data.video.title === undefined ? '' : data.video.title }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="lockout--badge mobile">
            <div class="items">
                <div class="badge">
                    <img src="@/img/careers/badge.svg" alt="GamesIndustry.biz - 2022 Best Places to Work Winner" />
                </div>
                <div class="dots">
                </div>
            </div>
        </div>

        <div class="lockout--careers galleries">

            <div class="rtc lockout">
                <div class="galleries">
                    <div class="gallery" v-for="galleryObj in data.galleries">
                        <div class="gallery__caption">
                            <ul>
                                <li v-if="galleryObj.gallery" v-for="galleryItem in galleryObj.gallery">
                                    <div class="gallery__caption__maintitle">TEAM: {{ galleryObj.title }}</div>
                                    <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                                    <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>

                                    <div class="gallery__caption__button">

                                        <a v-if="galleryObj.careersLink.toUpperCase() === 'BIT REACTOR'" target="_blank" href="https://www.bitreactor.com/careers/">
                                            <div class="btn btn--huge btn--huge--reversed">
                                                <div class="btn--huge__text">
                                                    <div>JOIN THE TEAM<span>JOIN THE TEAM</span></div>
                                                </div>
                                            </div>
                                        </a>
                                        <router-link v-else :to="'/careers-listings?team=' + galleryObj.careersLink">
                                            <div class="btn btn--huge btn--huge--reversed">
                                                <div class="btn--huge__text">
                                                    <div>JOIN THE TEAM<span>JOIN THE TEAM</span></div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div class="gallery__images">
                            <ul class="gallery__images__carousel">
                                <li v-for="galleryItem in galleryObj.gallery">
                                    <img :src="galleryItem.img">
                                </li>
                            </ul>
                            <div class="gallery__images__counter">
                                <div class="gallery__images__counter__current"></div>
                                <div class="gallery__images__counter__total">/</div>
                            </div>
                        </div>
                        <div class="gallery__caption gallery__caption--mobile">
                            <ul>
                                <li v-for="galleryItem in galleryObj.gallery">
                                    <div class="gallery__caption__maintitle">TEAM: {{ galleryObj.title }}</div>
                                    <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                                    <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>

                                    <div class="gallery__caption__button">
                                        <router-link :to="(galleryObj.careersLink) ? '/careers-listings?team=' + galleryObj.careersLink : '/careers-listings'">
                                            <div class="btn btn--huge btn--huge--reversed">
                                                <div class="btn--huge__text">
                                                    <div>JOIN THE TEAM<span>JOIN THE TEAM</span></div>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <perks-block :perks="data.perks"></perks-block>
            </div>
        </div>

        <router-view />
        <footer-bar></footer-bar>

    </div>
</template>

<script>
    import {
        nextTick
    } from 'vue';
    import Footer from '@/components/Footer'
    import SectionTitle from '../lib/SectionTitle'
    import axios from 'axios'
    import $ from 'jquery'
    import inobounce from 'inobounce'
    import Dropdown from '@/components/Dropdown'
    import router from '../router'
    import siteHelpers from "@/helpers/siteHelpers";
    import JoinStudioBlock from "@/components/JoinStudioBlock";
    import PerksBlock from "@/components/PerksBlock";
    import SlickCarousels from '../lib/SlickCarousels'
    import CareersPickers from '@/components/CareersPickers'

    import {
        gsap,
        Power3
    } from "gsap";

    export default {
        name: 'careers',
        metaInfo() {
            return siteHelpers.getGlobalMeta('Careers')
        },
        components: {
            'footer-bar': Footer,
            Dropdown,
            'perks-block': PerksBlock,
            'careers-pickers': CareersPickers
        },
        data() {
            return {
                data: {
                    video: {}
                },

                categories: false,
                categoriesFlat: [],
                selectionGroup: [],
                selectionTeam: [],
                selectionLocation: [],
                selectionGroupCounts: [],
                selectionTeamCounts: [],
                selectionLocationCounts: [],
                selectionGroupModel: [],
                selectionTeamModel: [],
                selectionLocationModel: [],

                introTextTruncated: true,
                introTextHeight: 0,
                positions: [],
                window: window,
            }
        },
        mounted() {
            this.loadJasonInit()
        },
        methods: {
            loadJasonInit() {
                if (this.window.careers.isLoaded) {
                    this.loadJson()
                } else {
                    setTimeout(this.loadJasonInit, 500)
                }
            },
            loadJson() {
                this.data = this.window.careers.data;
                this.categories = this.window.careers.data.categories;

                for (let i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i]
                    for (let ii = 0; ii < category.positions.length; ii++) {
                        let position = category.positions[ii]
                        let tobj = {
                            group: category.title,
                            id: position.id,
                            team: position.game,
                            location: position.location,
                            role: position.title,
                        }
                        this.categoriesFlat.push(tobj)

                        this.selectionGroup.push(category.title)
                        this.selectionTeam.push(position.game)
                        this.selectionLocation.push(position.location)

                        // group counts
                        if (!this.selectionGroupCounts[category.title]) {
                            this.selectionGroupCounts[category.title] = 0
                        }
                        this.selectionGroupCounts[category.title]++

                        // team counts
                        if (!this.selectionTeamCounts[position.game]) {
                            this.selectionTeamCounts[position.game] = 0
                        }
                        this.selectionTeamCounts[position.game]++

                        // location counts
                        if (!this.selectionLocationCounts[position.location]) {
                            this.selectionLocationCounts[position.location] = 0
                        }
                        this.selectionLocationCounts[position.location]++

                    } //for ii
                } //for i

                this.selectionGroup = [...new Set(this.selectionGroup)]
                this.selectionTeam = [...new Set(this.selectionTeam)]
                this.selectionLocation = [...new Set(this.selectionLocation)]

                this.selectionGroup.sort()
                this.selectionTeam.sort()
                this.selectionLocation.sort()

                /*
                let blank = '(blank)'
                this.selectionGroup.push(blank)
                this.selectionTeam.push(blank)
                this.selectionLocation.push(blank)

                 */

                nextTick(() => {
                    this.onJsonLoaded();
                });
            },

            onJsonLoaded() {
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, this.$store.state.firstSection ? 0 : 550);

                SectionTitle.animateIn(this.$refs.sectionTitle);
                inobounce.disable();
                SlickCarousels.init();
                this.animateIn();
                this.setTabZIndexes();

                this.initEvents();
            },

            initEvents() {
                $('.rich-text a').on('click', this.onRouterLinkClick);
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0.5 : 0.8);

                tl.fromTo($('.lockout--badge .dots'), 0.5, {
                    x: '100%',
                    opacity: 0
                }, {
                    x: '0%',
                    opacity: 1,
                    force3D: true,
                    ease: Power3.easeInOut,
                }, 0);
                tl.fromTo($('.lockout--badge .badge'), 1, {
                    "clip-path": "circle(0% at 50% 50%)",
                    opacity: 0
                }, {
                    "clip-path": "circle(100% at 50% 50%)",
                    opacity: 1,
                    force3D: true,
                    ease: Power3.easeInOut,
                }, 0.15);

                var isMobile = $(window).width() < 700;
                var $orangeHero = $('.hero__text__bg');
                var $whiteImageBlocker = $('.hero__image__white');
                var narrowOrange = $orangeHero.width() * 0.3;
                var targetX = $orangeHero.width() + (isMobile ? 0 : 230);
                var orangeStaringHeight = $orangeHero.outerHeight();
                var $text = $('.hero__text__bg__wrap');
                var $rtc = $('.rtc');

                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0 : 0.65);

                tl.set($orangeHero, {
                    width: $orangeHero.width() * 0.3,
                    height: orangeStaringHeight
                })
                tl.fromTo($orangeHero, 1, {
                    x: -narrowOrange,
                    force3D: true
                }, {
                    x: targetX,
                    force3D: true,
                    ease: Power3.easeInOut
                });
                tl.to($orangeHero, 0.6, {
                    x: 0,
                    width: '100%',
                    force3D: true,
                    ease: Power3.easeInOut,
                    clearProps: 'all'
                }, '-=0.21');
                tl.fromTo($whiteImageBlocker, 0.6, {
                    xPercent: 0
                }, {
                    x: '-100%',
                    force3D: true,
                    ease: Power3.easeInOut
                }, '-=0.55');
                tl.fromTo('.table-filter', 0.45, {
                    y: 0,
                    opacity: 0
                }, {
                    y: 0,
                    force3D: true,
                    opacity: 1,
                    ease: Power3.easeInOut
                }, 1.12);
                tl.fromTo('.table-filter-mobile', 0.45, {
                    y: 0,
                    opacity: 0
                }, {
                    y: 0,
                    force3D: true,
                    opacity: 1,
                    ease: Power3.easeInOut
                }, 1.12);
                tl.fromTo('.top', 0.45, {
                    y: 0,
                    opacity: 0
                }, {
                    y: 0,
                    force3D: true,
                    opacity: 1,
                    ease: Power3.easeInOut
                }, 1.12);
                tl.fromTo($text, 0.45, {
                    x: 200,
                    opacity: 0
                }, {
                    x: 0,
                    opacity: 1,
                    force3D: true,
                    ease: Power3.easeOut
                }, 1.12);
                tl.fromTo($rtc, 0.7, {
                    y: 100,
                    opacity: 0
                }, {
                    y: 0,
                    force3D: true,
                    opacity: 1,
                    ease: Power3.easeInOut
                }, '-=0.6');

            },

            setTabZIndexes() {
                $('.category').each((i, el) => {
                    $(el).css({
                        zIndex: 50 - i
                    });
                });
            },

            onVideoThumbOver(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.video-thumb__box');
                var $image = $el.find('.video-thumb__img');

                gsap.killTweensOf([$color, $image]);

                gsap.to($color, 0.3, {
                    x: 12,
                    y: -12,
                    force3D: true,
                    ease: Power2.easeIn
                });
                gsap.to($image, 0.3, {
                    x: -12,
                    y: 12,
                    force3D: true,
                    ease: Power2.easeIn,
                    onComplete: () => {
                        $color.addClass('orange');
                    }
                });
                gsap.to([$color, $image], 0.3, {
                    x: 0,
                    y: 0,
                    force3D: true,
                    ease: Power2.easeOut,
                    delay: 0.3
                });
            },

            onVideoThumbOut(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.video-thumb__box');
                var $image = $el.find('.video-thumb__img');

                gsap.killTweensOf([$color, $image]);

                if ($color.hasClass('orange')) {
                    gsap.to($color, 0.3, {
                        x: 12,
                        y: -12,
                        force3D: true,
                        ease: Power2.easeIn
                    });
                    gsap.to($image, 0.3, {
                        x: -12,
                        y: 12,
                        force3D: true,
                        ease: Power2.easeIn,
                        onComplete: () => {
                            $color.removeClass('orange');
                        }
                    });
                    gsap.to([$color, $image], 0.3, {
                        x: 0,
                        y: 0,
                        force3D: true,
                        ease: Power2.easeOut,
                        delay: 0.3
                    });
                } else {
                    gsap.to([$color, $image], 0.3, {
                        x: 0,
                        y: 0,
                        force3D: true,
                        ease: Power2.easeOut,
                        delay: 0
                    });
                }

            },

            onTabClick(e) {
                var $curPanel = $(e.currentTarget).parent().stop(true).find('.category__panel');
                $('.category__panel').parent().removeClass('category__tab--open')
                var panelOpen = $curPanel.is(':visible');
                $('.category__panel').slideUp(300);
                if (!panelOpen) {
                    $curPanel.slideDown(300);
                    $curPanel.parent().addClass('category__tab--open')
                }
            },

            getGameList: function(category) {

                var games = ['ALL GAMES'];
                for (var i = 0; i < category.positions.length; i++) {
                    var curPosition = category.positions[i];
                    if (games.indexOf(curPosition.game) == -1) {
                        if (curPosition.game !== null) { //dont display null value in list - some games are null
                            games.push(curPosition.game);
                        }
                    }
                }
                return games;
            },

            setPositionsArrays() {
                for (var i = 0; i < this.data.categories.length; i++) {
                    var cat = this.data.categories[i];
                    this.positions[i] = cat.positions;
                }
                this.$forceUpdate();
            },

            getPositions(catIndex) {
                return this.positions[catIndex];
            },

            onGameChanged(gameName, index, meta) {
                var catIndex = meta.categoryIndex;
                var cat = this.data.categories[catIndex];
                if (gameName == 'ALL GAMES') {
                    this.positions[catIndex] = cat.positions;
                } else {
                    this.positions[catIndex] = cat.positions.filter(function(item) {
                        return item.game == gameName;
                    });
                }
                this.$forceUpdate();
            },

            onRouterLinkClick(e) {
                if ($(e.currentTarget).attr('target') !== '_blank') {
                    e.preventDefault();

                    let href = $(e.currentTarget).attr('href');
                    if (href.substr(-1) === '/') {
                        href = href.substr(0, href.length - 1);
                    }
                    if (href.substr(0, 1) !== '/') {
                        href = '/' + href
                    }
                    router.push({
                        path: href
                    });
                }
            },

            checkBox(model, val) {
                if (model === 'group') {
                    let index = this.selectionGroupModel.indexOf(val)
                    if (index > -1) {
                        this.selectionGroupModel.splice(index, 1)
                    } else {
                        this.selectionGroupModel.push(val)
                    }
                } else if (model === 'team') {
                    let index = this.selectionTeamModel.indexOf(val)
                    if (index > -1) {
                        this.selectionTeamModel.splice(index, 1)
                    } else {
                        this.selectionTeamModel.push(val)
                    }
                } else if (model === 'location') {
                    let index = this.selectionLocationModel.indexOf(val)
                    if (index > -1) {
                        this.selectionLocationModel.splice(index, 1)
                    } else {
                        this.selectionLocationModel.push(val)
                    }
                }
            },
            selectedClass(model, val) {
                let index = -1
                if (model === 'group') {
                    index = this.selectionGroupModel.indexOf(val)
                } else if (model === 'team') {
                    index = this.selectionTeamModel.indexOf(val)
                } else if (model === 'location') {
                    index = this.selectionLocationModel.indexOf(val)
                }
                if (index > -1) {
                    return 'selected'
                } else {
                    return ''
                }
            },

            showRow(row) {
                let ret = true
                if (this.selectionGroupModel.length) {
                    let index = this.selectionGroupModel.indexOf(row.group)
                    if (index === -1) {
                        ret = false
                    }
                }
                if (this.selectionTeamModel.length) {
                    let index = this.selectionTeamModel.indexOf(row.team)
                    if (index === -1) {
                        ret = false
                    }
                }
                if (this.selectionLocationModel.length) {
                    let index = this.selectionLocationModel.indexOf(row.location)
                    if (index === -1) {
                        ret = false
                    }
                }
                return ret
            },

            blankFormatter(val) {
                return (val) ? val : '(none)'
            }
        },

        computed: {
            introTextMobile: function() {
                if (this.data.introText === undefined) return '';

                var fullText = this.data.introText;
                if (this.introTextTruncated) {
                    fullText = fullText.substring(0, 225).trim() + '...';
                }
                this.introTextHeight = $('.intro__text--mobile').height();

                return fullText;
            },
        },

        watch: {
            introTextTruncated: function(truncated) {
                // expand intro text when "more" is clicked
                nextTick(() => {
                    if (truncated == false) {
                        var $introText = $('.intro__text--mobile');
                        gsap.fromTo($introText, 1, {
                            height: this.introTextHeight
                        }, {
                            height: $('.intro__text--mobile').height(),
                            ease: Power2.easeInOut
                        });
                    }
                });
            }
        },

        destroyed() {
            $('.category__tab').off('click', (e) => this.onTabClick(e));
            $('.rich-text a').off('click', this.onRouterLinkClick);
        }

    }
</script>