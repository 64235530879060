<template >
    <div>
        <careers-detail-inner :key="remounterKey" ></careers-detail-inner>
    </div>
</template>

<script>

    import Vue from 'vue'
    import CareersDetailInner from '@/components/CareersDetailInner'
    import router from '../router'

    export default {
        name: 'careers',
        components: {
            'careers-detail-inner': CareersDetailInner
        },
        data() {
            return {

            }
        },
        computed: {
            remounterKey: {
                get: function () {
                    return this.$store.state.remounterKey;
                },
                set: function (value) {

                }
            }
        },

    }

</script>
