<template>
    <section class="join-studio-block">
        <p>
            The opportunity to create groundbreaking games with some of the best developer talent in the
            industry awaits our future team members.
        </p>
        <router-link to="/careers">
            <div class="btn btn--huge btn--huge--reversed">
                <div class="btn--huge__text">
                    <div>JOIN RESPAWN<span>JOIN RESPAWN</span></div>
                </div>
            </div>
        </router-link>
    </section>
</template>

<script>
    export default {
        name: "JoinStudioBlock"
    }
</script>