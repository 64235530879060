<template>
    <div id="history" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">History</h1>
        <div id="history-timeline">
            <div id="dot-holder-top">
                <div class="dot"><div class="dot2"><div class="dot3"></div></div></div>
            </div>
            <div id="dot-holder">
                <div class="dot"><div class="dot2"><div class="dot3"></div></div></div>
            </div>
        </div>
        <div class="scroll-ctas">
            <img src="../img/game-details/cta-scroll-short.png" class="scroll-ctas__short">
        </div>
        <div id="history-content"><div id="history-content-inner">

            <div :class="'history-item clearfix scrollto-' + historyitem.slug" v-for="(historyitem, index) in historygrid" :id="'history-item-' + index">
                <!--------------------------------------------->
                <div class="vue-inner clearfix" v-if="historyitem.type === 'textimage'">
                    <div class="year-bg">
                        <div class="year-bg-bg"></div>
                        <div class="year-bg-year"><div class="div-table"><div class="div-tablecell"><span>{{historyitem.year}}</span></div></div></div>
                    </div>
                    <div v-bind:class="{ 'box-content': true, 'bc-textimage': true, 'reverse': historyitem.textalign=='right' }">
                        <div class="box-photo" >
                            <div class="box-photo-img"
                                 v-on:mouseenter="onVideoThumbOver"
                                 v-on:mouseleave="onVideoThumbOut">
                                <div class="box-bg-square orange">
                                    <img class="specks" src="../img/global/textures/bg-specks-white-cover1.png">
                                </div>
                                <img class="featured" :src="historyitem.posterImage" />
                            </div>
                        </div>
                        <div v-bind:class="{ 'box-txt': true, 'align-left': historyitem.textalign=='left','align-right': historyitem.textalign=='right' }">
                            <h5>{{historyitem.subtitle}}</h5>
                            <h2>
                                <div class="line-wrap" v-for="titleitem in historyitem.title">
                                    <div class="split-line">{{titleitem.line}}</div>
                                </div>
                                <div class="line-wrap" v-if="historyitem.description.line4!=''">
                                    <div class="description split-line  rtc-generic-sides" v-html="historyitem.description"></div>
                                </div>
                            </h2>
                        </div>
                    </div>
                </div>
                <!--------------------------------------------->
                <div class="vue-inner clearfix" v-else-if="historyitem.type === 'textquote'">
                    <div class="year-bg">
                        <div class="year-bg-bg"></div>
                        <div class="year-bg-year"><div class="div-table"><div class="div-tablecell"><span>{{historyitem.year}}</span></div></div></div>
                    </div>
                    <div class="box-content bc-textimage">
                        <div class="box-photo">
                            <div class="box-photo-img">
                                <img class="featured" :src="historyitem.posterImage" />
                            </div>
                            <div class="quote-holder">
                                <div class="l">
                                    <img src="../img/global/icon-quote-left.png" />
                                </div>
                                <div class="r"><div class="r-inner">
                                    <div class="quote">{{historyitem.quote}}"</div>
                                    <div class="attribution">
                                        // {{historyitem.attribution}}
                                    </div>
                                </div></div>
                            </div>
                        </div>
                        <div class="box-txt align-left">
                            <h5>{{historyitem.subtitle}}</h5>
                            <h2>
                                <div class="line-wrap" v-for="titleitem in historyitem.title">
                                    <div class="split-line">{{titleitem.line}}</div>
                                </div>
                                <div class="line-wrap">
                                    <div class="description split-line" v-html="historyitem.description"></div>
                                </div>
                            </h2>
                        </div>
                    </div>
                </div>
                <!--------------------------------------------->
                <div class="vue-inner clearfix" v-else-if="historyitem.type === 'gallery'">
                    <div class="year-bg">
                        <div class="year-bg-bg"></div>
                        <div class="year-bg-year"><div class="div-table"><div class="div-tablecell"><span>{{historyitem.year}}</span></div></div></div>
                    </div>
                    <div class="box-content bc-gallery above">

                        <div class="software-release-small above">
                            <h5>{{historyitem.subtitle}}</h5>
                            <div class="img" v-bind:style="{ 'background-image': 'url(' + historyitem.posterImage + ')' }" ></div>
                        </div>
                        <div class="software-release-large above">

                            <div class="gallery">
                                <div class="gallery__images">
                                    <ul class="gallery__images__carousel">
                                        <li v-for="galleryitem in historyitem.gallery">
                                            <img :src="galleryitem.img">
                                        </li>
                                    </ul>
                                    <div class="gallery__images__counter">
                                        <div class="gallery__images__counter__current"></div>
                                        <div class="gallery__images__counter__total">/</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="box-content bc-gallery below">

                        <div class="software-release-small below">
                            <div class="bugs">
                                <div class="bug" v-for="bug in historyitem.posterBugs">
                                    <img  :src="bug.img" />
                                </div>
                            </div>
                            <div class="link">
                                <a :href="historyitem.posterLinkout.url" target="_blank">
                                    <div class="btn btn--square">
                                        <div class="btn--square__icon">
                                            <img src="../img/global/icon-visit-site-history-off.png" :alt="historyitem.posterLinkout.text" />
                                            <img src="../img/global/icon-visit-site-history-off.png" :alt="historyitem.posterLinkout.text" />
                                        </div>
                                        <h5>
                                            {{historyitem.posterLinkout.text}}
                                        </h5>
                                    </div>

                                </a>
                            </div>
                        </div>
                        <div class="software-release-large below">
                            <div class="quote-holder">
                                <div class="l">
                                    <img src="../img/global/icon-quote-left.png" />
                                </div>
                                <div class="r"><div class="r-inner">
                                    <div class="quote">{{historyitem.quote}}</div>
                                    <div class="attribution">
                                        // {{historyitem.attribution}}
                                    </div>
                                </div></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div></div> <!--history-content-->

        <footer-bar></footer-bar>
    </div>

</template>

<script>

    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import $ from 'jquery'
    import axios from 'axios'
    import SectionTitle from '../lib/SectionTitle'
    import SlickCarousels from '../lib/SlickCarousels'
    //import ScrollToPlugin from 'gsap/src/uncompressed/plugins/ScrollToPlugin'
    import inobounce from 'inobounce'
    import siteHelpers from "@/helpers/siteHelpers";

    export default {
        name: 'history',
        components: {
            'footer-bar': Footer,
        },
        metaInfo() {
            return siteHelpers.getGlobalMeta('History')
        },
        data() {
            return {
                data: {},
                history: null,
                historygrid:null,
                maxScrollHeight: 0,
                enterTimeline: null,
                leaveTimeline: null
            }
        },
        computed: {

        },
        mounted() {
            inobounce.disable();

            window.addEventListener("resize", this.onResize);
            this.onResize(null);

            $('#history').css('opacity',0);

            this.loadJson();
        },
        methods: {
            loadJson() {
                var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
                axios.get(window.baseUrl + '/history.json'+urlparams)
                    .then((response) => {
                        this.data = response.data;
                        this.history = response.data.history;
                        this.historygrid = response.data.history.grid;
                        //console.log(this.historygrid);
                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                        //console.log(response.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

            onJsonLoaded() {
                setTimeout(() => {
                    setTimeout(() => {
                        this.animateIn();
                    }, this.$store.state.firstSection ? 550 : 700);
                    this.$store.commit('endSectionPreload');
                }, this.$store.state.firstSection ? 0 : 550);
                SectionTitle.animateIn(this.$refs.sectionTitle);
            },


            animateIn() {
                this.$helpers.globalPostLoad();

                SlickCarousels.init();

                this.resetForInitialLoad();
                $('#history').css('opacity',1);
                this.setSoftwareReleaseHeight();

                this.onScroll(null);
                window.addEventListener('scroll', this.onScroll);

                this.onResize(null);

            },

            onMouseEnter(e) {

            },
            onMouseLeave(e) {

            },

            onResize(e) {
                //SIZE software-release-small
                this.setSoftwareReleaseHeight();
                setTimeout(this.setSoftwareReleaseHeight, 100);

                this.onScroll(null);


            },

            onScroll(e) {

                //loop through news items
                var t = this;
                $('#history-content .history-item').each(function( index ) {
                    if (t.isScrolledIntoView(this, '')) {
                        t.revealHistoryItem(this);
                    }
                });

                //first time only
                if (e===null && this.$route.params.id!==undefined) {
                    //scrollto if deep linked
                    var scrollto_class = ".scrollto-" + this.$route.params.id;
                    TweenLite.to(window, 2, {scrollTo:{y:scrollto_class,offsetY:100}});
                }

            },

            setHistoryNav() {
                //scrollbar
                var sd = ($(window).scrollTop());
                var minh = 0;//was 100
                this.maxScrollHeight = $('#history-content').height() - 260;
                if (sd<minh) {
                    sd = minh;
                } else if (sd>this.maxScrollHeight) {
                    sd = this.maxScrollHeight;
                }
                /*
                if (sd<this.maxScrollHeight) {
                    sd = this.maxScrollHeight;
                }
                */

                var sdnew = sd+300;
                if (sdnew>this.maxScrollHeight) {
                    sdnew = this.maxScrollHeight;
                }
                var del = 0;
                //PART 1
                gsap.to($('#history-timeline'), 0.5,
                    {
                        height: sdnew,
                        ease: Power2.easeInOut,
                        delay:del
                    }
                );

                var sddot=sd;
                if (sddot<0){ //was 150
                    sddot=0;
                } else {
                    sddot = sdnew;
                }
                del +=0;
                gsap.to($('#dot-holder'), 0.5,
                    {
                        top: sddot,
                        ease: Power2.easeInOut,
                        delay:del
                    }
                );

                //PART 2
                sdnew = sd+900;
                if (sdnew>this.maxScrollHeight) {
                    sdnew = this.maxScrollHeight+200;
                }
                del +=1;
                gsap.to($('#history-timeline'), 0.5,
                    {
                        height: sdnew,
                        ease: Power2.easeInOut,
                        delay:del
                    }
                );

                var sddot=sd;
                if (sddot<0){ //was 150
                    sddot=0;
                } else {
                    sddot = sdnew;
                }
                del +=0;
                gsap.to($('#dot-holder'), 0.5,
                    {
                        top: sddot,
                        ease: Power2.easeInOut,
                        delay:del
                    }
                );

            },

            setSoftwareReleaseHeight() {

                $('.slick-slider').slick('refresh');


                $('.history-item').each(function( index ) {
                    var h = $('.slick-current img',this).height();
                    var th = $('.software-release-small.above h5',this).outerHeight();

                    var newh = h - th - 7;//7 is bottom margin
                    //console.log("h:"+h + "::"+th+"::"+newh+"::::index:"+this.index);
                    $('.software-release-small.above .img',this).css('height',newh);
                });


            },

            onVideoThumbOver(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.box-bg-square');
                var $image = $el.find('.featured');


                gsap.killTweensOf([$color, $image]);

                gsap.to($color, 0.3, {x: 12, y: -12, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {
                    x: -12, y: 12, force3D: true, ease: Power2.easeIn, onComplete: () => {
                        $color.addClass('slate');
                        $color.removeClass('orange');
                    }
                });
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
            },

            onVideoThumbOut(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.box-bg-square');
                var $image = $el.find('.featured');

                gsap.killTweensOf([$color, $image]);

                /*
                if ($color.hasClass('orange')) {
                    gsap.to($color, 0.3, {x: 12, y: -12, force3D: true, ease: Power2.easeIn});
                    gsap.to($image, 0.3, {
                        x: -12, y: 12, force3D: true, ease: Power2.easeIn, onComplete: () => {
                            $color.removeClass('slate');
                            $color.addClass('orange');
                        }
                    });
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
                } else {
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0, onComplete: () => {
                        $color.removeClass('slate');
                        $color.addClass('orange');
                    }});
                }
                */
                gsap.to($color, 0.3, {x: 12, y: -12, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {
                    x: -12, y: 12, force3D: true, ease: Power2.easeIn, onComplete: () => {
                        $color.removeClass('slate');
                        $color.addClass('orange');
                    }
                });
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});

            },

            resetForInitialLoad() {
                //hide news items
                $('#history-content .history-item').css('opacity',0);

                //dynamic quote font sizes
                $('.history-item').each(function( index ) {
                    var $quote_holder = $('.quote-holder',this);
                    if ($quote_holder.length) {
                        var fs = "44px";
                        var lh = "48px";
                        var $quote = $(".quote",$quote_holder);
                        var $numWords = $quote.text().split(" ").length;
                        if (($numWords >= 5) && ($numWords < 10)) {
                            fs = "40px";
                            lh = "44px";
                        } else if (($numWords >= 10) && ($numWords < 15)) {
                            fs = "36px";
                            lh = "40px";
                        } else {
                            fs = "18px";
                            lh = "22px";
                        }
                        $quote.css({"font-size":fs,"line-height":lh});
                    }
                });



            },

            revealHistoryItem(t) {
                if ($(t).attr('is-revealed') !== '1') {
                    $(t).attr('is-revealed', '1');

                    //animate in
                    var del = 0;
                    var tme = 0.75;
                    var del_stagger = 0.1;
                    var yOffset = 75;
                    var xOffset = 50;


                    this.setHistoryNav();
                    del = 0.4; //delay after timeline nav (nav takes 0.5)

                    //YEAR
                    gsap.fromTo($('.year-bg-year', t), 0.25,
                        {
                            opacity: 0,
                            scale:1.1
                        },
                        {
                            opacity: 1,
                            scale:1,
                            ease: Power2.easeInOut,
                            delay:del
                        }
                    );
                    del += 0.25;
                    gsap.fromTo($('.year-bg-bg', t), 1.5,
                        {
                            width:0,
                        },
                        {
                            width:'100%',
                            ease: Power2.easeInOut,
                            delay:del
                        }
                    );


                    //SOFTWARE RELEASE
                    gsap.fromTo($('.software-release-small', t), tme,
                        {
                            opacity: 0,
                            y: yOffset,
                            scale:0.8,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scale:1,
                            ease: Power2.easeInOut,
                            delay: del

                        }
                    );
                    gsap.fromTo($('.software-release-large', t), tme,
                        {
                            opacity: 0,
                            y: yOffset,
                            scale:0.8,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scale:1,
                            ease: Power2.easeInOut,
                            delay: del+ del_stagger

                        }
                    );



                    //PHOTO + TEXT
                    gsap.fromTo($('.box-photo-img', t), tme,
                        {
                            opacity: 0,
                            y: yOffset,
                            scale:0.8,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scale:1,
                            ease: Power2.easeInOut,
                            delay: del

                        }
                    );
                    gsap.fromTo($('.quote-holder', t), tme,
                        {
                            opacity: 0,
                            y: yOffset,
                            scale:0.8,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scale:1,
                            ease: Power2.easeInOut,
                            delay: del+del_stagger

                        }
                    );

                    gsap.fromTo($('.news-item-img', t), tme,
                        {
                            opacity: 0,
                            y: yOffset,
                            scale:0.8,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scale:1,
                            ease: Power2.easeInOut,
                            delay: del

                        }
                    );
                    del += del_stagger;
                    gsap.fromTo($('h5', t), tme,
                        {
                            opacity: 0,
                            scaleX: 1.05,
                            x: xOffset,
                        },
                        {
                            opacity: 1,
                            scaleX: 1,
                            x:0,
                            ease: Power2.easeOut,
                            delay: del

                        }
                    );
                    del += del_stagger;
                    gsap.fromTo($('h2', t), tme,
                        {
                            opacity: 0,
                            scaleX: 1.05,
                            x: xOffset
                        },
                        {
                            opacity: 1,
                            scaleX: 1,
                            x: 0,
                            ease: Power2.easeOut,
                            delay: del

                        }
                    );
                    del += del_stagger;
                    gsap.fromTo($('.description', t), tme,
                        {
                            opacity: 0,
                            x: xOffset
                        },
                        {
                            opacity: 1,
                            x: 0,
                            ease: Power2.easeOut,
                            delay: del

                        }
                    );
                    del += del_stagger;

                    //toggle on
                    $(t).css('opacity', 1);
                }
            },


            isScrolledIntoView(elem,type) {
                var docViewTop = $(window).scrollTop();
                var docViewBottom = docViewTop + $(window).height();

                var h =  $(elem).height();
                var wh = docViewBottom-docViewTop;

                var elemTop = $(elem).offset().top;
                var elemBottom = elemTop +h;

                //onpage - nothing off
                //var ret = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                /*
                if (h > wh) {
                    var ret = (elemBottom <= docViewBottom+(wh/3));
                } else {
                    var ret = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                }
                */
                //var ret = (elemBottom <= docViewBottom+(wh/3));
                var ret = (elemTop <= docViewBottom-200);//NUM is slight bottom padding

                //console.log(elem.id+":"+h + "::::dvt:"+docViewTop+"::dvb:"+docViewBottom+":::wh:"+wh+"::et:"+elemTop+"::eb:"+elemBottom+"__ret:"+ret);
                return ret;
            },

            destroyed() {
                window.removeEventListener("resize", this.onResize);
                window.removeEventListener('scroll', this.onScroll);
            }

        }
    }

</script>
