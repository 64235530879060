<template>
    <Modal @close="onClose">
        <template v-slot:content>
            <div slot="content" class="video__modal">
                <div class="maintain-aspect-ratio">
                    <iframe :src="'https://www.youtube.com/embed/' + $route.params.videoId + '?autoplay=1&rel=0&showinfo=0'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>

    import Modal from '@/components/Modal'
    import axios from 'axios'

    export default {
        components: {
          Modal
        },
        data() {
            return {
                //videoId: null,
            }
        },
        mounted() {
            
        },
        methods: {
            
        }
    }

</script>
