<template >
    <div id="careers-detail" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">Careers</h1>

        <div class="hero">
            <div class="hero__text">
                <div class="hero__text__bg">
                    <div class="hero__text__bg__wrap">
                        <h4 class="hero__text__subtitle">{{ data.game }}</h4>
                        <h1 class="hero__text__title">{{ data.title }}</h1>
                    </div>
                </div>
            </div>
            <div class="hero__image" style="background-image: url('/img/careers/hero-image-detail2.jpg')">
                <div class="hero__image__white"></div>
                <a :href="data.applyUrl" target="_blank">
                    <div class="btn btn--huge hero__image__apply-btn">
                        <div class="btn--huge__text">
                            <div>APPLY<span>APPLY</span></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="job lockout lockout--careers">
            <div class="job__desc">
                <h1>JOB DETAILS:</h1>
                <!--<h2>THIS IS PLACEHOLDER COPY, YET TO BE DETERMINED WHAT DATASHOULD GO HERE</h2>-->
                <!--<h4>WHAT YOU'LL DO:</h4>-->

                <div v-html="data.description" ref="description"></div>

                <!--
                <h2 class="opportunity">The opportunity to create groundbreaking games with some of the best developer
                    talent in the
                    industry awaits our future team members. </h2>
                    -->

                <a :href="data.applyUrl" target="_blank">
                    <div class="btn btn--huge">
                        <div class="btn--huge__text">
                            <div>APPLY NOW<span>APPLY NOW</span></div>
                        </div>
                    </div>
                </a>

            </div>
            <div class="job__companies">
                <!--
                <h3>
                    Requisition Number: {{ data.reqId }}<br>
                    Location: {{ data.location }}<br>
                    Date Opened: {{ data.dateOpened }}
                </h3>
                <div class="about__group">
                    <h2>ABOUT EA</h2>
                    <p>Electronic Arts Inc. is a leading global interactive entertainment software company. EA delivers
                        games, content and online services for Internet-connected consoles, personal computers, mobile
                        phones and tablets.</p>
                </div>
                <div class="about__group">
                    <h2>ABOUT RESPAWN</h2>
                    <p>Respawn Entertainment is a AAA game development studio that was founded in 2010 by former
                        co-founders of Infinity Ward and creators of the multi-billion dollar Call of Duty and Modern
                        Warfare franchises. In 2017, Respawn was acquired by Electronic Arts, a leading global
                        interactive entertainment publisher. We have shipped Titanfall and Titanfall 2 to critical
                        acclaim, and are currently hiring for our Star Wars ™, Titanfall, and Unannounced VR game
                        development teams. Our motto is gameplay first, and the opportunity to create groundbreaking
                        games with some of the best developer talent in the industry awaits our future team members.</p>
                    <p>We’re looking for highly skilled Level Designer who will embrace our philosophy and share
                        his/her hard earned expertise to create an incredible experience for our players. If this sounds
                        like you, we're looking forward to seeing your resume!</p>
                </div>
                -->
                <div class="job__share">
                    <h2>SHARE THIS JOB</h2>
                    <ul class="job__share__icons">
                        <li>
                            <a :href="'https://www.facebook.com/sharer.php?u=https://www.respawn.com' + currentURL " target="_blank" >
                                <img src="../img/global/share/facebook.png" alt="Facebook">
                            </a>
                        </li>
                        <li>
                            <a :href="'https://www.linkedin.com/shareArticle?mini=true&title=Check out this job: &url=https://www.respawn.com' + currentURL " target="_blank" >
                                <img src="../img/global/share/linkedin.png" alt="Linked In">
                            </a>
                        </li>
                        <li>
                            <a :href="'https://twitter.com/intent/tweet?text=Check out this job: https://www.respawn.com' + currentURL " target="_blank" >
                                <img src="../img/global/share/twitter.png" alt="Twitter">
                            </a>
                        </li>
                        <li>
                            <a :href="'mailto:?subject=Check out this job&body=Check out this job:%0D%0Ahttps://www.respawn.com' + currentURL " target="_blank" >
                                <img src="../img/global/share/email.png" alt="Email">
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="other__jobs">
                    <h2>OTHER JOBS</h2>
                    <ul class="other__jobs__positions">
                        <li v-for="position in data.positions">
                            <a :href="position.link" data-router-link >
                                <h4 class="other__jobs__positions__title">{{position.title}}</h4>
                                <h5 class="other__jobs__positions__game">{{position.game}}</h5>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <router-view/>
        <footer-bar></footer-bar>

    </div>
</template>

<script>


    import Footer from '@/components/Footer'
    import SectionTitle from '../lib/SectionTitle'
    import axios from 'axios'
    import $ from 'jquery'
    import inobounce from 'inobounce'
    import router from '../router'
    import siteHelpers from "@/helpers/siteHelpers"
    import { nextTick } from 'vue';
    import {gsap, Power3} from "gsap";

    export default {
        name: 'careers-detail-inner',
        metaInfo() {
            return siteHelpers.getGlobalMeta(this.data.title, this.data.title + ' | Careers')
        },
        components: {
            'footer-bar': Footer
        },
        data() {
            return {
                remounterKeyCareersDetail:'',
                data: {},
                currentURL: window.location.pathname
            }
        },
        computed: {
            remounterKey: {
                get: function () {
                    return this.$store.state.remounterKey;
                },
                set: function (value) {

                }
            }
        },
        watch: {
            remounterKey: function () {
                this.remounterKeyCareersDetail = this.$store.state.remounterKey
                this.forceUpdate();
            }
        },
        mounted() {
            this.loadJson();
        },
        methods: {

            loadJson() {
                var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
                axios.get(window.baseUrl + '/careers/' + this.$route.params.id + '.json'+urlparams)
                    .then((response) => {
                        this.data = response.data;

                        //dynamic meta title load based on JSON
                        $('head title').html(this.data.title + " | Careers | " + this.$siteName)


                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

            onJsonLoaded() {
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, 500);

                $(this.$refs.description).find('*').removeAttr('style');
                $('a[data-router-link]').on('click', this.onRouterLinkClick);


                //shrink title if tons of words
                var big_title = $('.hero .hero__text__title');
                //$('.hero .hero__text__title').html('sdkjsf fgkjfdkjfsdhg skfjdgkdjfgdskfjg dkjgdfkgdfgdjkgfdjksfg');
                //$('.hero .hero__text__title').html('A job title goes sjksfdjfgdfhgj dfh sdfkjke jk for se');
                var big_title_chararacter_count = big_title.text().length;
                if (big_title.length) {
                    var fs = "70px";
                    var lh = "74px";
                    if (big_title_chararacter_count>50) {
                        fs = "45px";
                        lh = "49px";
                    } else if (big_title_chararacter_count>40) {
                        fs = "50px";
                        lh = "54px";
                    } else if (big_title_chararacter_count>30) {
                        fs = "60px";
                        lh = "64px";
                    }
                    big_title.css({"font-size":fs,"line-height":lh});
                }


                inobounce.disable();
                SectionTitle.animateIn(this.$refs.sectionTitle);
                this.animateIn();
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var isMobile = $(window).width() < 700;
                var $orangeHero = $('.hero__text__bg');
                var $whiteImageBlocker = $('.hero__image__white');
                var $applyBtn = $('.hero__image__apply-btn');
                var narrowOrange = $orangeHero.width() * 0.3;
                var targetX = $orangeHero.width() + (isMobile ? 0 : 230);
                var orangeStartingHeight = $orangeHero.outerHeight();
                var $text = $('.hero__text__bg__wrap');
                var $job = $('.job');

                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0 : 0.65);

                tl.set($orangeHero, {width: $orangeHero.width() * 0.3, height: orangeStartingHeight})
                tl.fromTo($orangeHero, 1, {x: -narrowOrange, force3D: true},
                    {x: targetX, force3D: true, ease: Power3.easeInOut});
                tl.to($orangeHero, 0.6, {
                    x: 0,
                    width: '100%',
                    force3D: true,
                    ease: Power3.easeInOut,
                    clearProps: 'all'
                }, '-=0.21');
                tl.fromTo($whiteImageBlocker, 0.6, {xPercent: 0}, {
                    xPercent: -100,
                    force3D: true,
                    ease: Power3.easeInOut
                }, '-=0.55');
                tl.fromTo($applyBtn, 0.7, {xPercent: 100, opacity:0}, {
                    xPercent: 0,
                    opacity: 1,
                    force3D: true,
                    ease: Power3.easeInOut
                }, '-=0.52');

                tl.fromTo($text, 0.45, {x: 200, opacity: 0}, {
                    x: 0,
                    opacity: 1,
                    force3D: true,
                    ease: Power3.easeOut
                }, 1.12);
                tl.fromTo($job, 0.7, {y: 100, opacity: 0}, {
                    y: 0,
                    force3D: true,
                    opacity: 1,
                    ease: Power3.easeInOut
                }, '-=0.6');

            },

            onRouterLinkClick(e) {
                e.preventDefault();
                let href = $(e.currentTarget).attr('href');
                router.push({path: href});
            },

        },

        destroyed() {

        }

    }

</script>
