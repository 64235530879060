<template>
    <div class="modal" @click="close">
        <div class="modal__bg"></div>
        <div class="modal__inner" @click.stop>
            <slot name="content" class="modal__inner__content"></slot>
            <div class="modal__inner__close" @click="close">
                <img src="../img/global/icon-x.png">
            </div>
        </div>
    </div>
</template>

<script>

    import $ from 'jquery'
    import router from '../router'
    import { gsap, Power2 } from "gsap";

    export default {
        name: 'Modal',
        data() {
            return {

            }
        },
        mounted() {
            this.open();
        },
        methods: {

            open() {
                 $('html').addClass('no-scroll');
                 // $('body').addClass('no-scroll');
                gsap.fromTo($('.modal'), 0.35, {opacity:0}, {opacity:1, ease:Power2.easeOut})
            },

            close() {

                this.$emit('close');
                $('html').removeClass('no-scroll');

                // dynamically determine parent
                // can't do router.go(-1) because it breaks on hard deeplink
                let path = window.location.pathname,
                    lastSlashIndex = path.lastIndexOf('/'),
                    secondToLastSlashIndex = path.lastIndexOf('/', lastSlashIndex - 1),
                    targetUri = path.substr(0, secondToLastSlashIndex);

                router.push(targetUri);

            }
            
        }
    }
</script>
