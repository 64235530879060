import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
//import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import helpers from './helpers';
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App);

// Use plugins
app.use(router);
app.use(store);
app.use(Vue3TouchEvents);

//const metaManager = createMetaManager()
//app.use(metaManager);

// Define a plugin to use helpers globally
const helpersPlugin = {
    install(app) {
        app.config.globalProperties.$helpers = helpers;
    }
};
app.use(helpersPlugin);

// Setup global properties
app.config.globalProperties.$siteName = 'Respawn Entertainment';
app.config.globalProperties.$siteDescription = 'Academy Award-winning studio behind critically acclaimed multi-platform games Apex Legends, Titanfall, Titanfall 2, Star Wars™ Jedi: Fallen Order, and Medal of Honor: Above and Beyond.';
app.config.globalProperties.$shareImage = 'https://www.respawn.com/share.png';
app.config.globalProperties.$shareURLBase = 'https://www.respawn.com';
app.config.globalProperties.$careers = {
    isLoaded: false
};

window.config = {
    siteName: app.config.globalProperties.$siteName,
    siteDescription: app.config.globalProperties.$siteDescription,
    shareURLBase: app.config.globalProperties.$shareURLBase,
    shareImage: app.config.globalProperties.$shareImage
}

window.careers = {
    isLoaded:false
}

axios.get(window.baseUrl + '/careers.json').then((response) => {
    window.careers.isLoaded = true
    window.careers.data = response.data;

    let categories = window.careers.data.categories
    let categoriesFlat = []
    let selectionGroup = []
    let selectionTeam = []
    let selectionLocation = []
    let selectionGroupCounts = []
    let selectionTeamCounts = []
    let selectionLocationCounts = []

    let interns = []
    for (let i=0;i<categories.length;i++) {
        let category = categories[i]
        for (let ii=0; ii  <category.positions.length;ii++) {
            let position = category.positions[ii]
            let tobj = {
                id: position.id,
                group: (category.title) ? category.title.toUpperCase().trim() : '(NONE)',
                team: (position.game) ? position.game.toUpperCase().trim() : '(NONE)',
                location: (position.location) ? position.location.toUpperCase().trim() : '(NONE)',
                role: position.title,
            }
            categoriesFlat.push(tobj)

            selectionGroup.push(tobj.group)
            selectionTeam.push(tobj.team)
            selectionLocation.push(tobj.location)

            // group counts
            if (!selectionGroupCounts[tobj.group]) {
                selectionGroupCounts[tobj.group] = 0
            }
            selectionGroupCounts[tobj.group] ++

            // team counts
            if (!selectionTeamCounts[tobj.team]) {
                selectionTeamCounts[tobj.team] = 0
            }
            selectionTeamCounts[tobj.team] ++

            // location counts
            if (!selectionLocationCounts[tobj.location]) {
                selectionLocationCounts[tobj.location] = 0
            }
            selectionLocationCounts[tobj.location] ++

            // interns
            let positionTitle = position.title.toString()
            let regex = /\b(intern)\b/i
            if (positionTitle.match(regex)) {
                interns.push(tobj)
            }

        }//for ii
    }//for i

    selectionGroup = [...new Set(selectionGroup)]
    selectionTeam = [...new Set(selectionTeam)]
    selectionLocation = [...new Set(selectionLocation)]
    interns = [...new Set(interns)]

    selectionGroup.sort()
    selectionTeam.sort()
    selectionLocation.sort()
    interns.sort()

    window.careers.calculated = {
        interns: interns,
        categoriesFlat:categoriesFlat,
        selectionGroup:selectionGroup,
        selectionTeam:selectionTeam,
        selectionLocation:selectionLocation,
        selectionGroupCounts:selectionGroupCounts,
        selectionTeamCounts:selectionTeamCounts,
        selectionLocationCounts:selectionLocationCounts
    }

    // Mount the application
    app.mount('#app');

})




