<template>
    <div id="terms" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">Terms & Conditions</h1>
        <div class="lockout">
            <p>
                Coming soon
            </p>
        </div>
        <footer-bar></footer-bar>
    </div>
</template>

<script>

    import Vue from 'vue'
    import axios from 'axios'
    import Footer from '@/components/Footer'
    import SectionTitle from '../lib/SectionTitle'

    export default {
        name: 'privacy',
        components: {
            'footer-bar': Footer
        },
        metaInfo() {
            return siteHelpers.getGlobalMeta('Terms & Conditions')
        },
        mounted() {
            // tells section loader to wipe up
            setTimeout(() => {
                this.$store.commit('endSectionPreload');
            }, 550);
            SectionTitle.animateIn(this.$refs.sectionTitle);
        }
    }

</script>
