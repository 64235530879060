<template>
    <div v-if="data" id="culture" class="section sticky-footer">
        <div class="hero hero-generic">
            <div class="hero__text">
                <div class="hero__text__bg">
                    <div class="hero__text__bg__wrap">
                        <h4 v-if="data.subtitle !== undefined" class="hero__text__subtitle" v-html="data.subtitle"></h4>
                        <h1 v-if="data.title !== undefined" class="hero__text__title" v-html="data.title"></h1>
                    </div>
                </div>
            </div>
            <div class="hero__image" :style="'background-image: url(' + data.heroImage + ')'">
                <div class="hero__image__white"></div>
            </div>
        </div>
        <div class="lockout">
            <div class="culture-values">
                <div class="culture-values-content">
                    <h2 class="culture-subtitle" v-html="data.company_values.title"></h2>
                    <div v-html="data.company_values.content"></div>
                </div>

                <div class="culture-values__images">
                    <img src="@/img/culture/shape.jpg" alt="" class="gallery-shape" />
                    <ul class="gallery__images__carousel">
                        <li v-for="galleryItem in data.company_values.slider">

                            <div class="yt-video" v-if="galleryItem.type == 'video'">
                                <!-- <iframe :src="`https://www.youtube.com/embed/`+ galleryItem.youtube_id" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> -->

                                <router-link :to="'video/' + galleryItem.youtube_id" append class="poster">
                                  <img src="../img/global/icon-button-play.svg" alt="" class="cta">

                                    <img :src="`https://img.youtube.com/vi/`+galleryItem.youtube_id+`/maxresdefault.jpg`" alt="" class="thumb" v-if="galleryItem.image.length < 12" />
                                    <img :src="galleryItem.image" alt="" class="thumb" v-else/>
                                </router-link>

                            </div>

                            <div v-else>
                                <img :src="galleryItem.image" alt="" class="thumb" />
                            </div>

                        </li>
                    </ul>
                </div>

            </div>
        </div>

        <div class="lockout--culture galleries">
            <div class="rtc lockout">
                <div class="culture-focus-content">
                    <h2 class="culture-subtitle" v-html="data.focus.title"></h2>
                    <div v-html="data.focus.content"></div>
                </div>
                <div class="galleries">
                    <div class="gallery gallery-focus-area" :class="index % 2 != 0 && 'gallery--reverse'" v-for="(area, index) in data.focus.areas">
                        <div class="focus-area-content">
                            <h3 :data-index="`0${index + 1}`"></h3>
                            <p>{{ area.content }}</p>
                        </div>
                        <div class="gallery__images focus-area-image">
                            <img :src="area.image" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="culture-award">
            <div class="culture-award-container">
                <img src="@/img/culture/glaad.jpg" class="glaad-bg" alt="" />
            </div>
        </div>

        <div class="lockout--culture">
            <div class="rtc lockout">
                <div class="culture-partners">
                    <h2 class="culture-subtitle">{{ data.partners.title }}</h2>

                    <div class="partners">
                        <div class="partner" v-for="partner in data.partners.partners">
                            <div class="partner-image">
                                <img :src="partner.logo_image" alt="" />
                            </div>

                            <h4>{{ partner.title }}</h4>
                            <p v-html="partner.content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="background-image: url('/img/culture/bg-pattern.jpg')" class="culture-careers">
            <img src="@/img/culture/careers-shape.png" class="shapes-1" alt="" />
            <img src="@/img/culture/careers-shape.png" class="shapes-2" alt="" />

            <div class="culture-careers-content">
                <div class="culture-careers-text">
                    <h3 class="culture-subtitle">{{ data.careers.title }}</h3>
                    <p>{{ data.careers.content }}</p>
                    <router-link to="/careers">
                        <div class="btn btn--huge btn--huge--reversed white">
                            <div class="btn--huge__text">
                                <div>JOIN RESPAWN<span>JOIN RESPAWN</span></div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <router-view/>

        <footer-bar></footer-bar>

    </div>
</template>

<script>
    import { nextTick } from 'vue';
    import Footer from "@/components/Footer";
    import siteHelpers from "@/helpers/siteHelpers";
    import axios from "axios";
    import inobounce from "inobounce";
    import SectionTitle from "../lib/SectionTitle";
    import SlickCarousels from "../lib/SlickCarousels";
    import $ from "jquery";
    import { gsap, Power3 } from "gsap";

    export default {
        name: "culture",
        components: {
            "footer-bar": Footer,
        },
        data() {
            return {
                data: null,
                window: window,
            };
        },
        metaInfo() {
            return siteHelpers.getGlobalMeta("Culture");
        },
        methods: {
            loadJson() {
                axios
                    .get(window.baseUrl + "/culture.json")
                    .then((response) => {
                        this.data = response.data;
                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                        //console.log(this.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            onJsonLoaded() {
                setTimeout(() => {
                    this.$store.commit("endSectionPreload");
                }, 500);
                inobounce.disable();
                SlickCarousels.init();
                this.animateIn();
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var isMobile = $(window).width() < 700;
                var $orangeHero = $(".hero__text__bg");
                var $whiteImageBlocker = $(".hero__image__white");
                var narrowOrange = $orangeHero.width() * 0.3;
                var targetX = $orangeHero.width() + (isMobile ? 0 : 230);
                var orangeStaringHeight = $orangeHero.outerHeight();
                var $text = $(".hero__text__bg__wrap");
                var $rtc = $(".rtc");

                var tl = gsap.timeline();
                //tl.delay(this.$store.state.firstSection ? 0.3 : 0.91);
                tl.delay(this.$store.state.firstSection ? 0 : 0.65);
                tl.set($orangeHero, {
                    width: $orangeHero.width() * 0.3,
                    height: orangeStaringHeight,
                });
                tl.fromTo(
                    $orangeHero,
                    1, {
                        x: -narrowOrange,
                        force3D: true
                    }, {
                        x: targetX,
                        force3D: true,
                        ease: Power3.easeInOut
                    }
                );
                tl.to(
                    $orangeHero,
                    0.6, {
                        x: 0,
                        width: "100%",
                        force3D: true,
                        ease: Power3.easeInOut,
                        clearProps: "all",
                    },
                    "-=0.21"
                );
                tl.fromTo(
                    $whiteImageBlocker,
                    0.6, {
                        x: '0%'
                    }, {
                        x: '-100%',
                        force3D: true,
                        ease: Power3.easeInOut
                    },
                    "-=0.55"
                );
                tl.fromTo(
                    $text,
                    0.45, {
                        x: 200,
                        opacity: 0
                    }, {
                        x: 0,
                        opacity: 1,
                        force3D: true,
                        ease: Power3.easeOut
                    },
                    1.12
                );
                tl.fromTo(
                    $rtc,
                    0.7, {
                        y: 100,
                        opacity: 0
                    }, {
                        y: 0,
                        force3D: true,
                        opacity: 1,
                        ease: Power3.easeInOut
                    },
                    "-=0.6"
                );
            },
        },
        mounted() {
            this.loadJson();
        },
    };
</script>