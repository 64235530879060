<template>
    <div class="perks-info-block">
        <div class="top">
            <img class="perks-bg" src="@/img/studio/perks-bg.png" alt="Perks" >
            <div class="copy">
                <div class="lr">
                    <div class="l">
                        <ul>
                            <li v-for="perkLine in perks">
                                <div class="plus"></div>{{perkLine.line}}
                            </li>
                        </ul>
                    </div>
                    <div class="r">
                        <router-link to="/perks">
                            <div class="btn btn--huge btn--huge--reversed">
                                <div class="btn--huge__text">
                                    <div>VIEW ALL PERKS<span>VIEW ALL PERKS</span></div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="perks-mobile-btn">
            <router-link to="/perks">
                <div class="btn btn--huge btn--huge--reversed">
                    <div class="btn--huge__text">
                        <div>VIEW ALL PERKS<span>VIEW ALL PERKS</span></div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "PerksBlock",
    props: {
        perks: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }

    }

}
</script>
