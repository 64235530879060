import $ from 'jquery'
import Slick from 'slick-carousel';

var simpleCarouselSlides = []
function init(autoPlay = false) {
    $('.simple_carousel').each((i, el) => {
        simpleCarouselSlides[i] = $(el);
        simpleCarouselSlides[i]
            .on('init', function (event, slick) {

            })
            .slick({
                speed: 350,
                //mobileFirst: true,
                autoplay: autoPlay,
                autoplaySpeed: 5000,
                infinite: true,
                arrows: true,
                dots:true,
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            speed: 500,
                        }
                    }
                ]
            })
            .on('beforeChange', function (event, slick, currentSlide, nextSlide) {

            })

    });
}
    export default {init, simpleCarouselSlides}
