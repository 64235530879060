<template>
    <div id="careers" class="section sticky-footer" v-if="data">
        <h1 class="section-title lockout" ref="sectionTitle">Careers</h1>

        <div class="lockout--badge">
            <div class="items">
                <div class="badge">
                    <img  src="@/img/careers/badge.svg" alt="GamesIndustry.biz - 2022 Best Places to Work Winner"/>
                </div>
                <div class="dots">
                </div>
            </div>
        </div>

        <div class="lockout--careers">

            <div class="lockout">
                <div class="intro">
                    <div class="intro__text">
                        <div class="intro__text intro__text--desktop rtc-no-padding-margins rich-text" v-html="data.introText === undefined ? '' : data.introText"></div>
                        <div class="intro__text intro__text--mobile rtc-no-padding-margins rich-text">
                            <div v-html="introTextMobile"></div>
                            <div class="intro__text--more" @click="introTextTruncated = false" v-if="introTextTruncated">
                                MORE
                            </div>
                        </div>
                    </div>
                    <div v-if="1==2" class="intro__video">
                        <div class="intro__video__title intro__video__title--mobile">
                            <h4>{{ data.video.title === undefined ? '' : data.video.title }}</h4>
                        </div>
                        <router-link v-if="(data.video.link === undefined || data.video.link === '')" :to="'video/' + (data === undefined ? '' : data.video.youtubeId)"
                             v-on:mouseenter.native="onVideoThumbOver"
                             v-on:mouseleave.native="onVideoThumbOut">
                            <div class="intro__video__thumb">
                                <div class="video-thumb">
                                    <img :src="data.video.youtubeThumb" alt="Respawn Office Culture"
                                         class="video-thumb__img"/>
                                    <div class="video-thumb__box"></div>
                                    <img src="../img/global/icon-play.png" class="video-thumb__play"/>
                                </div>
                            </div>
                        </router-link>
                        <a v-else :href="data.video.link" target="_blank"
                           v-on:mouseenter="onVideoThumbOver"
                           v-on:mouseleave="onVideoThumbOut">
                            <div class="intro__video__thumb">
                                <div class="video-thumb">
                                    <img :src="data.video.youtubeThumb" alt="Respawn Office Culture"
                                         class="video-thumb__img"/>
                                    <div class="video-thumb__box"></div>
                                    <img src="../img/global/icon-play.png" class="video-thumb__play"/>
                                </div>
                            </div>
                        </a>

                        <div class="intro__video__title intro__video__title--desktop">
                            <h4>{{ data.video.title === undefined ? '' : data.video.title }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--
        <div class="lockout--badge mobile">
            <div class="items">
                <div class="badge">
                    <img  src="@/img/careers/badge.svg" alt="GamesIndustry.biz - 2022 Best Places to Work Winner"/>
                </div>
                <div class="dots">
                </div>
            </div>
        </div>
        -->

        <careers-pickers @totalRowsShown="totalRowsShownUpdate" ref="cp" extraClasses="listings"></careers-pickers>

        <div class="lockout--careers careers-table">
            <div class="lockout">
                <div class="row header" v-if="!noResultsMessage" >
                    <div class="col col-role">
                        <p>Role:</p>
                    </div>
                    <div class="col col-group">
                        <p>Group:</p>
                    </div>
                    <div class="col col-team">
                        <p>Team: </p>
                    </div>
                    <div class="col col-location">
                        <p>Location: </p>
                    </div>
                    <div class="col col-link">
                        &nbsp;
                    </div>
                </div>
                <div class="row" v-for="(row, i) in categoriesFlat" :len="categoriesFlat.length" v-show="showRow(row)">
                    <div class="col col-role">
                        <p class="mobile-headers">Role:</p>
                        <p>{{ blankFormatter(row.role) }}</p>
                    </div>
                    <div class="col col-group">
                        <p class="mobile-headers">Group:</p>
                        <p>{{ blankFormatter(row.group) }}</p>
                    </div>
                    <div class="col col-team">
                        <p class="mobile-headers">Team:</p>
                        <p>{{ blankFormatter(row.team) }}</p>
                    </div>
                    <div class="col col-location">
                        <p class="mobile-headers">Location:</p>
                        <p>{{ blankFormatter(row.location) }}</p>
                    </div>
                    <div class="col col-link">
                        <router-link :to="'/careers/'+ row.id">
                            <p>Learn More <span class="arrow"></span></p>
                        </router-link>
                    </div>
                </div>
                <div v-if="noResultsMessage" class="row">
                    <p>No results found.  Please change your filters.</p>
                </div>
            </div>
        </div>


        <div class="lockout--careers final">
            <div class="intro final lockout">
                <div class="intro__text2 rtc-no-padding-margins" v-html="data.outroText === undefined ? '' : data.outroText"></div>
            </div>
        </div>

        <router-view/>
        <footer-bar></footer-bar>

    </div>
</template>

<script>

    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import SectionTitle from '../lib/SectionTitle'
    import axios from 'axios'
    import $ from 'jquery'
    import inobounce from 'inobounce'
    import Dropdown from '@/components/Dropdown'
    import CareersPickers from '@/components/CareersPickers'
    import router from '../router'
    import siteHelpers from "@/helpers/siteHelpers";
    import { gsap, Power3, Power2 } from "gsap";

    export default {
        name: 'careers',
        metaInfo() {
            return siteHelpers.getGlobalMeta('Careers')
        },
        components: {
            'footer-bar': Footer,
            Dropdown,
            'careers-pickers':CareersPickers
        },
        data() {
            return {
                data: null,

                categories: false,
                categoriesFlat: [],

                introTextTruncated: true,
                introTextHeight: 0,
                positions: [],
                window:window,

                resultsToggler: Math.random()
            }
        },
        mounted() {
            this.loadJasonInit()

        },

        computed: {
            introTextMobile: function () {
                if (this.data.introText === undefined) return '';

                var fullText = this.data.introText;
                if (this.introTextTruncated) {
                    fullText = fullText.substring(0, 225).trim() + '...';
                }
                this.introTextHeight = $('.intro__text--mobile').height();

                return fullText;
            },
            noResultsMessage() {
                let ret = false
                if (this.resultsToggler) {
                    ret = (this.$refs.cp && this.$refs.cp.totalRowsShown === 0)
                }
                return ret

            },
            anyFilterSelected() {
                let ret = false
                if (this.resultsToggler) {
                    ret = (this.$refs.cp && this.$refs.cp.anyFilterSelected)
                }
                return ret
            }

        },

        watch: {
            introTextTruncated: function (truncated) {
                // expand intro text when "more" is clicked
                nextTick(() => {
                    if (truncated == false) {
                        var $introText = $('.intro__text--mobile');
                        gsap.fromTo($introText, 1,
                            {height: this.introTextHeight},
                            {height: $('.intro__text--mobile').height(), ease: Power2.easeInOut});
                    }
                });
            },
        },

        destroyed() {
            $('.category__tab').off('click', (e) => this.onTabClick(e));
            $('.rich-text a').off('click', this.onRouterLinkClick);
        },


        methods: {
            loadJasonInit() {
                if (this.window.careers.isLoaded) {
                    this.loadJson()
                } else {
                    setTimeout(this.loadJasonInit,500)
                }
            },
            loadJson() {
                this.data = this.window.careers.data;
                this.categoriesFlat = this.window.careers.calculated.categoriesFlat


                nextTick(() => {
                    this.onJsonLoaded();
                });
                // console.log(this.data);
            },

            onJsonLoaded() {

                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, this.$store.state.firstSection ? 0 : 550);

                SectionTitle.animateIn(this.$refs.sectionTitle);
                inobounce.disable();
                this.animateIn();
                this.setTabZIndexes();

                $('.category__panel').slideUp(0);
                $('.category__tab').on('click', (e) => this.onTabClick(e));
                this.setPositionsArrays();

                this.initEvents();
            },

            initEvents() {
                $('.rich-text a').on('click', this.onRouterLinkClick);
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var tl = gsap.timeline();
                //tl.delay(this.$store.state.firstSection ? 0.5 : 0.8);
                //let del = (this.$store.state.firstSection) ? 0.5 : 0.8

                tl.fromTo($('.lockout--badge .dots'), 0.5,
                    {
                        x:'100%',
                        opacity: 0
                    },
                    {
                        x: '0%',
                        opacity: 1,
                        force3D: true,
                        ease: Power3.easeInOut,
                    },
                    0
                );
                tl.fromTo($('.lockout--badge .badge'), 1,
                    {
                        "clip-path": "circle(0% at 50% 50%)",
                        opacity: 0
                    },
                    {
                        "clip-path": "circle(100% at 50% 50%)",
                        opacity: 1,
                        force3D: true,
                        ease: Power3.easeInOut,
                    },
                    0.15
                );

                tl.fromTo(['.intro__text', '.intro__video', '.openings-title', '.intro__text2', '.table-filter', '.table-filter-mobile', '.careers-table'],
                    {
                        y: 250,
                        opacity: 0 },
                    {
                        duration: 0.65,
                        y: 0,
                        force3D: true,
                        ease: "power2.inOut",
                        opacity: 1,
                        stagger: 0.05
                    }
                );

                tl.from('.careers-table',
                    {
                        y: 600,
                        opacity: 0,
                        force3D: true,
                        ease: "power3.inOut"
                    },
                    {
                        duration: 1,
                        stagger: {
                            each: 0.035,
                            start: 0.05
                        }
                    }
                );


            },

            setTabZIndexes() {
                $('.category').each((i, el) => {
                    $(el).css({zIndex: 50 - i});
                });
            },

            onVideoThumbOver(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.video-thumb__box');
                var $image = $el.find('.video-thumb__img');

                gsap.killTweensOf([$color, $image]);

                gsap.to($color, 0.3, {x: 12, y: -12, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {
                    x: -12, y: 12, force3D: true, ease: Power2.easeIn, onComplete: () => {
                        $color.addClass('orange');
                    }
                });
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
            },

            onVideoThumbOut(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.video-thumb__box');
                var $image = $el.find('.video-thumb__img');

                gsap.killTweensOf([$color, $image]);

                if ($color.hasClass('orange')) {
                    gsap.to($color, 0.3, {x: 12, y: -12, force3D: true, ease: Power2.easeIn});
                    gsap.to($image, 0.3, {
                        x: -12, y: 12, force3D: true, ease: Power2.easeIn, onComplete: () => {
                            $color.removeClass('orange');
                        }
                    });
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
                } else {
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0});
                }

            },

            onTabClick(e) {
                var $curPanel = $(e.currentTarget).parent().stop(true).find('.category__panel');
                $('.category__panel').parent().removeClass('category__tab--open')
                var panelOpen = $curPanel.is(':visible');
                $('.category__panel').slideUp(300);
                if(!panelOpen) {
                    $curPanel.slideDown(300);
                    $curPanel.parent().addClass('category__tab--open')
                }
            },

            getGameList: function (category) {

                var games = ['ALL GAMES'];
                for (var i = 0; i < category.positions.length; i++) {
                    var curPosition = category.positions[i];
                    if (games.indexOf(curPosition.game) == -1) {
                        if (curPosition.game!==null) { //dont display null value in list - some games are null
                            games.push(curPosition.game);
                        }
                    }
                }
                return games;
            },

            setPositionsArrays() {
                for (var i = 0; i < this.data.categories.length; i++) {
                    var cat = this.data.categories[i];
                    this.positions[i] = cat.positions;
                }
                this.$forceUpdate();
            },

            getPositions(catIndex) {
                return this.positions[catIndex];
            },

            onGameChanged(gameName, index, meta) {
                var catIndex = meta.categoryIndex;
                var cat = this.data.categories[catIndex];
                if (gameName == 'ALL GAMES') {
                    this.positions[catIndex] = cat.positions;
                } else {
                    this.positions[catIndex] = cat.positions.filter(function (item) {
                        return item.game == gameName;
                    });
                }
                this.$forceUpdate();
            },

            onRouterLinkClick(e) {
                if ($(e.currentTarget).attr('target') !== '_blank') {
                    e.preventDefault();

                    let href = $(e.currentTarget).attr('href');
                    if(href.substr(-1) === '/') {
                        href = href.substr(0, href.length - 1);
                    }
                    if(href.substr(0,1) !== '/') {
                        href = '/' + href
                    }
                    router.push({path: href});
                }
            },



            //DUPLICATE, with the $REF
            showRow(row) {
                let ret = true
                if (this.$refs.cp) {
                    if (this.$refs.cp.selectionGroupModel.length) {
                        let index = this.$refs.cp.selectionGroupModel.indexOf(row.group)
                        if (index === -1) {
                            ret = false
                        }
                    }
                    if (this.$refs.cp.selectionTeamModel.length) {
                        let index = this.$refs.cp.selectionTeamModel.indexOf(row.team)
                        if (index === -1) {
                            ret = false
                        }
                    }
                    if (this.$refs.cp.selectionLocationModel.length) {
                        let index = this.$refs.cp.selectionLocationModel.indexOf(row.location)
                        if (index === -1) {
                            ret = false
                        }
                    }
                }
                return ret
            },
            blankFormatter (val) {
                return (val && val !== '(NONE)') ? val : ''
            },
            totalRowsShownUpdate() {
                this.resultsToggler = Math.random()
            }


        },
    }

</script>
