<template>
    <div class="footer-holder" :id="id">
        <div class="footer">
            <div class="footer__buttons">
                <ul class="footer__buttons__legal">
                    <li>
                        <a href="http://tos.ea.com/legalapp/WEBPRIVACY/US/en/PC/" target="_blank">
                            PRIVACY
                        </a>
                    </li>
                    <li>
                        <a href="https://tos.ea.com/legalapp/WEBTERMS/US/en/PC/" target="_blank">
                            TERMS
                        </a>
                    </li>
                    <li>
                        <a href="https://tos.ea.com/legalapp/WEBPRIVACYCA/US/en/PC/" target="_blank">
                            DO NOT SELL MY PERSONAL INFORMATION
                        </a>
                    </li>
                </ul>
                <ul class="footer__buttons__social">
                    <li>
                        <a href="https://www.instagram.com/respawn/" target="_blank">
                            <img src="../img/global/footer/icon-instagram.png" alt="Instagram">
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/respawn" target="_blank">
                            <img src="../img/global/footer/icon-twitter.png" alt="Twitter">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/RespawnEntertainment/" target="_blank">
                            <img src="../img/global/footer/icon-facebook.png" alt="Facebook">
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer__copyright">
                © {{ new Date().getFullYear() }} Respawn Entertainment Trademarks belong to their respective owners. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
            return {

            }
        },
        props: {
            id: {
                type: String,
                required: false,
                default: 'footer'
            },
        },
        methods: {

        }
    }
</script>
