<template>
    <div :class="['careers-pickers', extraClasses]">

        <div class="lockout--careers table-filter">
            <div class="lockout">
                <div class="find-title-landing">Find jobs by:</div>
                <div class="filters">
                    <div class="p1">
                        <div class="txt-filter">FILTER BY:</div>
                        <div class="sep sep-main">|</div>
                    </div>
                    <div class="p2">
                        <div class="picker picker-group">
                            <div class="picker-title">GROUP <span>></span></div>
                            <div class="dialogue">
                                <div class="inner">
                                    <div class="items">
                                        <div class="item" v-for="(checkbox, ii) in selectionGroup">
                                            <span :class="['checkbox', selectedClass('group', checkbox)]"
                                                  @click="checkBox('group', checkbox)"></span>
                                            <div class="line">
                                                <span class="txt">{{ blankFormatter(checkbox) }}</span> <span
                                                class="sep-count sep" v-show="!anyFilterSelected">|</span> <span
                                                class="sep-count count" v-show="!anyFilterSelected">{{ selectionGroupCounts[checkbox] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sep sep-main">|</div>
                        <div class="picker picker-team">
                            <div class="picker-title">TEAM <span>></span></div>
                            <div class="dialogue">
                                <div class="inner">
                                    <div class="items">
                                        <div class="item" v-for="(checkbox, ii) in selectionTeam">
                                            <span :class="['checkbox', selectedClass('team', checkbox)]"
                                                  @click="checkBox('team', checkbox)"></span>
                                            <div class="line">
                                                <span class="txt">{{ blankFormatter(checkbox) }}</span> <span
                                                class="sep" v-show="!anyFilterSelected">|</span> <span
                                                class="count" v-show="!anyFilterSelected">{{ selectionTeamCounts[checkbox] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sep sep-main">|</div>
                        <div class="picker picker-location">
                            <div class="picker-title">LOCATION <span>></span></div>
                            <div class="dialogue">
                                <div class="inner">
                                    <div class="items">
                                        <div class="item" v-for="(checkbox, ii) in selectionLocation">
                                            <span :class="['checkbox', selectedClass('location', checkbox)]"
                                                  @click="checkBox('location', checkbox)"></span>
                                            <div class="line">
                                                <span class="txt">{{ blankFormatter(checkbox) }}</span> <span
                                                class="sep" v-show="!anyFilterSelected">|</span> <span
                                                class="count" v-show="!anyFilterSelected">{{ selectionLocationCounts[checkbox] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-outer">
                            <router-link :to="findJobsUrl">
                                <div class="btn btn--huge">
                                    <div class="btn--huge__text">
                                        <div>FIND JOBS<span>FIND JOBS</span></div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="selected-filters">
                    <div v-if="selectionGroupModel.length>0" class="lbl">GROUP:</div>
                    <div class="item" v-for="(item, i) in selectionGroupModel">
                        <span class="close" @click="checkBox('group', item)"></span>
                        <span class="txt">{{ blankFormatter(item) }}</span>
                    </div>


                    <div v-if="selectionTeamModel.length>0" class="lbl">TEAM:</div>
                    <div class="item" v-for="(item, i) in selectionTeamModel">
                        <span class="close" @click="checkBox('team', item)"></span>
                        <span class="txt">{{ blankFormatter(item) }}</span>
                    </div>

                    <div v-if="selectionLocationModel.length>0" class="lbl">LOCATION:</div>
                    <div class="item" v-for="(item, i) in selectionLocationModel">
                        <span class="close" @click="checkBox('location', item)"></span>
                        <span class="txt">{{ blankFormatter(item) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="lockout--careers table-filter-mobile">
            <div class="lockout">

                <div class="find-title-landing">Find jobs by:</div>
                <div class="filters">
                    <div class="p1">
                        <div class="txt-filter">FILTER BY:</div>
                        <div class="sep sep-main">|</div>
                    </div>
                    <div class="p2">
                        <div class="picker picker-group" @click="showHideMobileDialogue('group', true)">
                            <div class="picker-title">GROUP <span>></span></div>
                        </div>
                        <div class="sep sep-main">|</div>
                        <div class="picker picker-team" @click="showHideMobileDialogue('team', true)">
                            <div class="picker-title">TEAM <span>></span></div>
                        </div>
                        <div class="sep sep-main">|</div>
                        <div class="picker picker-location" @click="showHideMobileDialogue('location', true)">
                            <div class="picker-title">LOCATION <span>></span></div>
                        </div>
                    </div>
                </div>
                <div class="filter-checkboxes">
                    <div class="dialogue group" v-show="mobileDialogueGroupOpen">
                        <div class="inner">
                            <div class="close" @click="showHideMobileDialogue('all')"></div>
                            <div class="items">
                                <div class="item" v-for="(checkbox, ii) in selectionGroup">
                                    <span :class="['checkbox', selectedClass('group', checkbox)]"
                                          @click="checkBox('group', checkbox)"></span>
                                    <div class="line">
                                        <span class="txt">{{ blankFormatter(checkbox) }}</span> <span
                                        class="sep" v-show="!anyFilterSelected">|</span> <span
                                        class="count" v-show="!anyFilterSelected">{{ selectionGroupCounts[checkbox] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dialogue team" v-show="mobileDialogueTeamOpen">
                        <div class="inner">
                            <div class="close" @click="showHideMobileDialogue('all')"></div>
                            <div class="items">
                                <div class="item" v-for="(checkbox, ii) in selectionTeam">
                                    <span :class="['checkbox', selectedClass('team', checkbox)]"
                                          @click="checkBox('team', checkbox)"></span>
                                    <div class="line">
                                        <span class="txt">{{ blankFormatter(checkbox) }}</span> <span
                                        class="sep" v-show="!anyFilterSelected">|</span> <span
                                        class="count" v-show="!anyFilterSelected">{{ selectionTeamCounts[checkbox] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dialogue location" v-show="mobileDialogueLocationOpen">
                        <div class="inner">
                            <div class="close" @click="showHideMobileDialogue('all')"></div>
                            <div class="items">
                                <div class="item" v-for="(checkbox, ii) in selectionLocation">
                                    <span :class="['checkbox', selectedClass('location', checkbox)]"
                                          @click="checkBox('location', checkbox)"></span>
                                    <div class="line">
                                        <span class="txt">{{ blankFormatter(checkbox) }}</span> <span
                                        class="sep" v-show="!anyFilterSelected">|</span> <span
                                        class="count" v-show="!anyFilterSelected">{{ selectionLocationCounts[checkbox] }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-outer-mobile">
                    <router-link :to="findJobsUrl">
                        <div class="btn btn--huge">
                            <div class="btn--huge__text">
                                <div>FIND JOBS<span>FIND JOBS</span></div>
                            </div>
                        </div>
                    </router-link>
                </div>

                <div class="selected-filters">
                    <div v-if="selectionGroupModel.length>0" class="lbl">GROUP:</div>
                    <div class="item" v-for="(item, i) in selectionGroupModel">
                        <span class="close" @click="checkBox('group', item)"></span>
                        <span class="txt">{{ blankFormatter(item) }}</span>
                    </div>


                    <div v-if="selectionTeamModel.length>0" class="lbl">TEAM:</div>
                    <div class="item" v-for="(item, i) in selectionTeamModel">
                        <span class="close" @click="checkBox('team', item)"></span>
                        <span class="txt">{{ blankFormatter(item) }}</span>
                    </div>

                    <div v-if="selectionLocationModel.length>0" class="lbl">LOCATION:</div>
                    <div class="item" v-for="(item, i) in selectionLocationModel">
                        <span class="close" @click="checkBox('location', item)"></span>
                        <span class="txt">{{ blankFormatter(item) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CareersPickers",
    data () {
        return {
            selectionGroup: [],
            selectionTeam: [],
            selectionLocation: [],
            selectionGroupCounts: [],
            selectionTeamCounts: [],
            selectionLocationCounts: [],
            selectionGroupModel: [],
            selectionTeamModel: [],
            selectionLocationModel: [],
            mobileDialogueGroupOpen: false,
            mobileDialogueTeamOpen: false,
            mobileDialogueLocationOpen: false,
            window: window,

            categoriesFlat: [],
            totalRowsShown: 0,
            anyFilterSelected: false,
        }
    },
    props: {
        extraClasses: String,
    },
    mounted () {
        this.loadJson()
    },
    computed: {
        findJobsUrl () {
            let ret = '/careers-listings'
            let oneAdded = false
            if (this.selectionLocationModel.length>0) {
                let imp = this.selectionLocationModel.join(',')
                ret += (oneAdded) ? '&' : '?'
                ret += 'location=' + imp
                oneAdded = true
            }
            if (this.selectionGroupModel.length>0) {
                let imp = this.selectionGroupModel.join(',')
                ret += (oneAdded) ? '&' : '?'
                ret += 'group=' + imp
                oneAdded = true
            }
            if (this.selectionTeamModel.length>0) {
                let imp = this.selectionTeamModel.join(',')
                ret += (oneAdded) ? '&' : '?'
                ret += 'team=' + imp
                oneAdded = true
            }
            return ret
        }
    },
    methods: {
        loadJson () {
            this.categoriesFlat = this.window.careers.calculated.categoriesFlat
            this.selectionGroup = this.window.careers.calculated.selectionGroup
            this.selectionTeam = this.window.careers.calculated.selectionTeam
            this.selectionLocation = this.window.careers.calculated.selectionLocation
            this.selectionGroupCounts = this.window.careers.calculated.selectionGroupCounts
            this.selectionTeamCounts = this.window.careers.calculated.selectionTeamCounts
            this.selectionLocationCounts = this.window.careers.calculated.selectionLocationCounts

            //set models base on url
            const urlParams = new URLSearchParams(this.window.location.search)
            let urlLocation = (urlParams.get("location")) ? urlParams.get("location").toUpperCase() : null
            if (urlLocation !== null) {
                let urlLocations = urlLocation.split(',')
                for (let i = 0; i < urlLocations.length; i++) {
                    let obj = urlLocations[i]
                    if (this.selectionLocation.indexOf(obj) > -1) {
                        this.selectionLocationModel.push(obj)
                    }
                }
            }


            let urlGroup = (urlParams.get("group")) ? urlParams.get("group").toUpperCase() : null
            if (urlGroup !== null) {
                let urlGroups = urlGroup.split(',')
                for (let i = 0; i < urlGroups.length; i++) {
                    let obj = urlGroups[i]
                    if (this.selectionGroup.indexOf(obj) > -1) {
                        this.selectionGroupModel.push(obj)
                    }
                }
            }

            let urlTeam = (urlParams.get("team")) ? urlParams.get("team").toUpperCase() : null
            if (urlTeam !== null) {
                let urlTeams = urlTeam.split(',')
                for (let i = 0; i < urlTeams.length; i++) {
                    let obj = urlTeams[i]
                    if (this.selectionTeam.indexOf(obj) > -1) {
                        this.selectionTeamModel.push(obj)
                    }
                }
            }

            //done now recalc
            this.calcTotalRowsShown()
        },

        checkBox (model, val) {
            if (model === 'group') {
                let index = this.selectionGroupModel.indexOf(val)
                if (index > -1) {
                    this.selectionGroupModel.splice(index, 1)
                } else {
                    this.selectionGroupModel.push(val)
                }
            } else if (model === 'team') {
                let index = this.selectionTeamModel.indexOf(val)
                if (index > -1) {
                    this.selectionTeamModel.splice(index, 1)
                } else {
                    this.selectionTeamModel.push(val)
                }
            } else if (model === 'location') {
                let index = this.selectionLocationModel.indexOf(val)
                if (index > -1) {
                    this.selectionLocationModel.splice(index, 1)
                } else {
                    this.selectionLocationModel.push(val)
                }
            }
            //done now recalc
            this.calcTotalRowsShown()

        },
        selectedClass (model, val) {
            let index = -1
            if (model === 'group') {
                index = this.selectionGroupModel.indexOf(val)
            } else if (model === 'team') {
                index = this.selectionTeamModel.indexOf(val)
            } else if (model === 'location') {
                index = this.selectionLocationModel.indexOf(val)
            }
            if (index > -1) {
                return 'selected'
            } else {
                return ''
            }
        },
        blankFormatter (val) {
            return (val && val !== '(NONE)') ? val : '(NONE)'
        },

        showHideMobileDialogue (section, show = false) {
            this.mobileDialogueGroupOpen = false
            this.mobileDialogueTeamOpen = false
            this.mobileDialogueLocationOpen = false
            switch (section) {
                case 'group':
                    this.mobileDialogueGroupOpen = show
                    break;
                case 'team':
                    this.mobileDialogueTeamOpen = show
                    break;
                case 'location':
                    this.mobileDialogueLocationOpen = show
                    break;
            }

        },
        calcTotalRowsShown() {
            this.totalRowsShown = 0
            this.anyFilterSelected = false
            for (let i = 0;i < this.categoriesFlat.length;i++){
                let obj = this.categoriesFlat[i]
                if (this.showRow(obj)) {
                    this.totalRowsShown++
                } else {
                    this.anyFilterSelected = true
                }
            }
            this.$emit('totalRowsShown', this.totalRowsShown);
        },
        //DUPLICATE, minus the $REF
        showRow(row) {
            let ret = true
            if (this.selectionGroupModel.length) {
                let index = this.selectionGroupModel.indexOf(row.group)
                if (index === -1) {
                    ret = false
                }
            }
            if (this.selectionTeamModel.length) {
                let index = this.selectionTeamModel.indexOf(row.team)
                if (index === -1) {
                    ret = false
                }
            }
            if (this.selectionLocationModel.length) {
                let index = this.selectionLocationModel.indexOf(row.location)
                if (index === -1) {
                    ret = false
                }
            }
            return ret
        },
    }
}
</script>
