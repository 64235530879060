import router from '../router'
const siteHelpers = {
    getGlobalMeta: function(metaTitle, metaTitleNested = null) {
        const shareURLBase = window.config.shareURLBase
        let metaUrl = shareURLBase + router.currentRoute?._value?.path
        let metaTitleFull = metaTitle + ' | ' + window.config.siteName
        if (metaTitleNested) {
            metaTitleFull = metaTitleNested + ' | ' + window.config.siteName
        } else if (metaTitle === 'Home') {
            metaTitleFull = window.config.siteName
        }
        let ret = {
            title: metaTitle,
            titleTemplate: metaTitleFull,
            meta: [
                {name: 'description', content: window.config.siteDescription},
                {property: 'og:site_name', content: window.config.siteName},
                {property: 'og:image', content: window.config.shareImage},
                {property: 'og:type', content: 'website'},
                {property: 'og:url', content: metaUrl},
                {property: 'og:title', content: metaTitleFull},
                {property: 'og:description', content: window.config.siteDescription},
                {property: 'twitter:card', content: 'summary_large_image'},
                {property: 'twitter:title', content: metaTitleFull},
                {property: 'twitter:description', content: window.config.siteDescription},
                {property: 'twitter:image', content: window.config.shareImage},
                {property: 'twitter:url', content: metaUrl},
            ]
        }

        //AND SET IT
        document.title = metaTitleFull;
        ret?.meta.forEach(tag => {
            if (tag.name) {
                // Update or create a meta tag with a 'name' attribute
                let element = document.querySelector(`meta[name="${tag.name}"]`);
                if (!element) {
                    element = document.createElement('meta');
                    element.setAttribute('name', tag.name);
                    document.head.appendChild(element);
                }
                element.setAttribute('content', tag.content);
            } else if (tag.property) {
                // Update or create a meta tag with a 'property' attribute
                let element = document.querySelector(`meta[property="${tag.property}"]`);
                if (!element) {
                    element = document.createElement('meta');
                    element.setAttribute('property', tag.property);
                    document.head.appendChild(element);
                }
                element.setAttribute('content', tag.content);
            }
        });

        return ret
    },

}
export default siteHelpers
