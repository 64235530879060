<template>
    <div v-touch:swipe="onSwipe">
        <div id="home">
            <div id="title-photo" ref="titlePhoto">
                <div class="title">
                    <img src="../img/global/cta-scroll.png" class="scroll-cta">
                    <div class="random-numbers" ref="randomNumbers">//04122010</div>
                    <div class="home-nav-bg">
                        <img src="../img/home/nav-line.png" class="nav-line">
                    </div>
                    <div class="title__text">
                        <div style="position: absolute;" class="title__text-copy">
                            <ul>
                                <li class="title__text__item" v-for="story in stories">
                                    <h5>{{ story.subtitle }}</h5>
                                    <h2>
                                        <div class="line-wrap" v-if="story.title.line1">
                                            <div class="split-line">{{ story.title.line1 }}</div>
                                        </div>
                                        <div class="line-wrap" v-if="story.title.line2">
                                            <div class="split-line">{{ story.title.line2 }}</div>
                                        </div>
                                        <div class="line-wrap" v-if="story.title.line3">
                                            <div class="split-line">{{ story.title.line3 }}</div>
                                        </div>
                                        <div class="line-wrap" v-if="story.title.line4">
                                            <div class="split-line">{{ story.title.line4 }}</div>
                                        </div>
                                    </h2>
                                </li>
                            </ul>
                        </div>
                        <div class="live-nav">
                            <div class="live-nav__marker">
                                <img src="../img/home/nav-dots.png">
                            </div>
                            <ul>
                                <li v-for="story in stories"></li>
                            </ul>
                        </div>
                        <img src="../img/home/dot-cover.png" class="dot-cover">
                    </div>

                </div>
                <div class="numbers">
                    <ul class="numbers__large">
                        <li class="active" v-for="(story, i) in stories">{{
                                (stories.length < 10 ? '0' : '') + (i + 1)
                            }}
                        </li>
                    </ul>
                    <div class="numbers__total"></div>
                </div>
                <div class="photo">
                    <ul class="photo-image-list">
                        <li class="image" v-for="story in stories">
                            <!--
                            <div class="image__inner" :style="{ 'background-image' : 'url(' + story.photo + ')' }">
                                <img src="../img/global/textures/bg-specks-white-cover1.png"
                                     class="white-specks--top-left">
                                <img src="../img/global/textures/bg-specks-white-cover1.png"
                                     class="white-specks--bottom-right">

                                <div class="image__inner__hover"
                                     :style="{ 'background-image' : 'url(' + story.photo + ')' }"
                                     :class="{hover:photoHovered}">
                                </div>
                            </div>
                            -->
                            <img src="../img/global/textures/bg-specks-white-cover1.png"
                                 class="white-specks--top-left">
                            <img src="../img/global/textures/bg-specks-white-cover1.png"
                                 class="white-specks--bottom-right">
                            <div class="photos simple_carousel">
                                <div v-for="slidePhoto in story.photos.slice(0, 5)" class="final-slide">
                                    <div class="image__inner"
                                         :style="{ 'background-image' : 'url(' + slidePhoto + ')' }">
                                        <div class="image__inner__hover"
                                             :style="{ 'background-image' : 'url(' + slidePhoto + ')' }"
                                             :class="{hover:photoHovered}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="orange-box"></div>
                        </li>
                    </ul>
                    <ul class="photo-buttons">
                        <li v-for="story in stories">
                            <router-link :to="story.button.link" :target="story.button.target"
                                         v-if="story.button.link.indexOf('://') == -1">
                                <div class="btn btn--huge" @mouseenter="photoHovered = true"
                                     @mouseleave="photoHovered = false">
                                    <div class="btn--huge__text">
                                        <div>{{ story.button.title }}<span>{{ story.button.title }}</span></div>
                                    </div>
                                </div>
                            </router-link>

                            <a :href="story.button.link" :target="story.button.target"
                               v-if="story.button.link.indexOf('://') > -1">
                                <div class="btn btn--huge" @mouseenter="photoHovered = true"
                                     @mouseleave="photoHovered = false">
                                    <div class="btn--huge__text">
                                        <div>{{ story.button.title }}<span>{{ story.button.title }}</span></div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <footer-bar :class="{'footer--active': footerShowing}"></footer-bar>
        </div>

    </div>

</template>

<script>

import {nextTick} from 'vue';
import $ from 'jquery'
import axios from 'axios'
import Footer from '@/components/Footer'
import SlickSimpleCarousel from '../lib/SlickSimpleCarousel'
import siteHelpers from '@/helpers/siteHelpers'
import {gsap, Power2, Power3} from "gsap";

export default {
    name: 'home',
    metaInfo() {
        return siteHelpers.getGlobalMeta('Home')
    },
    components: {
        'footer-bar': Footer
    },
    data() {
        return {
            stories: null,
            index: 0,
            prevIndex: -1,
            totalStories: Number,
            lastScrollTime: Number,
            photoHovered: false,
            lethargy: null,
            footerShowing: false
        }
    },
    mounted() {
        this.loadJson();
    },
    methods: {

        loadJson() {
            var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
            axios.get(window.baseUrl + '/home.json' + urlparams)
                .then((response) => {
                    this.stories = response.data.stories;
                    nextTick(() => {
                        this.onJsonLoaded();
                    });
                    // console.log(this.stories);
                })
                .catch((error) => {
                    console.error(error);
                })
        },

        onJsonLoaded() {

            this.addEventListeners();
            this.sizeWrapperHeight();

            // used to detect trackpad scrolling (for rubber banding)
            //this.lethargy = new Lethargy();

            this.lastScrollTime = new Date().getTime();
            this.totalStories = this.stories.length;

            $('.numbers__total').html('/' + (this.totalStories < 10 ? '0' : '') + this.totalStories);

            // make sure all split line titles have some content so they don't collapse
            $('.split-line').each((i, el) => {
                var $item = $(el);
                if ($item.html() == '') {
                    $item.html('&nbsp;');
                }
            });

            $('.title__text__item').hide().first().show().css({opacity: 0});
            $('.photo .image').hide();
            gsap.set($('.numbers__large'), {y: window.innerHeight, force3D: true});
            $('.photo-buttons li').find('.btn').hide();
            gsap.from('.numbers__total', 1, {
                y: window.innerHeight,
                force3D: true,
                ease: Power2.easeInOut,
                delay: 0.95
            });

            // tells section loader to wipe up
            setTimeout(() => {
                this.$store.commit('endSectionPreload');
            }, 550);

            setTimeout(() => {
                SlickSimpleCarousel.init();

                setTimeout(() => {
                    this.animateTitleIn();
                }, 760);
                this.animatePhotoIn();
                this.animateCounter();
                this.animateRandomNumber();


            }, 550);

        },

        addEventListeners() {
            document.body.addEventListener("wheel", this.onScroll);
            window.addEventListener("resize", this.onResize);
            $('.live-nav').find('li').on('click', this.onLiveNavLiClick);
        },

        sizeWrapperHeight() {
            // manually set height because of iOS 100vh is more than fullscreen
            this.$refs.titlePhoto.style.height = ($(window).outerHeight() - 0) + 'px';
        },

        onScroll(e) {
            //e.preventDefault();

            // prevents changing stories too fast
            if (this.scrollThrottled() && !this.footerShowing) return;

            // show/hide footer at end
            if (e.deltaY < 0 && this.index == this.totalStories - 1 && this.footerShowing) {
                // if on last story and scrolling up
                this.footerShowing = false;
                return;
            } else if (this.index == this.totalStories - 1 && e.deltaY > 0) {
                // if on last story and scrolling down
                this.footerShowing = true;
            }

            // rubber band at top
            if (e.deltaY < 0 && this.index == 0) {
                // helps with rubber banding when scrolling with trackpad
                //if (this.lethargy.check(e) === false) return;
                this.rubberBand('top');
                return;
            }

            // rubber band at bottom
            if (e.deltaY > 0 && this.index == this.totalStories - 1) {
                // helps with rubber banding when scrolling with trackpad
                //if (this.lethargy.check(e) === false) return;
                this.rubberBand('bottom');
                return;
            }

            this.prevIndex = this.index;
            this.index = e.deltaY < 0 ? this.index -= 1 : this.index += 1;

            this.changeStory();

        },

        onSwipe(e) {
            if (e === 'top') {
                this.onSwipeUp()
            } else if (e === 'bottom') {
                this.onSwipeDown()
            }
        },

        onSwipeUp() {
            if (this.index == this.totalStories - 1) {
                this.footerShowing = true;
                return;
            }

            // prevents changing stories too fast
            if (this.scrollThrottled()) return;

            this.prevIndex = this.index;
            this.index++;
            if (this.index > this.totalStories - 1) this.index = this.totalStories - 1;
            this.changeStory();
        },

        onSwipeDown() {
            if (this.index == 0) return;

            if (this.footerShowing) {
                this.footerShowing = false;
                return;
            }

            // prevents changing stories too fast
            if (this.scrollThrottled()) return;

            this.prevIndex = this.index;
            this.index--;
            if (this.index < 0) this.index = 0;
            this.changeStory();
        },

        rubberBand(direction) {
            var $curTitle = $('.title__text__item:visible');
            gsap.killTweensOf($curTitle);
            gsap.to($curTitle, 0.3, {y: direction == 'top' ? 20 : -20, force3D: true, ease: Power2.easeOut});
            gsap.to($curTitle, 0.3, {y: 0, force3D: true, ease: Power2.easeInOut, delay: 0.08});
        },

        onResize(e) {
            this.animateCounter();
            this.sizeWrapperHeight();
        },

        scrollThrottled() {
            let curTime = new Date().getTime();
            if (curTime - this.lastScrollTime < 1600) {
                return true;
            }
            this.lastScrollTime = curTime;
            return false;
        },

        changeStory() {
            this.animateTitleOut();
            this.animateCounter();
            this.animateLiveNav();
            this.animatePhotoOut();
            this.animatePhotoIn();
            this.animateRandomNumber();
        },

        animateTitleOut() {
            var $title = $($('.title__text__item').get(this.prevIndex));
            gsap.fromTo([$title.find('h2'), $title.find('h5')], 0.7, {y: 0, opacity: 1}, {
                y: this.prevIndex > this.index ? 175 : -175,
                opacity: 0,
                ease: Power2.easeInOut,
                delay: this.prevIndex > this.index ? 0 : 0.03,
                onComplete: () => {
                    $title.hide();
                    this.animateTitleIn();
                }
            });
        },

        animateTitleIn() {

            var $titles = $('.title__text__item');
            var $curTitle = $($titles.get(this.index));
            var down = this.index > this.prevIndex;

            gsap.killTweensOf($titles);
            $titles.hide();
            $curTitle.show();

            console.log('$curTitle', $titles, $curTitle)

            var $h5 = $curTitle.find('h5');
            var $h2 = $curTitle.find('h2');
            gsap.set([$h5, $h2], {y: 0, opacity: 1, force3D: true});

            var docwidth = document.body.clientWidth;
            var letterSpacing = (docwidth > 0 && docwidth < 400) ? '0.15rem' : '0.35rem';
            gsap.fromTo($h5, 0.95,
            {
                opacity: 0,
                letterSpacing: '1rem'
            }, {
                opacity: 1,
                letterSpacing: letterSpacing,
                ease: Power2.easeInOut
            });
            gsap.set($curTitle, {opacity: 1});
            gsap.from($curTitle.find('.split-line'), {
                duration: 0.9,
                yPercent: down ? 90 : -95,
                force3D: true,
                ease: "quint.inOut",
                delay: 0,
                stagger: 0.06
            });

        },

        animateCounter() {
            var $numbers = $('.numbers__large');
            var $numbersLis = $numbers.find('li');
            $numbersLis.removeClass('active');
            $($numbersLis.get(this.index)).addClass('active');

            var $firstNumberLi = $('.numbers').find('li').first();
            var h = $firstNumberLi.height() + parseInt($firstNumberLi.css('marginBottom'));

            gsap.to($numbers, 1.15, {
                y: -this.index * h,
                force3D: true,
                ease: Power3.easeInOut,
                delay: 0
            });
        },

        animateLiveNav() {
            var $liveNav = $('.live-nav');
            var $liveNavUl = $liveNav.find('ul');
            gsap.to($liveNavUl, 0.55, {
                y: this.index * -48,
                force3D: true,
                ease: Power2.easeOut
            });
        },

        onLiveNavLiClick(e) {
            this.prevIndex = this.index;
            this.index = $(e.currentTarget).index();
            this.changeStory();
        },

        animateRandomNumber() {
            // having issues loading the library
            // gsap.to(this.$refs.randomNumbers, 1, {
            //     scrambleText: {
            //         text: "123456789",
            //         chars: "XO",
            //         revealDelay: 0.5,
            //         speed: 0.3
            //     }
            // });
            gsap.to(this.$refs.randomNumbers, 1, {
                onUpdate: () => {
                    var rand = parseInt(Math.random() * 1000000000, 10);
                    $(this.$refs.randomNumbers).html('// ' + rand);
                },
                ease: Power2.easeInOut
            });
        },

        animatePhotoIn() {


            var $newImage = $($('.photo .image').get(this.index));
            var $btn = $($('.photo-buttons li').get(this.index)).find('.btn');
            var $imageInner = $newImage.find('.image__inner');
            var $leftSpecks = $newImage.find('.white-specks--top-left');
            var $orangeBox = $newImage.find('.orange-box');
            var orangeBoxHeight = $orangeBox.height();


            $newImage.show();
            $btn.show();

            var down = this.index >= this.prevIndex;

            gsap.set($newImage, {height: '100%'});
            gsap.set([$leftSpecks], {xPercent: 0, yPercent: 0, force3D: true, clearProps: 'all'});
            gsap.set($orangeBox, {height: orangeBoxHeight * (down ? 4 : 1), xPercent: 0});
            gsap.set($btn, {x: 0, force3D: true, opacity: 1});

            var tl = gsap.timeline();
            tl.fromTo($orangeBox, 1.4, {y: down ? window.innerHeight : -window.innerHeight}, {
                y: 0,
                force3D: true,
                ease: Power3.easeInOut
            });
            tl.to($orangeBox, 1.4, {height: orangeBoxHeight, ease: Power3.easeInOut, clearProps: 'height'}, 0.32);
            tl.fromTo($imageInner, 1.4, {y: down ? window.innerHeight : -window.innerHeight}, {
                y: 0,
                force3D: true,
                ease: Power3.easeInOut
            }, 0.3);
            tl.fromTo($btn, 1.4, {y: down ? window.innerHeight * 1.23 : -window.innerHeight * 2}, {
                y: 0,
                force3D: true,
                ease: Power3.easeInOut,
                onComplete: this.animatePhotoIn2
            }, 0.38);

            //console.log('animated photo in',SlickSimpleCarousel.simpleCarouselSlides[this.index]);
            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('slickGoTo', 0, true);
            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('refresh');
            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('slickPause');
            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('slickGoTo', 0, true);

            //adding in arrows and dots
            var $simpleCarouselArrow = $newImage.find('.simple_carousel .slick-arrow');
            var $simpleCarouselDots = $newImage.find('.simple_carousel .slick-dots li');
            var tl2 = gsap.timeline();
            tl2.fromTo($simpleCarouselArrow, 0.5, {
                opacity: 0,
            }, {
                opacity: 1,
            }, 1.5);
            tl2.fromTo($simpleCarouselDots,
                {opacity: 0, left: 10},
                {
                    duration: 0.3,
                    opacity: 1,
                    left: 0,
                    stagger: {
                        each: 0.05,
                        start: 1.6
                    }
                }
            );

            gsap.set($('.simple_carousel .slick-dots'), {opacity: 1});
        },
        animatePhotoIn2() {
            var $newImage = $($('.photo .image').get(this.index));
            var $imageInner = $newImage.find('.image__inner');
            $imageInner.css('transform', 'none');

            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('slickGoTo', 0, true);
            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('slickPlay');
        },

        animatePhotoOut() {
            SlickSimpleCarousel.simpleCarouselSlides[this.index].slick('slickPause');
            SlickSimpleCarousel.simpleCarouselSlides[this.prevIndex].slick('slickPause');

            //$('.photo .image .image__inner').removeClass('notransform');

            var $prevImage = $($('.photo .image').get(this.prevIndex));
            var $newImage = $($('.photo .image').get(this.index));
            var $orangeBox = $prevImage.find('.orange-box');
            var $leftSpecks = $prevImage.find('.white-specks--top-left');
            var $btn = $($('.photo-buttons li').get(this.prevIndex)).find('.btn');
            var down = this.index >= this.prevIndex;

            $prevImage.find('.image__inner').removeClass('notransform');
            $newImage.find('.image__inner').removeClass('notransform');

            if (down) {
                gsap.set($prevImage, {top: 0, bottom: 'auto'});
            } else {
                gsap.set($prevImage, {top: 'auto', bottom: 0});
            }

            gsap.fromTo($prevImage, 0.9, {height: '100%'}, {
                height: '0%', ease: Power3.easeInOut,
                onComplete: () => {
                    $('.photo .image').hide();
                    $newImage.show();
                }
            });

            var $simpleCarouselArrow = $prevImage.find('.simple_carousel .slick-arrow');
            var $simpleCarouselDots = $prevImage.find('.simple_carousel .slick-dots');
            gsap.fromTo($simpleCarouselArrow, 0.3, {
                opacity: 1
            }, {
                opacity: 0,
                delay: 0
            });
            gsap.fromTo($simpleCarouselDots, 0.3, {
                opacity: 1,
            }, {
                opacity: 0,
                delay: 0
            });

            var photoHeight = $('.photo').height();
            gsap.fromTo([$orangeBox, $leftSpecks], down ? 0.65 : 0.5, {xPercent: 0, y: 0}, {
                xPercent: 380, y: down ? 0 : -photoHeight / 1.31, force3D: true, ease: Power3.easeIn
            });
            gsap.fromTo($btn, 0.5, {y: 0, opacity: 1}, {
                opacity: 0, x: 500, force3D: true, ease: Power3.easeIn,
                onComplete: () => {
                    $btn.hide();
                }
            });

        },

    },
    destroyed() {
        document.body.removeEventListener("wheel", this.onScroll);
        window.removeEventListener("resize", this.onResize);
        $('.live-nav').find('li').off('click', this.onLiveNavLiClick);
    }
}

</script>



