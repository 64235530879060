<template>
    <div class="dropdown"  :class="{open: isOpen}" @click.stop>
        <h2 @click="isOpen = !isOpen">{{ curItemLabel }}</h2>
        <div class="dropdown__menu" :class="{open: isOpen}">
            <ul>
                <li v-for="(item, i) in filteredItems" @click="onItemSelect(item)" >
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    import $ from 'jquery'
    import Vue from 'vue'


    export default {
        name: 'Dropdown',
        data() {
            return {
                isOpen: false,
                curLabel: '',
                curIndex: 0
            }
        },
        props: {
            items: Array,
            meta: Object
        },
        mounted() {
            $('body').on('click', this.onBodyClick);
        },
        methods: {
            onItemSelect(item) {
                var index = this.getIndexByLabel(item);
                this.isOpen = false;
                this.curIndex = index;
                this.$emit('changed', item, index, this.meta);
            },
            onBodyClick(e) {
                this.isOpen = false;
            },
            getIndexByLabel(label) {
                for (var i = 0; i < this.items.length; i++) {
                    var curItem = this.items[i];
                    if (curItem == label) {
                        return i;
                        break;
                    }
                }
            }
        },
        computed : {
            curItemLabel: function() {
                return this.items[this.curIndex];
            },
            filteredItems: function () {
                var filtered = [];
                for (var i = 0; i < this.items.length; i++) {
                    if(i == this.curIndex) continue;
                    filtered.push(this.items[i])
                }
                return filtered;
            }
        },
        destroyed() {
            $('body').off('click', this.onBodyClick);
        }
    }
</script>
