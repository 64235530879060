<template>
    <div id="not-found" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">Error!</h1>
        <div class="lockout">
            <p>
                Sorry,  but the page you requested was not found.
            </p>
            <router-link to="/">
                <div class="btn btn--simple">
                    Go Home
                </div>
            </router-link>
        </div>
        <footer-bar></footer-bar>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    import Footer from '@/components/Footer'
    import SectionTitle from '../lib/SectionTitle'

    export default {
        name: 'not-found',
        components: {
            'footer-bar': Footer
        },
        metaInfo() {
            return siteHelpers.getGlobalMeta('Not Found')
        },
        mounted() {
            // tells section loader to wipe up
            setTimeout(() => {
                this.$store.commit('endSectionPreload');
            }, 550);
            SectionTitle.animateIn(this.$refs.sectionTitle);
        }
    }

</script>
