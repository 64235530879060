<template>
    <div id="news" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">News</h1>
        <div id="news-content">
            <router-link v-if="newsfeatured"
                    :to="'/news/' + newsfeatured.id">
                <div id="news-content-featured"  ><div id="news-content-featured-inner" class="">
                    <div class="l1"><div class="l1-inner">
                        <div id="news-content-featured-title">
                            <span>FEATURED</span>
                        </div>
                        <div id="news-content-featured-img"><div id="news-content-featured-img-inner">
                            <div id="news-content-featured-img-bg" v-bind:style="{ 'background-image' : 'url(' + newsfeatured.posterImage + ')' }">
                            </div>
                            <div id="news-content-featured-img-datebox" class="datebox"><div class="div-table"><div class="div-tablecell">
                                <div class="month">{{ newsfeatured.month }}</div>
                                <div class="day">{{ newsfeatured.day }}</div>
                                <div class="year">{{ newsfeatured.year }}</div>
                            </div></div></div>
                        </div></div>
                    </div></div>
                    <div class="l2"><div class="l2-inner">
                        <div id="news-content-featured-content"><div id="news-content-featured-content2"><div id="news-content-featured-content-inner">
                            <h3>{{ newsfeatured.author }}</h3>
                            <h2>{{ newsfeatured.title }}</h2>
                            <div class="description">{{ newsfeatured.description }}</div>
                        </div></div></div>
                    </div></div>
                </div></div>
            </router-link>
            <div id="news-content-below">
                <div id="news-content-filter"><div class="content-inner" id="news-content-filter-inner"  >
                    <div id="news-content-filter-box-holder">
                        <div id="news-content-filter-box">
                            <div class="header">
                                <div class="header-icon">
                                    <img class="off" src="../img/global/icon-news-filter2.png">
                                    <img class="on" src="../img/global/icon-news-filter.png">
                                </div>
                                <div class="header-txt"><div class="div-table"><div class="div-tablecell">
                                    Latest News
                                </div></div></div>
                            </div>
                            <div class="header-dropdown">
                                <div v-for="(catitem, index) in newscategories" :cat="catitem.id" v-on:click="setCategoryClick" class="header-dropdown-item">{{catitem.display}}</div>
                            </div>
                        </div>
                    </div>
                </div></div>
                <div id="news-content-grid"><div class="content-inner" id="news-content-grid-inner"  >
                    <article v-for="(newsitem, index) in newsgrid" class="news-item" :cat="newsitem.category"  :id="'news-item-' + index" ><div class="news-item-inner clearfix">
                        <router-link
                                :to="'/news/' + newsitem.id"
                                v-on:mouseenter.native="onThumbOver"
                                v-on:mouseleave.native="onThumbOut"

                        >
                        <div class="news-item-img">
                            <div class="box-bg-square">
                                <img class="specks" src="../img/global/textures/bg-specks-white-cover1.png">
                                <div class="txt">READ ></div>
                            </div>
                            <div class="bg-with-date">
                                <div class="bg">
                                    <img class="mainbgimg" :src="newsitem.posterImage"/>
                                </div>
                                <div class="datebox"><div class="div-table"><div class="div-tablecell">
                                    <div class="month">{{ newsitem.month }}</div>
                                    <div class="day">{{ newsitem.day }}</div>
                                    <div class="year">{{ newsitem.year }}</div>
                                </div></div></div>
                            </div>
                        </div>
                        <div class="news-item-content">
                            <h3 v-if="newsitem.author !== undefined && newsitem.author !== ''">By {{ newsitem.author }}</h3>
                            <h2>{{ newsitem.title }}</h2>
                            <div class="description">{{ newsitem.description }}</div>
                        </div>
                        </router-link>
                    </div></article>
                </div></div>
                <div id="news-content-loadmore">
                    <div id="news-content-loadmore-button" v-on:click="setCategoryButtonClick" >
                        LOAD MORE POSTS
                    </div>
                </div>
            </div>
        </div> <!--news-content-->

        <footer-bar></footer-bar>
    </div>

</template>

<script>

    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import $ from 'jquery'
    import axios from 'axios'
    import SectionTitle from '../lib/SectionTitle'
    import inobounce from 'inobounce'
    import siteHelpers from "@/helpers/siteHelpers";
    import { gsap, Power2 } from "gsap";

    export default {
        name: 'news',
        components: {
            'footer-bar': Footer,
        },
        metaInfo() {
            return siteHelpers.getGlobalMeta('News')
        },
        data() {
            return {
                data: {},
                news: null,
                newscategories: null,
                newsgrid: null,
                newsfeatured:null,
                enterTimeline: null,
                leaveTimeline: null,

                currentCategory:'',
                currentCategoryResult:null,
                currentCategoryPage:1,
            }
        },
        mounted() {
            inobounce.disable();

            window.addEventListener("resize", this.onResize);

            this.loadJson();
        },
        methods: {

            loadJson() {
                var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
                axios.get(window.baseUrl + '/news.json'+urlparams)
                    .then((response) => {
                        this.data = response.data;
                        this.newscategories = response.data.news.newscategories;
                        this.news = response.data.news;
                        this.newsgrid = response.data.news.newsgrid;
                        this.newsfeatured = response.data.news.featured;
                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                        //console.log(response.data);
                        //console.log(response.data.newscategories);
                        //console.log(this.newscategories);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

            onJsonLoaded() {
                //cat
                //$('.header-dropdown-item').on('click', this.setCategoryClick);

                $('#news').css('opacity',0);

                setTimeout(() => {
                    setTimeout(() => {
                        this.animateIn();
                    }, this.$store.state.firstSection ? 550 : 700);
                    this.$store.commit('endSectionPreload');
                }, this.$store.state.firstSection ? 0 : 550);
                SectionTitle.animateIn(this.$refs.sectionTitle);

            },



            animateIn() {
                this.$helpers.globalPostLoad();

                this.resetForInitialLoad();
                this.setCategory('all');

                this.revealFeaturedNewsBox();
                this.onScroll(null);
                window.addEventListener('scroll', this.onScroll);
            },


            setCategoryClick(event) {
                var cat = $(event.target).attr('cat');
                this.setCategory(cat);
            },
            setCategory(cat) {
                //set cat
                if (cat !== this.currentCategory) {
                    //change nav
                    $('#news .header-dropdown-item').removeClass('active');
                    $('#news .header-dropdown-item[cat="' + cat + '"]').addClass('active');


                    //search for dataset
                    var res;
                    if (cat === "all") {
                        res = $('#news-content-grid-inner .news-item');
                    } else {
                        res = $('#news-content-grid-inner .news-item[cat="' + cat + '"]');
                    }
                    this.resetNewsItems();

                    //set new variables
                    this.currentCategory = cat;
                    this.currentCategoryResult=res;
                    this.currentCategoryPage = 1;

                    //show
                    this.setCategoryButton();
                    this.onScroll(null);
                }

            },

            setCategoryButtonClick() {
                this.currentCategoryPage++;
                this.setCategoryButton();
            },

            setCategoryButton() {
                var resultsPerPage = 6;
                var numToShow = this.currentCategoryPage * resultsPerPage;
                var ctr = 0;
                $(this.currentCategoryResult).each(function( index ) {
                    if (ctr<numToShow) {
                        $(this).show();
                    }
                    ctr++;
                });

                var resultsInSet = $(this.currentCategoryResult).length;
                var totalShown = this.currentCategoryPage * resultsPerPage;
                if (totalShown>=resultsInSet) {
                    $('#news-content-loadmore').hide();
                } else {
                    $('#news-content-loadmore').show();
                }

                this.onScroll(null);
            },


            onResize(e) {

            },

            onScroll(e) {
                var t = this;
                //loop through news items
                $('#news-content-grid-inner .news-item').each(function( index ) {
                    if (t.isScrolledIntoView(this, '')) {
                        t.revealNewsItem(this);
                    }
                });
                var item = $('#news-content-filter');
                if (t.isScrolledIntoView(item, '')) {
                    item.css('opacity',1);
                }
                var item = $('#news-content-loadmore');
                if (t.isScrolledIntoView(item, '')) {
                    item.css('opacity',1);
                }

            },

            resetForInitialLoad() {
                //hide featured box
                $('#news-content-featured-title').css('width',0);
                $('#news-content-featured-title span').css('opacity',0);
                $('#news-content-featured-content-inner').css('opacity',0);


                $('#news-content-featured-img').hide();
                $('#news-content-featured-content').css('visibility','hidden');

                this.currentCategory = '';
                this.currentCategoryResult = null;
                this.currentCategoryPage = 1;

                //hide news items
                this.resetNewsItems();

            },

            resetNewsItems() {
                $('#news-content-grid-inner .news-item').css('opacity',0);
                $('#news-content-grid-inner .news-item').attr('is-revealed', 'z');
                $('#news-content-grid-inner .news-item').hide();
            },

            revealFeaturedNewsBox() {
                var tw = $(window).width()/2;

                //animate in
                var del = 0;
                var tme = 0.5;
                var del_stagger = 0.1;
                gsap.fromTo($('#news-content-featured-title'), tme,
                    {
                        width: '0%'
                    },
                    {
                        width: tw,
                        ease: Power2.easeOut,
                        delay: del,
                        onComplete: this.revealFeaturedNewsBox2
                    }
                );

                $('#news').css('opacity',1);
            },
            revealFeaturedNewsBox2() {
                var del = 0;
                var tme = 0.75;
                var del_stagger = 0.1;
                gsap.fromTo($('#news-content-featured-title'), tme,
                    {

                    },
                    {
                        width: 100,
                        ease: Power2.easeInOut,
                        delay: del,
                        onStart:this.revealFeaturedNewsBox3
                    }
                );
                del += del_stagger;
                gsap.fromTo($('#news-content-featured-title span'), tme,
                    {
                        opacity: 0,
                        scaleX: 1.05
                    },
                    {
                        opacity: 1,
                        scaleX: 1,
                        ease: Power2.easeInOut,
                        delay: del

                    }
                );


            },
            revealFeaturedNewsBox3() {
                var del = 0;
                var tme = 0.75;
                var del_stagger = 0.1;

                $('#news-content-featured-img').show();
                $('#news-content-featured-content').css('visibility','visible');

                gsap.fromTo($('#news-content-featured-content2'), tme,
                    {
                        opacity: 0,
                        width:0
                    },
                    {
                        opacity: 1,
                        width:'100%',
                        ease: Power2.easeInOut,
                        delay: del,
                        onStart:this.revealFeaturedNewsBox4
                    }
                );
                del+=del_stagger;
                del+=del_stagger;
                gsap.fromTo($('#news-content-featured-img-inner'), tme,
                    {
                        opacity: 0,
                        width:0
                    },
                    {
                        opacity: 1,
                        width:'100%',
                        ease: Power2.easeInOut,
                        delay: del
                    }
                );
            },
            revealFeaturedNewsBox4() {
                //animate in
                var del = 0.35;
                var tme = 0.75;
                var del_stagger = 0.1;
                var yOffset = 75;
                var xOffset = 50;
                gsap.fromTo($('#news-content-featured-content h3'), tme,
                    {
                        opacity: 0,
                        scaleX: 1.05,
                        x: xOffset,
                    },
                    {
                        opacity: 1,
                        scaleX: 1,
                        x:0,
                        ease: Power2.easeOut,
                        delay: del

                    }
                );
                del += del_stagger;
                gsap.fromTo($('#news-content-featured-content h2'), tme,
                    {
                        opacity: 0,
                        scaleX: 1.05,
                        x: xOffset
                    },
                    {
                        opacity: 1,
                        scaleX: 1,
                        x: 0,
                        ease: Power2.easeOut,
                        delay: del

                    }
                );
                del += del_stagger;
                gsap.fromTo($('#news-content-featured-content .description'), tme,
                    {
                        opacity: 0,
                        x: xOffset
                    },
                    {
                        opacity: 1,
                        x: 0,
                        ease: Power2.easeOut,
                        delay: del

                    }
                );
                del += del_stagger;

                $('#news-content-featured-content-inner').css('opacity',1);

            },

            revealNewsItem(t) {
                if ($(t).attr('is-revealed') !== '1' && $(t).css('display') !== 'none') {
                    $(t).attr('is-revealed', '1');

                    //animate in
                    var del = 0;
                    var tme = 0.75;
                    var del_stagger = 0.2;
                    var yOffset = 75;
                    var xOffset = 50;
                    gsap.fromTo($('.news-item-img', t), tme,
                        {
                            opacity: 0,
                            y: yOffset,
                            scaleY:1.2,
                        },
                        {
                            opacity: 1,
                            y: 0,
                            scaleY:1,
                            ease: Power2.easeInOut,
                            delay: del

                        }
                    );
                    del += del_stagger;
                    gsap.fromTo($('h3', t), tme,
                        {
                            opacity: 0,
                            scaleX: 1.05,
                            x: xOffset,
                        },
                        {
                            opacity: 1,
                            scaleX: 1,
                            x:0,
                            ease: Power2.easeOut,
                            delay: del

                        }
                    );
                    del += del_stagger;
                    gsap.fromTo($('h2', t), tme,
                        {
                            opacity: 0,
                            scaleX: 1.05,
                            x: xOffset
                        },
                        {
                            opacity: 1,
                            scaleX: 1,
                            x: 0,
                            ease: Power2.easeOut,
                            delay: del

                        }
                    );
                    del += del_stagger;
                    gsap.fromTo($('.description', t), tme,
                        {
                            opacity: 0,
                            x: xOffset
                        },
                        {
                            opacity: 1,
                            x: 0,
                            ease: Power2.easeOut,
                            delay: del

                        }
                    );
                    del += del_stagger;

                    //toggle on
                    $(t).css('opacity', 1);
                }
            },

            onThumbOver(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.box-bg-square');
                var $image = $el.find('.bg-with-date');


                gsap.killTweensOf([$color, $image]);

                var dist = 17;
                gsap.to($color, 0.3, {x: dist, y: dist, force3D: true, ease: Power2.easeOut});
                gsap.to($image, 0.3, {
                    x: -dist, y: -dist, force3D: true, ease: Power2.easeOut, onComplete: () => {

                    }
                });
                //gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
            },

            onThumbOut(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.box-bg-square');
                var $image = $el.find('.bg-with-date');

                gsap.killTweensOf([$color, $image]);


                /*
                gsap.to($color, 0.3, {x: 12, y: -12, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {
                    x: -12, y: 12, force3D: true, ease: Power2.easeIn, onComplete: () => {

                    }
                });
                */
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0});

            },


            isScrolledIntoView(elem,type) {
                var docViewTop = $(window).scrollTop();
                var docViewBottom = docViewTop + $(window).height();

                var h =  $(elem).height();
                var wh = docViewBottom-docViewTop;

                var offset = $(elem).offset()
                var elemTop = (offset) ? offset.top : 0
                var elemBottom = elemTop + h;


                //trace (elem+":"+h + ":::::"+docViewTop+":::"+docViewBottom+"::::"+wh+":::"+elemBottom);
                /*
                if (type=="above_top") {
                    var ret = (elemTop <= docViewTop-site.scrollto_offset);
                } else {
                    //onpage - nothing off
                    //var ret = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                    if (h > wh) {
                        var ret = (elemBottom <= docViewBottom+(wh/2));
                    } else {
                        var ret = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
                    }
                }
                */
                var ret = (elemBottom <= docViewBottom+(wh/3));
                return ret;
            },

            destroyed() {
                window.removeEventListener("resize", this.onResize);
                window.removeEventListener('scroll', this.onScroll);
                //$('.header-dropdown-item').off('click', this.setCategoryClick);
            }

        }
    }

</script>
