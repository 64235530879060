<template>

    <div id="games" class="section">
        <h1 class="section-title lockout" ref="sectionTitle">Games</h1>
        <ul v-if="games" :class="['lockout games-list','count-' + games.length]" ref="boxArtUl">
            <li v-for="(game, i) in games" :class="'index-'+i">
                <router-link :to="'/games/' + game.id" v-on:mouseenter.native="onMouseEnter"
                             v-on:mouseleave.native="onMouseLeave">
                    <div class="box-art">
                        <div class="box-art__color"></div>
                        <div class="box-art__image">
                            <img :src="game.posterImage" :alt="game.title">
                        </div>
                        <h3>{{ game.title }}</h3>
                    </div>
                </router-link>
            </li>
        </ul>
        <footer-bar></footer-bar>
    </div>

</template>

<script>


    import Footer from '@/components/Footer'
    import $ from 'jquery'
    import axios from 'axios'
    import SectionTitle from '../lib/SectionTitle'
    import inobounce from 'inobounce'
    import siteHelpers from "@/helpers/siteHelpers";
    import { nextTick } from 'vue';
    import { gsap, Power3, Power2 } from "gsap";

    export default {
        name: 'games',
        components: {
            'footer-bar': Footer,
        },
        metaInfo() {
            return siteHelpers.getGlobalMeta('Games')
        },
        data() {
            return {
                data: {},
                games: null,
                enterTimeline: null,
                leaveTimeline: null
            }
        },
        mounted() {
            this.loadJson();
            inobounce.disable();
        },
        methods: {

            loadJson() {
                var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
                axios.get(window.baseUrl + '/games.json'+urlparams)
                    .then((response) => {
                        this.data = response.data;
                        this.games = response.data.games;
                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                        // console.log(this.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

            onJsonLoaded() {
                setTimeout(() => {
                    setTimeout(() => {
                        this.animateIn();
                    }, this.$store.state.firstSection ? 550 : 700);
                    this.$store.commit('endSectionPreload');
                }, this.$store.state.firstSection ? 0 : 550);
                SectionTitle.animateIn(this.$refs.sectionTitle);
            },

            animateIn() {
                this.$helpers.globalPostLoad();
                gsap.fromTo(this.$refs.boxArtUl.querySelectorAll('li'),
                    { y: 440, force3D: true, opacity: 0 },
                    {
                        duration: 0.75,
                        y: 0,
                        force3D: true,
                        opacity: 1,
                        ease: "power3.out",
                        stagger: 0.11
                    }
                );
            },

            onMouseEnter(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.box-art__color');
                var $image = $el.find('.box-art__image');

                gsap.killTweensOf([$color, $image]);

                gsap.to($color, 0.3, {x: 15, y: 15, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {x: -15, y: -15, force3D: true, ease: Power2.easeIn, onComplete:()=> {
                    $color.addClass('orange');
                }});
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
            },

            onMouseLeave(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.box-art__color');
                var $image = $el.find('.box-art__image');

                gsap.killTweensOf([$color, $image]);

                if ($color.hasClass('orange')) {
                    gsap.to($color, 0.22, {x: 15, y: 15, force3D: true, ease: Power2.easeIn});
                    gsap.to($image, 0.22, {
                        x: -15, y: -15, force3D: true, ease: Power2.easeIn, onComplete: () => {
                            $color.removeClass('orange');
                        }
                    });
                    gsap.to([$color, $image], 0.2, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.22});
                } else {
                    gsap.to([$color, $image], 0.2, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0});
                }

            }
        }
    }

</script>
