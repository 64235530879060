// Import the required methods from Vue Router
import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home';
import Games from './views/Games';
import GamesDetail from './views/GamesDetail';
import VideoModal from './components/VideoModal';
import GenericVideoModal from './components/GenericVideoModal';
import ImageModal from './components/ImageModal';
import Studio from './views/Studio';
import Internships from './views/Internships';
import Perks from './views/Perks';
import Team from './views/Team';
import TeamModal from './components/TeamModal';
import Careers from './views/Careers';
import Culture from './views/Culture';
import CareersListings from './views/CareersListings';
import CareersJobsDetail from './views/CareersJobsDetail';
import News from './views/News';
import NewsDetail from './views/NewsDetail';
import History from './views/History';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import NotFound from './views/404';

//meta add on
//Vue.use(Meta)

//router
var videoModal = {
    path: '/games/:id/video/:videoId',  //lock video model down to games, was path: 'video/:videoId',
    component: VideoModal,
    props:true,
    meta: {
        isModal: true
    },
}

var cultureVideoModal = {
    path: '/culture/video/:videoId',
    component: GenericVideoModal,
    props:true,
    meta: {
        isModal: true
    },
}

var newsVideoModal = {
    path: '/news/:id/video/:videoId',
    component: GenericVideoModal,
    props:true,
    meta: {
        isModal: true
    },
}

var imageModal = {
    path: 'image/:imageId',
    component: ImageModal,
    props:true,
    meta: {
        isModal: true
    },
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            isModal: false
        }
    },
    {
        path: '/games',
        name: 'games',
        meta: {
            isModal: false
        },
        children: [
            {
                path: '',
                name: 'games',
                component: Games,
                meta: {
                    isModal: false
                }
            },
            {
                path: ':id',
                name: 'games-detail',
                meta: {
                    isModal: false
                },
                children: [
                    {
                        path: '',
                        name: 'games-detail',
                        component: GamesDetail,
                        meta: {
                            isModal: false
                        },
                        children: [videoModal, imageModal]
                    }
                ]
            }
        ]
    },
    {
        path: '/studio',
        name: 'studio',
        component: Studio,
        meta: {
            isModal: false
        }
    },
    {
        path: '/perks',
        name: 'perks',
        component: Perks,
        meta: {
            isModal: false
        }
    },
    {
        path: '/culture',
        name: 'culture',
        component: Culture,
        meta: {
            isModal: false
        },
        children: [cultureVideoModal]
    },
    /*
    {
        path: '/people',
        name: 'people',
        component: Team,
        meta: {
            isModal: false
        }
    },
    {
        path: '/people/:id',
        component: Team,
        meta: {
            isModal: true
        },
        children: [
            {
                name: 'team-bio',
                path: '',
                component: TeamModal,
            },
        ]
    },

     */
    {
        path: '/internships',
        name: 'internships',
        component: Internships,
        meta: {
            isModal: false
        },
        children: [videoModal, imageModal]
    },
    {
        path: '/careers',
        children: [
            {
                path: '',
                name: 'careers',
                component: Careers,
                meta: { isModal: false },
                children: [
                    videoModal,
                    imageModal
                ]
            },
            {
                path: '/careers-listings',
                name: 'careers-listings',
                component: CareersListings,
                meta: { isModal: false },
                children: [
                    videoModal,
                    imageModal
                ]
            },
            {
                path: ':id',
                name: 'careers-detail',
                component: CareersJobsDetail,
                meta: { isModal: false },
                children: [
                    videoModal,
                    imageModal
                ]
            }
        ]
    },
    {
        path: '/news',
        children: [
            {
                path: '',
                name: 'news',
                component: News,
                meta: { isModal: false }
            },
            {
                path: ':id',
                name: 'news-detail',
                component: NewsDetail,
                meta: { isModal: false },
                children: [
                    videoModal, // Assuming videoModal is defined appropriately elsewhere
                    imageModal  // Assuming imageModal is defined appropriately elsewhere
                ]
            }
        ]
    },
    {
        path: '/history',
        name: 'history',
        component: History,
        meta: {
            isModal: false
        },
        children: [videoModal, imageModal]
    },


    {
        path: '/history/:id',
        name: 'history',
        component: History,
        meta: {
            isModal: false
        },
        children: [videoModal, imageModal]
    },
    /*
     */
    {
        path: '/legal/privacy',
        name: 'privacy',
        component: Privacy,
        meta: {
            isModal: false
        }
    },
    {
        path: '/legal/terms',
        name: 'terms',
        component: Terms,
        meta: {
            isModal: false
        }
    },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: NotFound,
        meta: {
            isModal: false
        }
    }
]

let router = createRouter({
    history: createWebHistory(), // This uses the HTML5 history API
    routes,
});

// Navigation Guard to handle metaInfo method invocation
router.afterEach((to, from) => {
    to.matched.forEach((record) => {
        const component = record?.components?.default;
        // Check if component is a function and might be an async component
        if (typeof component === 'function') {
            component().then((resolvedComponent) => {
                if (resolvedComponent.metaInfo) {
                    resolvedComponent.metaInfo();
                }
            }).catch(error => {
               // console.error("Failed to load component", error);
            });
        } else if (component && component.metaInfo) {
            // If the component is already loaded and has a metaInfo method
            component.metaInfo();
        }
    });
});

export default router;