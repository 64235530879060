<template>
    <Modal @close="onClose">
        <template v-slot:content>
            <div v-if="videoId" slot="content" class="video__modal">
                <div class="maintain-aspect-ratio">
                    <iframe :src="'https://www.youtube.com/embed/' + videoId + '?autoplay=1&rel=0&showinfo=0'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>

    import Modal from '@/components/Modal'
    import axios from 'axios'

    export default {
        name: 'TrailerModal',
        components: {
          Modal
        },
        data() {
            return {
                videoId: null,
            }
        },
        mounted() {
            this.loadJson()
        },
        methods: {
            loadJson () {
                const videoIdParam = this.$route.params.videoId
                const gameIdParam = this.$route.params.id
                var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
                axios.get(window.baseUrl + '/games.json' + urlparams)
                    .then((response) => {
                        for (let i=0;i<response.data.games.length;i++) {
                            let obj = response.data.games[i]
                            if (obj.id === gameIdParam) {
                                if (obj.youtubeTrailerId === videoIdParam) {
                                    this.videoId = videoIdParam //make sure youtube cms param was in cms
                                }
                            }
                        }
                        if (!this.videoId) {
                            window.location.href = '/404'
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            },
        }
    }

</script>
