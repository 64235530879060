import $ from 'jquery'
export default {
    getURLParameters(obj) {
        //alert('ah');
        var ret = '';
        if (obj !== undefined && obj !== null && obj !== '') {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (ret==='') {
                        ret += "?";
                    } else {
                        ret += "&";
                    }
                    ret += key + "=" + obj[key];
                }
            }
        }
        return ret;
    },
    globalPostLoad() {
        $('a[href^="https://"]').attr('target', '_blank');
        $('a[href^="http://"]').attr('target', '_blank');
    }
}
