<template>
    <div v-if="data" id="news-detail" class="section sticky-footer">

        <div class="hero">
            <div class="hero__inner hero__inner--hidden" :style="'background-image: url(' + data.posterImage + ')'">
                <div class="meta">
                    <div class="meta__date">
                        <div class="meta__date__month">{{ data.month}}</div>
                        <div class="meta__date__day">{{ data.day}}</div>
                        <div class="meta__date__year">{{ data.year}}</div>
                    </div>
                </div>
            </div>
            <div class="hero__wipe"></div>
        </div>

        <div class="article__title rtc lockout">
            <div class="meta">
                <div class="meta__date">
                    <div class="meta__date__month">{{ data.month}}</div>
                    <div class="meta__date__day">{{ data.day}}</div>
                    <div class="meta__date__year">{{ data.year}}</div>
                </div>
                <div v-if="data.author !== undefined && data.author !== ''" class="meta__credit">
                    <div class="meta__credit__by">BY</div>
                    <div class="meta__credit__name">{{ data.author}}</div>
                </div>
            </div>
            <div v-if="data.author !== undefined && data.author !== ''"  class="meta__credit__name--mobile">By {{ data.author}}</div>
            <h1>{{ data.title}}</h1>
        </div>

        <div class="rtc lockout" v-html="data.content"></div>

        <router-view/>
        <footer-bar></footer-bar>
    </div>
</template>

<script>

    import Vue from 'vue'
    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import SlickCarousels from '../lib/SlickCarousels'
    import inobounce from 'inobounce'
    import SectionTitle from '../lib/SectionTitle'
    import $ from 'jquery'
    import axios from 'axios'
    import router from '../router'
    import siteHelpers from "@/helpers/siteHelpers";
    //import {getURLParameters} from "../helpers"
    import { gsap, Expo, Power2 } from "gsap";

    export default {
        name: 'news-detail',
        metaInfo() {
            return siteHelpers.getGlobalMeta(this.data.title, this.data.title + ' | News')
        },
        components: {
            'footer-bar': Footer,
        },
        data() {
            return {
                data: {},
            }
        },
        mounted() {
            this.loadJson();
        },
        methods: {
            loadJson() {
                var urlparams = this.$helpers.getURLParameters(this.$route.query);
                //axios.get('/data/news/news-slug.json')
                 axios.get(window.baseUrl + '/news/' + this.$route.params.id + '.json'+urlparams)
                    .then((response) => {
                        this.data = response.data;

                        //dynamic meta title load based on JSON
                        $('head title').html(this.data.title + " | News | " + this.$siteName)

                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                        // console.log(this.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

            onJsonLoaded() {

                // tells section loader to wipe up
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, 550);

                inobounce.disable();
                SlickCarousels.init();
                this.initEvents();
                this.animateIn();

            },

            initEvents() {
                $('.video-module__thumb').find('a').on('mouseenter', this.onVideoThumbOver);
                $('.video-module__thumb').find('a').on('mouseleave', this.onVideoThumbOut);
                $('.image-module__thumb').find('a').on('mouseenter', this.onImageThumbOver);
                $('.image-module__thumb').find('a').on('mouseleave', this.onImageThumbOut);
                $('a[data-router-link]').on('click', this.onRouterLinkClick);
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var $heroWipe = $('.hero__wipe');
                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0.3 : 0.88);

                tl.to($heroWipe, {
                    duration: 0.8,
                    y: 0,
                    force3D: true,
                    ease:Expo.easeInOut,
                    //clearProps: 'all'
                }, 0);
                tl.call(() => {  // This will now execute 0.5 seconds after the last animation
                    document.querySelector('.hero__inner').classList.remove('hero__inner--hidden');
                });
                tl.to($heroWipe, 0.8, {
                    y: '-100%',
                    force3D:true,
                    ease:Expo.easeInOut,
                    //clearProps: 'all'
                }, '-=0.12');

                tl.fromTo([$('.article__title').find('.meta'), '.article__title', '.rtc'],
                    {
                        opacity: 0,
                        y: 150,
                        force3D: true }, // From properties
                    {
                        duration: 0.8,
                        y: 0,
                        force3D: true,
                        opacity: 1,
                        ease: "power3.inOut",
                        stagger: {
                            each: 0, // Stagger time, set to 0 if you want them to start at the same time
                            from: "start" // Start stagger from the start of the selection
                        },
                        //clearProps: 'all'  // Use 'all' if you want to clear all applied styles at the end of the animation
                    },
                    "-=0.76" // Start 0.76 seconds earlier relative to the last animation
                );

            },

            onRouterLinkClick(e) {
                e.preventDefault();
                let href = window.location.pathname + '/' + $(e.currentTarget).attr('href');
                router.push({path: href});
            },

            onVideoThumbOver(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.video-module__thumb__image__box');
                var $image = $el.find('.video-module__thumb__image__img');

                gsap.killTweensOf([$color, $image]);

                gsap.to($color, 0.3, {x: 17, y: -17, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {
                    x: -17, y: 17, force3D: true, ease: Power2.easeIn, onComplete: () => {
                        $color.addClass('orange');
                    }
                });
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
            },

            onVideoThumbOut(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.video-module__thumb__image__box');
                var $image = $el.find('.video-module__thumb__image__img');

                gsap.killTweensOf([$color, $image]);

                if ($color.hasClass('orange')) {
                    gsap.to($color, 0.3, {x: 17, y: -17, force3D: true, ease: Power2.easeIn});
                    gsap.to($image, 0.3, {
                        x: -17, y: 17, force3D: true, ease: Power2.easeIn, onComplete: () => {
                            $color.removeClass('orange');
                        }
                    });
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
                } else {
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0});
                }

            },

            onImageThumbOver(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.image-module__thumb__image__box');
                var $image = $el.find('.image-module__thumb__image__img');

                gsap.killTweensOf([$color, $image]);

                gsap.to($color, 0.3, {x: 17, y: 17, force3D: true, ease: Power2.easeIn});
                gsap.to($image, 0.3, {
                    x: -17, y: -17, force3D: true, ease: Power2.easeIn, onComplete: () => {
                        $color.addClass('orange');
                    }
                });
                gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
            },

            onImageThumbOut(e) {
                var $el = $(e.currentTarget);
                var $color = $el.find('.image-module__thumb__image__box');
                var $image = $el.find('.image-module__thumb__image__img');

                gsap.killTweensOf([$color, $image]);

                if ($color.hasClass('orange')) {
                    gsap.to($color, 0.3, {x: 17, y: 17, force3D: true, ease: Power2.easeIn});
                    gsap.to($image, 0.3, {
                        x: -17, y: -17, force3D: true, ease: Power2.easeIn, onComplete: () => {
                            $color.removeClass('orange');
                        }
                    });
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0.3});
                } else {
                    gsap.to([$color, $image], 0.3, {x: 0, y: 0, force3D: true, ease: Power2.easeOut, delay: 0});
                }

            }

        },

        destroyed() {
            $('.video-module__thumb').find('a').off('mouseenter', this.onVideoThumbOver);
            $('.video-module__thumb').find('a').off('mouseleave', this.onVideoThumbOut);
            $('a[data-router-link]').off('click', this.onRouterLinkClick);
        }
    }

</script>
