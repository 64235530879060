import Store from '../store.js';
import $ from 'jquery'
import { gsap, Power3 } from "gsap";

function animateIn(el) {
    // TweenLite.to(el, 0.85, {
    //     scrambleText: {
    //         text: $(el).text(),
    //         chars: "upperCase",
    //         revealDelay: 0.5,
    //     },
    //     delay: Store.state.firstSection ? 0 : 0.95
    // });
    gsap.fromTo(el, 1.15, {x: 0, letterSpacing: $(window).width() < 700 ? '1.5rem' : '3rem', opacity: 0}, {
        x: 0,
        opacity: 1,
        force3D: true,
        ease: Power3.easeInOut,
        letterSpacing: $(window).width() < 700 ? '0.4rem' : '0.7rem',
        delay: Store.state.firstSection ? 0 : 0.7
    });
}

export default {animateIn}
