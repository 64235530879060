<template>

    <div id="game-details">
        <div id="game-details-dots-shell">
            <div id="game-details-dots-hider">
                <div id="game-details-dots-img">
                    <img src="../img/game-details/bg-dots-transparent.png"/>
                </div>
            </div>
        </div>

        <div class="mobile__top" :style="{ 'z-index': dropdownOpen ? 4 : 4}">
            <div class="mobile__top__dropdown" @click="dropdownOpen = !dropdownOpen" :class="{open: dropdownOpen}">
                <h2>{{ gameName }}</h2>
                <div class="mobile__top__dropdown__menu" :class="{open: dropdownOpen}">
                    <ul>
                        <li v-for="game in games" :class="{ 'router-link-active': gameId == game.id }">{{ game.title
                            }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mobile__top__release">
                <div class="mobile__top__release__text" v-for="game in games">{{ game.releaseDate }}</div>
            </div>
        </div>

        <div class="content" v-bind:class="{open: isOpen}">

            <ul class="games__ul">
                <li class="games__ul__game" v-for="game in games" :game-slug="game.id">
                    <div class="sliding-content">
                        <div class="sliding-content__details"
                             v-bind:style="{ 'background-image' : 'url(' + game.bgImage + ')' }">
                            <div class="tint tint--black"></div>
                            <div class="tint tint--orange"></div>
                            <div class="tint tint--slate"></div>
                            <div class="sliding-content__details__inner">
                                <div class="sliding-content__details__inner__p">
                                    <div v-if="game.rich_description && game.rich_description !== ''" class="d p-hook" v-html="game.rich_description"></div>
                                    <p v-else class="d p-hook" >{{ game.description }}</p>
                                </div>
                                <ul class="sliding-content__details__inner__buttons">
                                    <li>
                                        <a :href="game.gameSiteUrl" target="_blank">
                                            <div class="btn btn--square btn--square--white">
                                                <div class="btn--square__icon">
                                                    <img src="../img/global/icon-visit-site.png"
                                                         alt="Visit Game Site">
                                                    <img src="../img/global/icon-visit-site.png"
                                                         alt="Visit Game Site">
                                                </div>
                                                <h5 v-html="game.gameSiteLabel"></h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li v-if="game.youtubeTrailerId">
                                        <router-link :to="`/games/${game.id}/video/${game.youtubeTrailerId}`">
                                            <div class="btn btn--square btn--square--white">
                                                <div class="btn--square__icon">
                                                    <img src="../img/global/icon-watch-trailer.png" alt="Watch Traler">
                                                    <img src="../img/global/icon-watch-trailer.png" alt="Watch Traler">
                                                </div>
                                                <h5>
                                                    WATCH<br>TRAILER
                                                </h5>
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <img src="../img/global/icon-close-circle.png" class="sliding-content__mobile__close"
                             @click="isOpen = !isOpen" :style="{display: isOpen ? 'block': 'none'}">

                        <div class="sliding-content__box-art">

                            <div class="sliding-content__box-art__inner">
                                <div v-touch:swipe="onSwipe" class="swipe-area">
                                    <img :src="game.posterImage" v-on:load="sizeItems()"
                                         class="sliding-content__box-art__img">
                                </div>
                                <div class="sliding-content__box-art__buttons">
                                    <div @click="isOpen=!isOpen" :class="isOpenButtonClass">
                                        <div class="btn--huge__text">
                                            <div class="btn--huge__text__final" v-bind:style="{display: isOpen ? 'none': 'block'}">LEARN MORE<span>LEARN MORE</span></div>
                                            <div class="btn--huge__text__final" v-bind:style="{display: isOpen ? 'block': 'none'}">CLOSE<span>CLOSE</span></div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="title-wrap">
                        <h5 class="title-wrap__release">{{ game.releaseDate }}</h5>
                        <h2 class="title-wrap__title">{{ game.title }}</h2>
                        <ul class="title-wrap__buttons">
                            <li v-if="game.ctaMeetTheTeam">
                                <router-link :to="'/people/'">
                                    <div class="btn btn--square">
                                        <div class="btn--square__icon">
                                            <img src="../img/global/icon-meet-team.png" alt="Meet the Team">
                                            <img src="../img/global/icon-meet-team.png" alt="Meet the Team">
                                        </div>
                                        <h5>
                                            MEET<br>THE TEAM
                                        </h5>
                                    </div>
                                </router-link>
                            </li>
                            <li v-if="game.ctaJoinTheTeam">
                                <router-link :to="(game.careersLink) ? '/careers-listings?team=' + game.careersLink : '/careers-listings'">
                                    <div class="btn btn--square">
                                        <div class="btn--square__icon">
                                            <img src="../img/global/icon-join-team.png" alt="Join the Team">
                                            <img src="../img/global/icon-join-team.png" alt="Join the Team">
                                        </div>
                                        <h5>
                                            JOIN<br>THE TEAM
                                        </h5>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

            <div class="white-dot-block" ref="whiteDotBlock"></div>

            <div class="subnav">
                <ul>
                    <li v-for="game in games" :class="{ 'router-link-active': gameId == game.id }">
                        <!-- <router-link :to=" game.id "> -->
                        <div>
                            {{ game.title }}<span class="subnav__hover__wrap"><span class="subnav__hover">{{ game.title }}</span></span>
                        </div>
                        <!-- </router-link> -->
                    </li>
                </ul>
                <div class="scroll-ctas">
                    <img src="../img/game-details/cta-scroll.png" class="scroll-ctas__long">
                    <img src="../img/game-details/cta-scroll-short.png" class="scroll-ctas__short">
                </div>
            </div>

        </div>

        <div v-if="1 == 2" class="mobile__buttons">
            <div class="mobile__buttons__wrap" v-for="game in games">
                <ul>
                    <li>
                        <router-link :to="'/people'">
                            <div class="btn btn--square">
                                <div class="btn--square__icon">
                                    <img src="../img/global/icon-meet-team.png" alt="Meet the Team">
                                    <img src="../img/global/icon-meet-team.png" alt="Meet the Team">
                                </div>
                                <h5>
                                    MEET<br>THE TEAM
                                </h5>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="(game.careersLink) ? '/careers-listings?team=' + game.careersLink : '/careers-listings'">
                            <div class="btn btn--square">
                                <div class="btn--square__icon">
                                    <img src="../img/global/icon-join-team.png" alt="Join the Team">
                                    <img src="../img/global/icon-join-team.png" alt="Join the Team">
                                </div>
                                <h5>
                                    JOIN<br>THE TEAM
                                </h5>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <router-view/>
        <footer-bar :class="{'footer--active': footerShowing}"></footer-bar>

    </div>

</template>

<script>

    import Vue from 'vue'
    import $ from 'jquery'
    import SplitText from '@/lib/SplitText.min'
    import axios from 'axios'
    import inobounce from 'inobounce'
    import Footer from '@/components/Footer'
    import siteHelpers from '@/helpers/siteHelpers'
    import { nextTick } from 'vue';
    import { gsap, Power3, Power2, Quint } from "gsap";

    gsap.registerPlugin(SplitText);

    export default {
        name: 'games-detail',
        metaInfo() {
            console.log('detail', this.gameName)
            return siteHelpers.getGlobalMeta(this.gameName, this.gameName + ' | Games')
        },
        components: {
            'footer-bar': Footer
        },
        data() {
            return {
                data: {},
                games: null,
                isOpen: false,
                index: 0,
                prevIndex: -1,
                totalGames: Number,
                lastScrollTime: Number,
                lethargy: null,
                splitTextTitles: null,
                gameId: null,
                dropdownOpen: false,
                footerShowing: false
            }
        },
        computed: {
            gameName: function () {
                if (this.games) {
                    for (var i = 0; i < this.games.length; i++) {
                        if (this.games[i].id == this.gameId) {
                            return this.games[i].title;
                            break;
                        }
                    }
                }
            },
            isOpenButtonClass() {
                if (this.isOpen) {
                    return 'btn btn--huge btn--white btn-display-close btn--huge__reversed-arrow sliding-content__open';
                } else {
                    return 'btn btn--huge btn--white btn-display-open sliding-content__open';
                }
            }
        },
        mounted() {
            this.loadJson();
            this.sizeWrapperHeight();
            this.sizeItems();
            inobounce.enable();
        },
        methods: {

            loadJson() {
                var urlparams = this.$helpers.getURLParameters(this.$route.query);//+urlparams
                axios.get(window.baseUrl + '/games.json' + urlparams)
                    .then((response) => {
                        this.data = response.data;
                        this.games = response.data.games;

                        nextTick(() => {
                            this.onJsonLoaded();
                        });
                        // console.log(this.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

            onJsonLoaded() {

                // tells section loader to wipe up
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, 550);

                this.lastScrollTime = new Date().getTime();
                this.totalGames = this.games.length;

                this.addEventListeners();
                this.setGameIdFromUrl();

                // used to detect trackpad scrolling (for rubber banding)
                //this.lethargy = new Lethargy();

                setTimeout(() => {
                    setTimeout(() => {
                        this.animateTitleIn();
                    }, 670);
                    this.animatePhotoIn();
                }, 415);

            },

            addEventListeners() {
                window.addEventListener("resize", this.onResize);
                document.body.addEventListener("wheel", this.onScroll);
                $('.subnav').find('li').on('click', this.onSubnavLiClick);
                $('.mobile__top__dropdown__menu').find('li').on('click', this.onDropdownLiClick);
                document.body.addEventListener('touchmove', function (e) {
                    e.preventDefault();
                });

                // force hard-refresh on back-button so game changes properly
                // vue-router has issues with this otherwise :(
                $(window).on('popstate', this.onPopState);
            },

            onPopState() {
                //location.reload();
                //window.history.back(
                //console.log('location',location)
                //console.log('window.history',window.history)
            },

            onScroll(e) {
                // e.preventDefault();

                // prevents changing games too fast
                if (this.scrollThrottled() && !this.footerShowing) return;

                // show/hide footer at end
                if (e.deltaY < 0 && this.index == this.totalGames - 1 && this.footerShowing) {
                    // if on last story and scrolling up
                    this.footerShowing = false;
                    return;
                } else if (this.index == this.totalGames - 1 && e.deltaY > 0) {
                    // if on last story and scrolling down
                    this.footerShowing = true;
                }

                // rubber band at top
                if (e.deltaY < 0 && this.index == 0) {
                    // helps with rubber banding when scrolling with trackpad
                    //if (this.lethargy.check(e) === false) return;
                    this.rubberBand('top');
                    return;
                }

                // rubber band at bottom
                if (e.deltaY > 0 && this.index == this.totalGames - 1) {
                    // helps with rubber banding when scrolling with trackpad
                    //if (this.lethargy.check(e) === false) return;
                    this.rubberBand('bottom');
                    return;
                }

                this.prevIndex = this.index;
                this.index = e.deltaY < 0 ? this.index -= 1 : this.index += 1;

                //change URL
                var $newGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var gameslug = $newGame.attr('game-slug');
                var newurl = '/games/' + gameslug;
                //window.history.pushState({"html": newurl, "pageTitle": 'Games'}, "", newurl);

                //now change game
                this.changeGame();
            },

            scrollThrottled() {
                let curTime = new Date().getTime();
                if (curTime - this.lastScrollTime < 1700) {
                    return true;
                }
                this.lastScrollTime = curTime;
                return false;
            },

            changeGame() {
                gsap.killTweensOf("*"); // helps prevent weirdness when clicking learn more and then immediately scrolling

                this.gameId = this.games[this.index].id;
                this.dropdownOpen = false;

                // window.history.pushState("", "", "/games/" + this.gameId);

                if (this.isOpen) {
                    this.isOpen = false;
                    setTimeout(() => {
                        this.animateTitleOut();
                        this.animatePhotoOut();
                        this.animatePhotoIn();
                        this.lastScrollTime = new Date().getTime();
                    }, 800);
                } else {
                    this.animateTitleOut();
                    this.animatePhotoOut();
                    this.animatePhotoIn();
                }


                //dynamic meta title load based on JSON
                //$('head title').html(this.gameName + " | Games | " + this.$siteName);
                siteHelpers.getGlobalMeta(this.gameName, this.gameName + ' | Games')
                gtagPage();//ping gtag manually since this isn't triggered by a route change
            },

            onResize(e) {
                this.sizeItems();
                this.sizeWrapperHeight();
            },

            sizeItems() {

                // size boxart image
                var $curGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var $boxArt = $curGame.find('.sliding-content__box-art');
                var $boxArtImg = $curGame.find('.sliding-content__box-art__img');

                let windowWidth = window.innerWidth
                let thew = $boxArtImg.width()
                if (windowWidth <= 480 ) {
                    thew = (1-0.14) * windowWidth
                }
                $boxArt.css({
                    width: thew
                });

                // set left margin for main game title
                var targetLeft = $boxArt.width() + ($(window).width() * .15);
                $curGame.find('.title-wrap').css({left: targetLeft});
                $(this.$refs.whiteDotBlock).css({width: targetLeft - 5});

                //NEW!  White Dot Block is Not Used.  Instead, crop dot background
                var sidew = $(window).width() - targetLeft - 9;//9 is the right adjust
                $('#game-details-dots-hider').css({'width': sidew});

                // size description text inside of panel for mobile
                var $descP = $('.sliding-content__details__inner__p');
                $descP.css({height: 'auto'});
                if ($(window).width() < 500) {
                    var descNativeHeight = $descP.height()
                    var descTextInnerHeight = $curGame.find('.sliding-content__details').height();
                    var targetHeight = descTextInnerHeight - 100;
                    if (targetHeight > 180) targetHeight = 180;

                    if (descNativeHeight > targetHeight) {
                        $('.sliding-content__details__inner__p').css({height: targetHeight});
                    }
                }

            },

            animateTitleOut() {
                var $prevGame = $($('.games__ul').find('.games__ul__game').get(this.prevIndex));
                var $curTitle = $prevGame.find('.title-wrap');
                // var $buttonsMobile = $($('.mobile__buttons__wrap').get(this.index));
                var $releaseDateMobile = $($('.mobile__top__release__text').get(this.prevIndex));

                gsap.fromTo($curTitle, 0.7, {y: 0, opacity: 1, force3D: true},
                    {
                        y: this.prevIndex > this.index ? 175 : -175,
                        opacity: 0,
                        ease: Power2.easeInOut,
                        delay: this.prevIndex > this.index ? 0 : 0.03,
                        force3D: true,
                        onComplete: () => {
                            $curTitle.hide();
                            this.animateTitleIn();
                        }
                    });
                gsap.to($releaseDateMobile, 0.3, {opacity: 0});
            },

            animateTitleIn() {
                //aniamte
                var $newGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var $curTitle = $newGame.find('.title-wrap');
                var $buttons = $newGame.find('.title-wrap__buttons').find('li');
                var $buttonsMobile = $($('.mobile__buttons__wrap').get(this.index));
                var $releaseDateMobile = $($('.mobile__top__release__text').get(this.index));
                var down = this.index > this.prevIndex;


                //change url
                //this.$router.push({name: 'home', query : data });
                var gameslug = $newGame.attr('game-slug');
                var newurl = '/games/' + gameslug;
                //window.history.pushState({"html": newurl, "pageTitle": 'Games'}, "", newurl);

                //go
                gsap.killTweensOf($curTitle);
                $curTitle.show();

                this.splitTitlesIntoLines();

                var $h5 = $curTitle.find('.title-wrap__release');
                var $h2 = $curTitle.find('.title-wrap__title');
                gsap.set($curTitle, {y: 0, opacity: 1, force3D: true});

                var tl = gsap.timeline();
                tl.delay(down ? 0.2 : 0);

                tl.fromTo([$h5], 0.95, {opacity: 0, letterSpacing: '1rem'}, {
                    opacity: 1, letterSpacing: '0.35rem',
                    ease: Power2.easeInOut
                }, 0);

                tl.fromTo($buttons,
                    { x: 100, force3D: true, opacity: 0 },
                    {
                        duration: 1,
                        x: 0,
                        opacity: 1,
                        ease: "quint.inOut",
                        stagger: {
                            amount: 0.045,
                            start: 0
                        }
                    }
                );

                $('.mobile__buttons__wrap').hide();
                $buttonsMobile.show();

                $('.mobile__top__release__text').hide();
                $releaseDateMobile.show();
                tl.fromTo($releaseDateMobile, 1, {opacity: 0, letterSpacing: '0.8rem'}, {
                    opacity: 1,
                    letterSpacing: '0.2rem',
                    ease: Power2.easeInOut
                }, 0);

                // $buttonsMobile.show().css({ opacity: 0});
                // tl.fromTo($buttonsMobile, 1, {opacity:0, y: down? 100 : -100}, {opacity:1, y:0, force3D:true}, 0);

                tl.staggerFrom($curTitle.find('.split-line'), 0.9, {
                    yPercent: down ? 90 : -95,
                    force3D: true,
                    ease: Quint.easeInOut,
                    onComplete: () => {
                        // revert split text back to regular h2
                        $h2.html($h2.text());
                    }
                }, 0.06, 0);

                siteHelpers.getGlobalMeta(this.gameName, this.gameName + ' | Games')

            },

            animatePhotoIn() {

                var $newGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var $btns = $newGame.find('.sliding-content__box-art__buttons');

                $newGame.show();
                this.sizeItems();

                var $orangeBox = $newGame.find('.sliding-content__details');
                var $boxArt = $newGame.find('.sliding-content__box-art__img');
                var orangeBoxHeight = $newGame.find('.sliding-content__box-art__img').height();
                var down = this.index >= this.prevIndex;


                gsap.set($orangeBox, {height: orangeBoxHeight * (down ? 5 : 1)});
                gsap.set($btns, {x: 0, force3D: true});
                gsap.set('.p-hook', {opacity: 0}); // so we can't see the text peek through

                var tl = gsap.timeline();
                tl.fromTo($orangeBox, 1.4, {y: down ? window.innerHeight : -window.innerHeight}, {
                    y: 0,
                    force3D: true,
                    ease: Power3.easeInOut
                });
                tl.to($orangeBox, 1.4, {height: orangeBoxHeight, ease: Power3.easeInOut, clearProps: 'height'}, 0.32);

                tl.fromTo($boxArt, 1.4, {y: down ? window.innerHeight : -window.innerHeight}, {
                    y: 0,
                    force3D: true,
                    ease: Power3.easeInOut
                }, down ? 0.5 : 0.2);
                tl.fromTo($btns, 1.4, {y: down ? window.innerHeight * 0.6 : -window.innerHeight * 1}, {
                    y: 0,
                    force3D: true,
                    ease: Power3.easeInOut
                }, down ? 0.68 : 0.33);
                tl.call(() => {
                    $orangeBox.addClass('transition');
                });

                tl.call(() => {
                    $('.mobile__top').css({ zIndex: 4 });
                }, null, 1.2);
            },

            animatePhotoOut() {

                var $prevGame = $($('.games__ul').find('.games__ul__game').get(this.prevIndex));
                var $newGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var $orangeBox = $prevGame.find('.sliding-content__details');
                var $boxArt = $prevGame.find('.sliding-content__box-art__img');
                var $btns = $prevGame.find('.sliding-content__box-art__buttons');
                var down = this.index >= this.prevIndex;
                $('.sliding-content__details').removeClass('transition');
                $('.mobile__top').css({zIndex: 1});

                gsap.fromTo($boxArt, 0.9, {y: 0}, {
                    y: down ? -window.innerHeight : window.innerHeight, ease: Power3.easeInOut, force3D: true,
                    onComplete: () => {
                        $prevGame.hide();
                        $newGame.show();
                    }
                });
                gsap.fromTo($orangeBox, 1.06, {y: 0}, {
                    y: down ? -window.innerHeight : window.innerHeight,
                    ease: Power3.easeInOut,
                    force3D: true
                });
                gsap.fromTo($btns, 0.5, {x: 0}, {x: -500, force3D: true, ease: Power3.easeIn});
            },

            onSwipe(e) {
                if (e==='top') {
                    this.onSwipeUp()
                } else if (e==='bottom') {
                    this.onSwipeDown()
                }
            },

            onSwipeUp() {

                if (this.index == this.totalGames - 1) {
                    this.footerShowing = true;
                    return;
                }

                if (this.isOpen) return;
                if (this.index == this.totalGames - 1) return;

                // prevents changing games too fast
                if (this.scrollThrottled()) return;

                this.prevIndex = this.index;
                this.index++;
                if (this.index > this.totalGames - 1) this.index = this.totalGames - 1;
                this.changeGame();
            },

            onSwipeDown() {

                if (this.footerShowing) {
                    this.footerShowing = false;
                    return;
                }

                if (this.isOpen) return;
                if (this.index == 0) return;

                // prevents changing games too fast
                if (this.scrollThrottled()) return;

                this.prevIndex = this.index;
                this.index--;
                if (this.index < 0) this.index = 0;
                this.changeGame();
            },

            splitTitlesIntoLines() {
                var $newGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var $curTitle = $newGame.find('.title-wrap');
                var $h2Title = $curTitle.find(".title-wrap__title");
                var originalText = $h2Title.text();
                $h2Title.html(originalText);
                new SplitText($h2Title, {type: "lines", linesClass: "split-line"});
                $('.split-line').wrap("<div class='line-wrap'></div>");
            },

            rubberBand(direction) {
                var $newGame = $($('.games__ul').find('.games__ul__game').get(this.index));
                var $curTitle = $newGame.find('.title-wrap');

                gsap.killTweensOf($curTitle);
                gsap.to($curTitle, 0.3, {y: direction == 'top' ? 20 : -20, force3D: true, ease: Power2.easeOut});
                gsap.to($curTitle, 0.3, {y: 0, force3D: true, ease: Power2.easeInOut, delay: 0.08});
            },

            onSubnavLiClick(e) {
                if (this.scrollThrottled()) return;
                let clickOnIndex = $(e.currentTarget).index();
                if (this.index == clickOnIndex) return;
                this.prevIndex = this.index;
                this.index = clickOnIndex;
                this.changeGame();
            },

            onDropdownLiClick(e) {
                if (this.scrollThrottled()) return;
                let clickOnIndex = $(e.currentTarget).index();
                if (this.index == clickOnIndex) {
                    this.dropdownOpen = false;
                    return;
                }
                this.prevIndex = this.index;
                this.index = clickOnIndex;
                e.stopPropagation();
                this.dropdownOpen = false;
                setTimeout(() => {
                    this.changeGame();
                }, 300);
            },

            setGameIdFromUrl() {
                for (var i = 0; i < this.games.length; i++) {
                    if (this.games[i].id == this.$route.params.id) {
                        this.gameId = this.games[i].id;
                        this.index = i;
                        break;
                    }
                }

                //dynamic meta title load based on JSON
                $('head title').html(this.gameName + " | Games | " + this.$siteName)
            },

            sizeWrapperHeight() {
                $('#game-details').css({height: window.innerHeight});
            },


        },

        watch: {
            isOpen: function (opening) {

                var $curGame = $('.games__ul').find('.games__ul__game:visible');
                var tl = gsap.timeline();
                var blackTint = $curGame.find('.tint--black');
                var orangeTint = $curGame.find('.tint--orange');
                var slateTint = $curGame.find('.tint--slate');
                var detailsInner = $curGame.find('.sliding-content__details__inner')[0];
                var p = detailsInner.querySelectorAll('.p-hook');
                var buttons = detailsInner.querySelectorAll('.sliding-content__details__inner__buttons');
                var mobileButtons = $('.mobile__buttons');

                //flip button
                if (opening) {

                } else {

                }

                if (opening) {
                    tl.delay(0.2);
                    tl.fromTo(slateTint, 0.7, {width: 0}, {width: '100%', ease: Power3.easeInOut}, 0);
                    tl.fromTo(orangeTint, 0.7, {xPercent: 0}, {
                        xPercent: 100,
                        force3D: true,
                        ease: Power3.easeInOut
                    }, 0);
                    tl.to(slateTint, 0.7, {width: 0, ease: Power3.easeInOut}, "+=0");
                    tl.fromTo(blackTint, 0.7, {xPercent: 100, display: 'none'}, {
                        xPercent: 0,
                        force3D: true,
                        display: 'block',
                        ease: Power3.easeInOut
                    }, "-=0.7");
                    tl.fromTo(p, 0.73, {autoAlpha: 0, xPercent: 70}, {
                        autoAlpha: 1,
                        xPercent: 0,
                        force3D: true,
                        ease: Power2.easeInOut
                    }, 0.74);
                    tl.fromTo(buttons, 0.73, {autoAlpha: 0, xPercent: 70}, {
                        autoAlpha: 1,
                        xPercent: 0,
                        force3D: true,
                        ease: Power2.easeInOut
                    }, 0.78);
                    tl.fromTo(mobileButtons, 0.35, {autoAlpha: 1, x: 0}, {
                        autoAlpha: 0,
                        x: 70,
                        force3D: true,
                        ease: Power2.easeInOut
                    }, 0.15);
                } else {

                    var isMobile = $(window).width() < 900;
                    tl.fromTo(orangeTint, isMobile ? 0.3 : 0.7, {xPercent: 100}, {
                        xPercent: 0,
                        force3D: true,
                        ease: Power3.easeInOut
                    }, 0);
                    tl.fromTo(blackTint, isMobile ? 0.3 : 0.7, {xPercent: 0, display: 'block'}, {
                        xPercent: -100,
                        force3D: true,
                        display: 'none',
                        ease: Power3.easeInOut
                    }, 0);
                    tl.fromTo(p, isMobile ? 0.0 : 0.35, {autoAlpha: 1}, {
                        autoAlpha: 0,
                        ease: Power3.easeInOut,
                        delay: isMobile ? 0 : 0.1
                    }, 0);
                    tl.fromTo(buttons, isMobile ? 0.0 : 0.35, {autoAlpha: 1}, {
                        autoAlpha: 0,
                        ease: Power3.easeInOut,
                        delay: isMobile ? 0 : 0.1
                    }, 0);
                    tl.fromTo(mobileButtons, 0.45, {autoAlpha: 0, x: 100}, {
                        autoAlpha: 1,
                        x: 0,
                        force3D: true,
                        ease: Power2.easeOut
                    }, 0.05);
                }
            }
        },

        destroyed() {
            window.removeEventListener("resize", this.onResize);
            document.body.removeEventListener("wheel", this.onScroll);
            $('.subnav').find('li').off('click', this.onSubnavLiClick);
            $('.mobile__top__dropdown__menu').find('li').off('click', this.onDropdownLiClick);
            $(window).off('popstate', this.onPopState);
            this.lethargy = null;
        }

    }

</script>
