<template>
    <div id="app">
        <header-nav/>
        <router-view/>
        <section-transition/>
        <blockers/>
    </div>
</template>
<script>

import { gsap } from "gsap";
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import SplitText from '@/lib/SplitText.min';
import ScrambleTextPlugin from '@/lib/ScrambleTextPlugin.min';
import HeaderNav from '@/components/HeaderNav';
import Blockers from '@/components/Blockers';
import SectionTransition from '@/components/SectionTransition';

gsap.registerPlugin(ScrollToPlugin, SplitText, ScrambleTextPlugin);

// VueTouch setup for Vue 3 (if needed, check if there's a Vue 3 compatible version)
//import { VueTouch } from 'vue-touch';
import { createApp } from 'vue';
const app = createApp({});
//app.use(VueTouch, {name: 'v-touch'});


export default {
    components: {
        'header-nav': HeaderNav,
        Blockers,
        'section-transition': SectionTransition,
    },
    methods: {}
}

</script>
<style lang="scss">
@import 'sass/app';
</style>
