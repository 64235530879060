<template>
    <div id="section-transition" class="hidden" ref="sectionTransition">
        <div class="loader__mask" ref="mask">
            <div class="loader__mask__centered" ref="centered">
                <div class="loader__mask__centered2">
                    <video autoplay playsinline muted loop ref="video">
                        <source src="/img/global/preloader/loader-logo.mp4" type="video/mp4">
                    </video>
                    <h4>LOADING</h4>
                </div>
            </div>
        </div>
        <div class="transition__white transition--full" ref="white"></div>
        <div class="transition__black transition--full" ref="black"></div>
    </div>
</template>

<script>

    import router from '../router'
    import $ from 'jquery'
    import { gsap, Expo, Linear } from "gsap";

    export default {
        name: 'section-transition',
        components: {},
        data() {
            return {
                next: null,
                next2: null,
                wipeSpeed: 0.7,
                easing: Expo.easeInOut
            }
        },
        computed: {
            preloadingSection: {
                get: function () {
                    return this.$store.state.preloadingSection;
                },
                set: function (value) {
                    this.$store.commit('startSectionPreload', value);
                }
            }
        },

        created() {

            router.beforeEach((to, from, next) => {
                var openingModal = to.matched.some(record => record.meta.isModal);
                var closingModal = from.matched.some(record => record.meta.isModal);

                for (var i = 0; i < router.options.routes.length; i++) {
                    var curRoute = router.options.routes[i];
                    var fromPathContainsCurSection = from.path.indexOf(curRoute.path) > -1;
                    var toPathContainsCurSection = to.path.indexOf(curRoute.path) > -1;
                    var sameSectionChange = fromPathContainsCurSection && toPathContainsCurSection && curRoute.path != '/';


                    // if route change is same section and opening or closing modal,
                    // return to prevent full page section transition
                    if (sameSectionChange && openingModal || sameSectionChange && closingModal) {
                        // console.log('same section and opening modal');
                        $('html').removeClass('no-scroll');
                        next();
                        return;
                        break;
                    }

                }

                //careers details - flipping between the two - but we DO want a full page transition
                if (from.name==="careers-detail" && to.name==="careers-detail") {
                    this.next = this.animateTriggerRemounter
                    this.next2 = next
                } else {
                    this.next = next;
                }

                this.preloadingSection = true;

            })
        },

        watch: {
            preloadingSection: function (val) {
                if (this.preloadingSection) {
                    this.animateIn();
                } else {
                    this.animateOut();
                }
            }
        },

        methods: {
            animateTriggerRemounter: function () {
                this.next2()
                this.$store.commit('triggerRemounter');
            },
            animateIn: function () {
                this.$refs.sectionTransition.classList.remove('hidden');
                this.$refs.video.currentTime = 0;
                this.$refs.video.pause();

                gsap.set(this.$refs.mask, {height: 0, bottom: 0, top: 'auto'});
                gsap.set(this.$refs.centered, {bottom: 0, top: 'auto'});

                var tl = gsap.timeline({ timeScale: 0.9 });  // Set timeScale at the timeline initialization
                tl.fromTo(this.$refs.black,
                    { opacity: 0 },
                    { duration: this.wipeSpeed / 2, opacity: 0.2, ease: "none" }  // Linear.easeNone is now "none"
                );

                tl.fromTo(this.$refs.white,
                    { yPercent: 100 },
                    { duration: this.wipeSpeed, yPercent: 0, ease: "expo.inOut", force3D: true },
                    0  // Start at the beginning of the timeline
                );

                tl.fromTo(this.$refs.mask,
                    { height: '0%' },
                    { duration: this.wipeSpeed, height: '100%', ease: "expo.inOut" },
                    0  // Start at the beginning of the timeline
                );

                tl.to(this.$refs.video,
                    { duration: 0.01, onStart: () => this.$refs.video.play() },
                    0.4  // Start slightly later in the timeline
                );

                tl.to({},
                    { duration: 0.01, onComplete: () => {
                            document.body.classList.add('no-scroll');
                            window.scrollTo(0, 0);
                            this.next();
                        } },
                    ">+0.01"  // Slightly after the previous action
                );
            },

            animateOut: function () {
                gsap.set(this.$refs.mask, {bottom: 'auto', top: 0});
                gsap.set(this.$refs.centered, {top: 0, bottom: 'auto'});

                var tl = gsap.timeline({});
                tl.timeScale(0.87);
                tl.fromTo(this.$refs.white, this.wipeSpeed, {yPercent: 0}, {
                    yPercent: -100,
                    force3D: true,
                    ease: Expo.easeInOut
                });
                tl.fromTo(this.$refs.black, this.wipeSpeed * 0.9, {opacity: 0.28}, {
                    opacity: 0,
                    ease: Linear.easeNone
                }, 0.1);
                tl.fromTo(this.$refs.mask, this.wipeSpeed, {height: '100%'}, {height: '0%', ease: Expo.easeInOut}, 0);
                tl.call(() => {
                    this.$refs.sectionTransition.classList.add('hidden');
                    //document.body.classList.remove('no-scroll');
                });

            },

        }


    }

</script>
