<template>
    <div v-if="data" id="internships" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">Internships</h1>
        <div class="hero hero-generic">
            <div class="hero__text">
                <div class="hero__text__bg">
                    <div class="hero__text__bg__wrap">
                        <h4 v-if="data.subtitle !== undefined" class="hero__text__subtitle" v-html="data.subtitle"></h4>
                        <h1 v-if="data.title  !== undefined" class="hero__text__title"  v-html="data.title"></h1>
                    </div>
                </div>
            </div>
            <div class="hero__image" :style="'background-image: url(' + data.studioImage + ')'">
                <div class="hero__image__white"></div>
            </div>
        </div>
        <div class="rtc lockout">
            <div class="gallery">
                <div class="gallery__caption">
                    <ul>
                        <li v-if="data.gallery" v-for="galleryItem in data.gallery">
                            <div class="gallery__caption__maintitle">{{galleryItem.highlight}}</div>
                            <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                            <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>
                        </li>
                    </ul>
                </div>
                <div class="gallery__images">
                    <ul class="gallery__images__carousel">
                        <li v-for="galleryItem in data.gallery">
                            <img :src="galleryItem.img">
                        </li>
                    </ul>
                    <div class="gallery__images__counter">
                        <div class="gallery__images__counter__current"></div>
                        <div class="gallery__images__counter__total">/</div>
                    </div>
                </div>
                <div class="gallery__caption gallery__caption--mobile">
                    <ul>
                        <li v-for="galleryItem in data.gallery">
                            <div class="gallery__caption__maintitle">{{galleryItem.highlight}}</div>
                            <div class="gallery__caption__title" v-html="galleryItem.title"></div>
                            <div class="gallery__caption__subtitle rich-text" v-html="galleryItem.description"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="rtc lockout--badge">
            <div class="items">
                <div class="dots"></div>
                <div class="badge">
                    <img  src="@/img/careers/badge.svg" alt="GamesIndustry.biz - 2022 Best Places to Work Winner"/>
                </div>

            </div>
        </div>

        <div class="rtc lockout" v-if="data.blurbs">
            <div class="blurbs">
                <div class="l">
                    <div class="row" v-for="(row, i) in data.blurbs.blurbs"  >
                        <h2 class="internships-subtitle" v-html="row.title"></h2>
                        <div class="desc" v-html="row.description"></div>
                    </div>
                </div>
                <div class="r">
                    <img :src="data.blurbs.photo.img">
                    <div class="caption">
                        <div class="caption-l">“</div>
                        <div class="caption-r" v-html="data.blurbs.photo.quote+'”'"></div>
                    </div>
                    <div class="attribute"><small>{{data.blurbs.photo.attribute}}</small></div>
                </div>
            </div>
            <div class="lockout--quotes quotes" v-if="data.quotes.length>0">
                <div class="simple_carousel">
                    <div v-for="(row, i) in data.quotes"  class="quote-holder">
                        <div class="l">
                            <img src="../img/global/icon-quote-left.png" />
                        </div>
                        <div class="r"><div class="r-inner">
                            <div class="quote" v-html="row.quote"></div>
                            <div v-if="row.attribution" class="attribution">
                                // {{row.attribution}}
                            </div>
                        </div></div>
                    </div>
                </div>

            </div>
            <div class="lockout--careers careers-table" v-if="interns.length>0" :len="interns.length">
                <div class="open-text">
                    <h2 class="internships-subtitle" v-html="data.internships.title"></h2>
                    <div class="desc" v-html="data.internships.description"></div>
                    <div class="dots"></div>
                </div>

                <div class="zlockout">
                    <div class="row header">
                        <div class="col col-role">
                            <p>Role:</p>
                        </div>
                        <div class="col col-group">
                            <p>Group:</p>
                        </div>
                        <div class="col col-team">
                            <p>Team: </p>
                        </div>
                        <div class="col col-link">
                            &nbsp;
                        </div>
                    </div>
                    <div class="row" v-for="(row, i) in interns"  >
                        <div class="col col-role">
                            <p>{{ row.role }}</p>
                        </div>
                        <div class="col col-group">
                            <p>{{ row.group }}</p>
                        </div>
                        <div class="col col-team">
                            <p>{{ row.team }}</p>
                        </div>
                        <div class="col col-link">
                            <router-link :to="'/careers/'+ row.id">
                                <p>Learn More <span class="arrow"></span></p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="faqs">
                <div class="open-text">
                    <h2 class="internships-subtitle" v-html="data.faqs.title"></h2>
                    <div class="desc" v-html="data.faqs.description"></div>
                    <div class="dots"></div>
                </div>
                <div class="questions" :len="data.faqs.items.length">
                    <div :class="['row row-'+i, isAnswerOpen(i) ? 'open' : 'closed']" v-for="(row, i) in data.faqs.items"  >
                        <div :class="'question question-'+i" @click="toggleAnswer(i)">
                            <p v-html="row.question"></p>
                            <div class="arrow"></div>
                        </div>
                        <div :class="'answer answer-'+i" v-html="row.answer"></div>
                    </div>
                </div>
            </div>
        </div>

        <footer-bar></footer-bar>
    </div>
</template>

<script>

    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import SlickCarousels from '../lib/SlickCarousels'
    import inobounce from 'inobounce'
    import SectionTitle from '../lib/SectionTitle'
    import $ from 'jquery'
    import axios from 'axios'
    import JoinStudioBlock from '@/components/JoinStudioBlock'
    import PerksBlock from '@/components/PerksBlock'
    import siteHelpers from "@/helpers/siteHelpers";
    import SlickSimpleCarousel from '../lib/SlickSimpleCarousel'
    import { gsap, Power3 } from "gsap";

    export default {
        name: 'internships',
        metaInfo() {
            return siteHelpers.getGlobalMeta('Internships')
        },
        components: {
            'footer-bar': Footer,
            'join-studio-block': JoinStudioBlock,
            'perks-block': PerksBlock
        },
        data() {
            return {
                data: {},
                interns: [],
                window: window,
                openFAQs:[]
            }
        },
        created() {

        },
        mounted() {
            this.loadJson();
            this.loadCareersInit()
            //this.onJsonLoaded();
        },
        methods: {
            toggleAnswer(i) {
                if (this.openFAQs.includes(i)) {
                    this.openFAQs = []
                } else {
                    this.openFAQs = [i]
                }
                /*
                if (this.openFAQs.includes(i)) {
                    const index = this.openFAQs.indexOf(i);
                    if (index > -1) {
                        this.openFAQs.splice(index, 1);
                    }
                } else {
                    this.openFAQs.push(i)
                }

                 */
            },
            isAnswerOpen(i) {
                return (this.openFAQs.includes(i))
            },
            loadCareersInit() {
                if (this.window.careers.isLoaded) {
                    this.loadCareersJson()
                } else {
                    setTimeout(this.loadCareersInit,500)
                }
            },
            loadCareersJson() {
                this.interns = this.window.careers.calculated.interns
            },

            loadJson() {
                axios.get(window.baseUrl + '/internships.json')
                     .then((response) => {
                         this.data = response.data;
                         nextTick(() => {
                             this.onJsonLoaded();
                         });
                          //console.log(this.data);
                     })
                     .catch((error) => {
                         console.error(error);
                     })
             },

            onJsonLoaded() {
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');

                    SlickSimpleCarousel.init(true);

                }, 500);
                inobounce.disable();
                SlickCarousels.init();
                SectionTitle.animateIn(this.$refs.sectionTitle);
                this.animateIn();
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var isMobile = $(window).width() < 700;
                var $orangeHero = $('.hero__text__bg');
                var $whiteImageBlocker = $('.hero__image__white');
                var narrowOrange = $orangeHero.width() * 0.3;
                var targetX = $orangeHero.width() + (isMobile ? 0 : 230);
                var orangeStaringHeight = $orangeHero.outerHeight();
                var $text = $('.hero__text__bg__wrap');
                var $rtc = $('.rtc');

                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0 : 0.65);

                tl.set($orangeHero, {width: $orangeHero.width() * 0.3, height: orangeStaringHeight})
                tl.fromTo($orangeHero, 1, { x:-narrowOrange, force3D:true },
                    { x: targetX, force3D:true, ease:Power3.easeInOut});
                tl.to($orangeHero, 0.6, { x:0, width: '100%', force3D: true, ease: Power3.easeInOut, clearProps:'all' }, '-=0.21');
                tl.fromTo($whiteImageBlocker, 0.6, {xPercent:0}, { xPercent: -100, force3D:true, ease:Power3.easeInOut}, '-=0.55');
                tl.fromTo($text, 0.45, {x:200, opacity: 0}, { x: 0, opacity:1, force3D:true, ease:Power3.easeOut}, 1.12);
                tl.fromTo($rtc, 0.7, { y:100, opacity:0}, { y:0, force3D:true, opacity:1, ease:Power3.easeInOut}, '-=0.6');

            }
        }

    }

</script>
