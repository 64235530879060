import { createStore } from 'vuex'
import $ from 'jquery'

// Create a new store instance
const store = createStore({
    state() {
        return {
            preloadingSection: false,
            firstSection: true,
            remounterKey: 'key-' + Math.random(),
        }
    },
    mutations: {
        startSectionPreload(state) {
            state.preloadingSection = true;
        },
        endSectionPreload(state) {
            state.preloadingSection = false;
            $('body').removeClass('no-scroll');
            $('html').removeClass('no-scroll');
            state.firstSection = false;
        },
        triggerRemounter(state) {
            state.remounterKey = 'key-' + Math.random();
        },
    },
    actions: {}
})

// Export the store
export default store;
