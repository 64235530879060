<template>
    <div id="perks" class="section sticky-footer">
        <h1 class="section-title lockout" ref="sectionTitle">Perks</h1>
        <div class="hero">
            <div class="hero__text">
                <div class="hero__text__bg">
                    <div class="hero__text__bg__wrap">
                        <h4 class="hero__text__subtitle">WORKING AT RESPAWN</h4>
                        <h1 class="hero__text__title">HAS ITS PERKS<br>AND BENEFITS</h1>
                    </div>
                </div>
            </div>
            <div class="hero__image" style="background-image: url('/img/perks/hero-image2.jpg')">
                <div class="hero__image__white"></div>
            </div>
        </div>
        <div class="rtc lockout">
            <blockquote>
                We strive to keep our employees happy and healthy.
                <br><span class="orange">Here is a sneak peek at some of what we offer…</span>
            </blockquote>


            <div class="perks-block">
                <div class="lr">
                    <div class="l">
                        <div class="l1">
                            <div class="num">01<img src="@/img/perks/perk-dots.png"></div>
                            <div class="icon"><img src="@/img/perks/perk-icon-1.png"></div>
                        </div>
                        <div class="l2">
                            <div class="txt">Wellbeing for You and Your Family</div>
                        </div>
                    </div>
                    <div class="r">
                        <ul>
                            <li><div class="plus"></div>Medical, Dental, Vision, Mental Health, and other welfare plans.</li>
                            <li><div class="plus"></div>Disability, Life, and AD&D insurance benefits.</li>
                            <li><div class="plus"></div>Paid time off, as well as holiday office closures including shutdown between Christmas and New Year's.</li>
                            <li><div class="plus"></div>Generous parental leave for new parents.</li>
                            <li><div class="plus"></div>Other perks include emergency backup child care program, adoption assistance, legal services, and group home, auto, and pet insurance.</li>
                        </ul>

                    </div>
                </div>

            </div>


            <div class="perks-block">
                <div class="lr">
                    <div class="l">
                        <div class="l1">
                            <div class="num">02<img src="@/img/perks/perk-dots.png"></div>
                            <div class="icon"><img src="@/img/perks/perk-icon-2.png"></div>
                        </div>
                        <div class="l2">
                            <div class="txt">FINANCIAL BENEFITS & SUPPORT</div>
                        </div>
                    </div>
                    <div class="r">
                        <ul>
                            <li><div class="plus"></div>Flexible Spending Accounts for heath and child care.</li>
                            <li><div class="plus"></div>401K with company match and no vesting period.</li>
                            <li><div class="plus"></div>Employee Stock Purchase Plan.</li>
                            <li><div class="plus"></div>Other perks include education assistance and commuter benefits.</li>
                        </ul>

                    </div>
                </div>

            </div>


            <div class="perks-block">
                <div class="lr">
                    <div class="l">
                        <div class="l1">
                            <div class="num">03<img src="@/img/perks/perk-dots.png"></div>
                            <div class="icon"><img src="@/img/perks/perk-icon-3.png"></div>
                        </div>
                        <div class="l2">
                            <div class="txt">VARIOUS AMENITIES & CONVENIENCES</div>
                        </div>
                    </div>
                    <div class="r">
                        <ul>
                            <li><div class="plus"></div>Game room with a pool table, ping pong, foosball, arcade and tabletop games.</li>
                            <li><div class="plus"></div>Fully stocked kitchens with fresh fruit, breakfast, snacks, and more.</li>
                            <li><div class="plus"></div>Convenient meal order service for on-site lunch deliveries.</li>
                            <li><div class="plus"></div>State of the art onsite gym with 24/7 access.</li>
                            <li><div class="plus"></div>Onsite company-sponsored yoga, strengthening, and other fitness classes.</li>
                            <li><div class="plus"></div>Onsite massages and car washes.</li>
                            <li><div class="plus"></div>Mocap stage for use by development teams.</li>
                        </ul>

                    </div>
                </div>

            </div>


            <div class="perks-block">
                <div class="lr">
                    <div class="l">
                        <div class="l1">
                            <div class="num">04<img src="@/img/perks/perk-dots.png"></div>
                            <div class="icon"><img src="@/img/perks/perk-icon-4.png"></div>
                        </div>
                        <div class="l2">
                            <div class="txt">TEAM Activities, FUN Stuff & MORE!</div>
                        </div>
                    </div>
                    <div class="r">
                        <ul>
                            <li><div class="plus"></div>Free games and company store with discounts on games and consoles.</li>
                            <li><div class="plus"></div>Company-sponsored team events such as regular staff parties, food trucks, tournaments, movie screenings, game conventions, and launch parties.</li>
                            <li><div class="plus"></div>A variety of interest groups and outdoor activities to join with other Respawn Employees: table top games club, poker, club, beer club, kit-bashing club, running/hiking, biking, basketball, and soccer among others.</li>
                            <li><div class="plus"></div>Opportunities for volunteering, donating to causes (with company match), and giving back to the community.</li>
                            <li><div class="plus"></div>Friendly, caring co-workers and management.</li>
                        </ul>

                    </div>
                </div>

            </div>

            <join-studio-block></join-studio-block>

        </div>

        <footer-bar></footer-bar>
    </div>
</template>

<script>

    import { nextTick } from 'vue';
    import Footer from '@/components/Footer'
    import inobounce from 'inobounce'
    import SectionTitle from '../lib/SectionTitle'
    import $ from 'jquery'
    import axios from 'axios'
    import JoinStudioBlock from '@/components/JoinStudioBlock'
    import siteHelpers from "@/helpers/siteHelpers"
    import { gsap, Power3 } from "gsap";
    export default {
        name: 'perks',
        metaInfo() {
            return siteHelpers.getGlobalMeta('Perks')
        },
        components: {
            'footer-bar': Footer,
            'join-studio-block': JoinStudioBlock
        },
        data() {
            return {
                data: {},
            }
        },
        created() {

        },
        mounted() {
            // this.loadJson();
            this.onJsonLoaded();
        },
        methods: {

            // loadJson() {
            //     axios.get(window.baseUrl + '/studio.json')
            //         .then((response) => {
            //             //this.data = response.data;
            //             nextTick(() => {
            //                 this.onJsonLoaded();
            //             });
            //             // console.log(this.data);
            //         })
            //         .catch((error) => {
            //             console.error(error);
            //         })
            // },

            onJsonLoaded() {
                setTimeout(() => {
                    this.$store.commit('endSectionPreload');
                }, 500);
                inobounce.disable();
                SectionTitle.animateIn(this.$refs.sectionTitle);
                this.animateIn();
            },

            animateIn() {
                this.$helpers.globalPostLoad();

                var isMobile = $(window).width() < 700;
                var $orangeHero = $('.hero__text__bg');
                var $whiteImageBlocker = $('.hero__image__white');
                var narrowOrange = $orangeHero.width() * 0.3;
                var targetX = $orangeHero.width() + (isMobile ? 0 : 230);
                var orangeStaringHeight = $orangeHero.outerHeight();
                var $text = $('.hero__text__bg__wrap');
                var $rtc = $('.rtc');

                var tl = gsap.timeline();
                tl.delay(this.$store.state.firstSection ? 0 : 0.65);

                tl.set($orangeHero, {width: $orangeHero.width() * 0.3, height: orangeStaringHeight})
                tl.fromTo($orangeHero, 1, { x:-narrowOrange, force3D:true },
                    { x: targetX, force3D:true, ease:Power3.easeInOut});
                tl.to($orangeHero, 0.6, { x:0, width: '100%', force3D: true, ease: Power3.easeInOut, clearProps:'all' }, '-=0.21');
                tl.fromTo($whiteImageBlocker, 0.6, {xPercent:0}, { xPercent: -100, force3D:true, ease:Power3.easeInOut}, '-=0.55');
                tl.fromTo($text, 0.45, {x:200, opacity: 0}, { x: 0, opacity:1, force3D:true, ease:Power3.easeOut}, 1.12);
                tl.fromTo($rtc, 0.7, { y:100, opacity:0}, { y:0, force3D:true, opacity:1, ease:Power3.easeInOut}, '-=0.6');

            }
        }

    }

</script>
