<template>
    <Modal @close="onClose">
        <template v-slot:content>
            <div slot="content" class="image__modal">
                <img :src="imageUri">
            </div>
        </template>
    </modal>
</template>

<script>

    import Modal from '@/components/Modal'
    import $ from 'jquery'
    import axios from 'axios'

    export default {
        name: 'ImageModal',
        data() {
            return {
                imageUri: ''
            }
        },
        components: {
          Modal
        },
        created() {
          this.getImageUri();
        },
        methods: {

            getImageUri() {
                var tid = this.$attrs.imageId;
                //axios.get(window.baseUrl + '/news/' + this.$route.params.id + '.json')
                axios.get(window.baseUrl + '/image/' + tid + '.json')
                //axios.get('/data/team.json')
                    .then((response) => {
                         //this.imageUri = response.data.path;
                        //this.imageUri = '/img/news/fpo2.jpg'
                        this.imageUri = response.data.posterImage;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            },

        }

    }

</script>
